import React, { useState } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import {
  Screen,
  Text,
  TextField,
  ImageUploader,
  Button,
  HeaderWithBackButton,
} from "app/components"

export interface ProviderProfileProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const ProviderProfile = observer(function ProviderProfile(props: ProviderProfileProps) {
  const [bizName, setBizName] = useState<string>("")
  const [tagline, setTagline] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [address, setAddress] = useState<string>("")

  // Pull in navigation via hook
  const navigation = useNavigation()

  return (
    <View>
      <HeaderWithBackButton title="Main Profile" />
      <View>
        <Text
          preset="default"
          size="xs"
          weight="medium"
          text="Business Name"
          style={styles.labelText}
        />
        <Text
          preset="default"
          size="xs"
          weight="normal"
          text="This will be displayed on your profile."
          style={styles.descText}
        />

        <TextField
          value={bizName}
          onChangeText={setBizName}
          placeholder="Skin Republic"
          containerStyle={styles.textFieldContainer}
        />
      </View>
      <View style={styles.divider} />
      {/* @next line */}
      <View>
        <Text preset="default" size="xs" weight="medium" text="Tagline" style={styles.labelText} />
        <Text
          preset="default"
          size="xs"
          weight="normal"
          text="A quick description of your service"
          style={styles.descText}
        />

        <TextField
          value={tagline}
          onChangeText={setTagline}
          placeholder="Type here...."
          style={[styles.textFieldContainer, styles.textArea]}
        />
      </View>
      <Text
        preset="default"
        size="xs"
        weight="normal"
        text="40 Characters left"
        style={styles.descText}
      />
      <View style={styles.divider} />
      <View style={{ marginBottom: 18 }}>
        <ImageUploader />
      </View>

      <View style={styles.bottomSpace}>
        <Text
          preset="default"
          size="xs"
          weight="medium"
          text="Official Email Address"
          style={styles.labelText}
        />
        <TextField value={email} onChangeText={setEmail} placeholder="theplace@gmail.com" />
      </View>

      <View style={styles.bottomSpace}>
        <Text
          preset="default"
          size="xs"
          weight="medium"
          text="Official Phone Number"
          style={styles.labelText}
        />
        <TextField
          value={phoneNumber}
          onChangeText={setPhoneNumber}
          placeholder="e.g 09059436965"
        />
      </View>

      <View style={styles.bottomSpace}>
        <Text preset="default" size="xs" weight="medium" text="Address" style={styles.labelText} />
        <TextField value={address} onChangeText={setAddress} placeholder="25 Montgomery road, UK" />
      </View>

      <Text
        preset="default"
        size="xs"
        weight="medium"
        text="Use my current location"
        style={styles.close2meText}
      />

      <Button
        preset="primary"
        text="Save and Continue"
        style={{ marginBottom: 40 }}
        onPress={() => navigation.navigate("BankAccountDetails")}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  labelText: {
    color: "#344054",
  },

  descText: {
    color: "#667085",
    marginBottom: 8,
  },

  divider: {
    borderBottomWidth: 1,
    borderBottomColor: "#E4E7EC",
    borderBottomStyle: "solid",
    marginVertical: 20,
  },

  textFieldContainer: {
    paddingHorizontal: 14,
    paddingVertical: 10,
  },
  textArea: {
    height: 90,
  },
  close2meText: {
    color: colors.palette.primary600,

    marginBottom: 24,
  },

  bottomSpace: {
    marginBottom: 20,
  },
})
