import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import splash1 from "../../../assets/images/splash-1.png"
import splash2 from "../../../assets/images/splash-2.png"
import linepattern from "../../../assets/images/Line-pattern-web.png"
import { AutoImage } from "../AutoImage"

export interface ImagePatternWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const ImagePatternWeb = observer(function ImagePatternWeb(props: ImagePatternWebProps) {
  const { style } = props
  const $styles = [$container, style]

  return (
    <View style={$styles}>
      <View style={styles.TopImagesContainer}>
        <AutoImage source={splash1} style={styles.TopLeftImage} />
        <AutoImage source={linepattern}/>
      </View>
      <View style={styles.BottomImagesContainer}>
        <AutoImage source={linepattern}/>
        <AutoImage source={splash2} />
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  backgroundColor: "#f3f4f6",
}

const styles = StyleSheet.create({
  BottomImagesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width:"100%",
  },
BottomRightImage:{
    marginBottom: 74,
    marginRight: 24,
  },
 TopImagesContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 50,
    width:"100%",
  },
  TopLeftImage:{
    marginLeft: 24,
    marginTop: 74,

  }

})

