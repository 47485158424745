/* eslint-disable react-native/no-color-literals */
import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors } from "app/theme"
import { Text } from "app/components/Text"
import { Header } from "app/components/Header"
import { Search } from "app/components/serviceUser/Search"
import { ScrollView } from "react-native-gesture-handler"
import { AutoImage } from "app/components/AutoImage"
import {
  TextFieldData,
  addressType,
} from "app/components/serviceUser/DummyData/AddAddressConstants"
import Checkbox from "expo-checkbox"
import { TextField } from "app/components/TextField"
import { CustomCheckBox } from "app/components/Common/CheckBox"
import { Button } from "app/components/Button"

const dummyMap = require("../../../../assets/images/dummyMap.png");


export interface AddNewAddressWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const AddNewAddressWeb = observer(function AddNewAddressWeb(props: AddNewAddressWebProps) {
  const { style } = props
  const $styles = [$container, style]
  const [isChecked, setChecked] = React.useState(false)

  const handleCheckboxChange = (newValue: boolean) => {
    setChecked(newValue)
  }
  return (
    <View style={$styles}>
      <Header title="Add New Address" />
      <Search />
      <ScrollView>
        <Text
          text="Use My Current Address"
          preset="subheading"
          weight="semiBold"
          size="md"
          style={styles.mapTextStyle}
        />
        <AutoImage source={dummyMap} style={styles.image} />
        <Text
          text="Address Type"
          preset="label"
          weight="normal"
          size="md"
          style={styles.textStyle}
        />
        <View style={styles.selectWrapper}>
          {addressType.map((type) => (
            <View key={type.id} style={styles.selectWrapper}>
              <Text text={type.type} />
              <Checkbox />
            </View>
          ))}
        </View>
        {TextFieldData.map((data) => (
          <TextField
            key={data.id}
            placeholder={data.placeholder}
            style={styles.textField}
            inputWrapperStyle={styles.textField}
            containerStyle={styles.containerStyle}
          />
        ))}
        <View style={[styles.leftContainer, { marginTop: 20 }]}>
          <CustomCheckBox
            label="Mark as default address"
            value={isChecked}
            onChange={handleCheckboxChange}
          />
        </View>
        <Button
          text="Confirm"
          style={styles.button}
          textStyle={styles.buttonText}
          pressedStyle={styles.pressedButton}
          pressedTextStyle={styles.pressedButtonText}
        />
      </ScrollView>{" "}
    </View>
  )
})

const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.primary600,
    borderRadius: 27.2,
    height: 48,
    justifyContent: "center",
    marginBottom: 20,
    width: "100%",
  },
  buttonText: {
    color: "#F5F5FF",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  containerStyle: {
    marginBottom: 10,
  },
  headerTitle: {
    color: "#101828",
    fontSize: 20,
    fontWeight: "900",
    lineHeight: 32,
    marginBottom: 8,
  },
  image: {
    marginBottom: 16,
  },
  leftContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 5,
    marginBottom: 20,
  },
  mapTextStyle: {
    color: colors.palette.primary600,
    marginBottom: 16,
    marginTop: 24,
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },

  pressedButtonText: {
    color: colors.palette.primary600,
  },
  selectWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
  },
  textStyle: {
    color: "#344054",
    marginBottom: 8,
  },
})
const $container: ViewStyle = {
  justifyContent: "center",
}


