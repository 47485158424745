import React, { useState } from "react"
import { StyleSheet, View, ViewStyle, Image, Pressable } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, TextField, Icon } from "app/components"
import AppLogo from "assets/images/app-icon-all.png"
import { useNavigation } from "@react-navigation/native"

export interface SidebarProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

type IconTypes = "stat" | "Calendar" | "transaction" | "scissors" | "ticket" | "carbonTime"

const searchbarData = [
  { icon: "stat", text: "Dashboard", url: "ServiceProviderHome" },
  { icon: "Calendar", text: "All Bookings", price: "$4", url: "ServiceProviderBookings" },
  { icon: "transaction", text: "Pending Bookings", price: "$4", url: "PendingBookings" },
  { icon: "scissors", text: "Services", url: "ServiceProviderServices" },
  { icon: "ticket", text: "Earnings", url: "Earnings" },
  { icon: "carbonTime", text: "Your Availability", url: "Reviews" },
]

/**
 * Describe your component here
 */
export const Sidebar = observer(function Sidebar(props: SidebarProps) {
  const [searchTerm, setSearch] = useState("")
  const [activeState, setActiveState] = useState(0)
  const navigation = useNavigation()

  const handleSidePress = (index, url) => {
    setActiveState(index)
    navigation.navigate(url)
  }

  return (
    <View style={styles.sidebarCover}>
      <Pressable onPress={() => navigation.navigate("ServiceProviderHome")}>
        <Image source={AppLogo} alt="logo" style={styles.logoStyle} />
      </Pressable>
      <TextField
        onChangeText={(text) => setSearch(text)}
        value={searchTerm}
        placeholder="Search"
        LeftAccessory={() => <Icon icon="search" size={16} style={{ marginLeft: 8 }} />}
        style={{ borderWidth: 0 }}
        containerStyle={{
          marginVertical: 24,
        }}
        inputWrapperStyle={{
          height: 44,
          flexDirection: "row",
          alignItems: "center",
        }}
      />

      <View style={{ marginBottom: 371 }}>
        {searchbarData.map((item, index) => (
          <Pressable key={index} onPress={() => handleSidePress(index, item.url)}>
            <View style={styles.menuItemContainer}>
              <Icon
                icon={item.icon as IconTypes}
                color={
                  index === activeState ? colors.palette.primary500 : colors.palette.neutral500
                }
                size={18}
              />
              <Text
                text={item.text}
                size="sm"
                style={{
                  color:
                    index === activeState ? colors.palette.primary500 : colors.palette.neutral500,
                  fontWeight: "500",
                }}
              />

              {item.price && (
                <View style={styles.priceContainer}>
                  <Text text={item.price} size="xs" style={styles.priceStyle} />
                </View>
              )}
            </View>
          </Pressable>
        ))}
      </View>

      <View style={styles.sidebarProfile}>
        <Icon icon="avatar" size={30} />
        <View>
          <Text size="xs" text="Ayo Dahunsi" style={{ fontWeight: "500", color: colors.text }} />
          <Text size="xs" text="ayo@close2mi.com" style={{ fontWeight: "500", color: "#667085" }} />
        </View>

        <Icon icon="sidelogout" size={24} />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  sidebarCover: {
    maxWidth: 280,
    paddingHorizontal: 16,
    paddingTop: 8,
    backgroundColor: "#fff",
    marginBottom: 56,
  },
  menuItemContainer: {
    marginBottom: 32,
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },

  priceStyle: {
    color: colors.palette.neutral500,
    fontWeight: "500",
  },

  priceContainer: {
    justifyContent: "center",
    alignItems: "center",
    width: 38,
    height: 24,
    borderRadius: 25,
    backgroundColor: "#F9F9F9",
  },

  sidebarProfile: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  logoStyle: {
    width: 68,
    height: 72,
    marginBottom: 24,
  },
})
