import React, { useState } from "react"
import { View, StyleSheet } from "react-native"
import { Text, TextField, Icon, ProviderWebServices, HeaderBar, Sidebar } from "app/components"
import { colors } from "app/theme"

export const ServiceProviderServicesScreen = () => {
  const [searchItem, setSearchItem] = useState("")
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Services" />
        <View style={styles.serviceCover}>
          <View style={styles.rightContainer}>
            <TextField
              value={searchItem}
              onChangeText={(text) => setSearchItem(text)}
              RightAccessory={() => <Icon icon="search" size={18} style={{ marginRight: 8 }} />}
              inputWrapperStyle={{
                borderRadius: 24,
                width: 694,
                flexDirection: "row",
                alignItems: "center",
              }}
            />
            <View style={styles.dropdown}>
              <Text
                size="xs"
                text="All"
                style={{ fontWeight: "500", color: colors.palette.neutral800 }}
              />
              <Icon icon="carretdown" size={14} />
            </View>

            <View style={styles.add}>
              <Text
                preset="bold"
                size="xs"
                text="Add Service"
                style={{ color: colors.palette.primary500 }}
              />
              <Icon icon="add" size={16} />
            </View>
          </View>
          <View style={styles.add}>
            <View style={{ flex: 1 }}>
              <ProviderWebServices />
            </View>
            <View style={{ flex: 1 }}>
              <ProviderWebServices />
            </View>
          </View>

          <View style={styles.add}>
            <View style={{ flex: 1 }}>
              <ProviderWebServices />
            </View>
            <View style={{ flex: 1 }}>
              <ProviderWebServices />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  dropdown: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 8,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#D1D5DB",
    borderRadius: 24,
    backgroundColor: "#fff",
  },
  add: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "center",
    gap: 8,
    marginLeft: 8,
    paddingRight: 32,
  },

  rightContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginVertical: 16,
    marginLeft: 8,
    paddingRight: 32,
  },

  serviceCover: {
    padding: 24,
    backgroundColor: "#F9FAFB",
  },
})
