import { View, StyleSheet, Pressable } from "react-native"
import React, { useState } from "react"
import {
  Header,
  Sidebar,
  Screen,
  Text,
  Icon,
  PreviousWork,
  WebProfile,
  HeaderBar,
} from "app/components"
import { colors } from "app/theme"

export const PreviousWorksScreen = () => {
  const [isSelected, setIsSelected] = useState<boolean>(false)
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Your Profile" />
        <View style={styles.previousWorkWrapper}>
          <View style={[styles.previousWorkContainer, styles.flexStyle]}>
            <WebProfile />
          </View>

          <View style={{ flex: 1, backgroundColor: "#F9FAFB" }}>
            <View style={styles.previousWorkContainer}>
              <Text
                preset="default"
                size="lg"
                text="Previous Work"
                style={styles.prviousWorkStyle}
              />
              <View style={styles.selectWrapper}>
                {isSelected ? (
                  <View style={styles.deleteWrapper}>
                    <Text size="xs" text="Assign To" style={styles.selectStyle} />
                    <Text size="xs" text="Delete" style={styles.deletText} />
                  </View>
                ) : (
                  <Pressable onPress={() => setIsSelected(true)}>
                    <Text size="xs" text="Select" style={styles.selectStyle} />
                  </Pressable>
                )}
              </View>
              <Text size="xs" text="Haircut" style={{ marginBottom: 8, fontWeight: "600" }} />
              <PreviousWork isWeb isSelected={isSelected} previousWork />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  previousWorkContainer: {
    padding: 24,
    borderRadius: 16,
    backgroundColor: "#fff",
  },

  flexStyle: {
    flex: 1,
  },

  previousWorkWrapper: {
    padding: 24,
    backgroundColor: "#F9FAFB",
    flexDirection: "row",
    gap: 24,
  },

  prviousWorkStyle: {
    color: "#101828",
    marginBottom: 24,
  },
  selectStyle: {
    fontWeight: "500",
    color: colors.palette.primary500,
  },

  selectWrapper: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginBottom: 24,
  },

  deletText: {
    fontWeight: "500",
    color: "#EF4444",
  },

  deleteWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 24,
  },
})
