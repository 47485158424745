import React from "react"
import { View, StyleSheet, FlatList } from "react-native"
import { Header, Screen, Text, WebProfile, Icon, HeaderBar, Sidebar, Button } from "app/components"
import { colors } from "app/theme"
const premiumData: string[] = [
  "Chat Therapy Sessions ",
  "Smart client matching",
  "Session Scheduling",
]

export const ProviderSubscriptionScreen = () => {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={{ flex: 1 }}>
          <HeaderBar title="Your Profile" />
          <View style={styles.subscribeCover}>
            <View style={styles.subwrapper}>
              <WebProfile />
            </View>
            <View style={{ flex: 1 }}>
              <View style={styles.bannerWrapper}>
                <View style={styles.subBanner1}>
                  <View style={styles.flexBetween}>
                    <View style={styles.mildSpace}>
                      <Icon icon="lovely" size={18} />
                      <Text
                        preset="formHelper"
                        size="sm"
                        text="Premium"
                        style={{ fontWeight: "600", fontSize: 16, color: colors.palette.primary50 }}
                      />
                    </View>

                    <View style={styles.mildSpace}>
                      <Text preset="subheading" size="lg" text="$9.99" style={{ color: "#fff" }} />
                      <Text
                        preset="formHelper"
                        size="sm"
                        text="monthly"
                        style={{ fontWeight: "400", color: colors.palette.primary100 }}
                      />
                    </View>
                  </View>

                  {
                    <FlatList
                      data={premiumData}
                      renderItem={({ item }) => (
                        <View style={styles.mildSpace}>
                          <Icon icon="tickCircle" size={16} />
                          <Text
                            preset="default"
                            size="xs"
                            text={item}
                            style={{ fontWeight: "400", color: colors.palette.primary50 }}
                          />
                        </View>
                      )}
                      keyExtractor={(item) => item}
                    />
                  }

                  <Button preset="default" text="Try Now" onPress={() => {}} />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  bannerWrapper: {
    display: "flex",
    justifContent: "center",
    alignItems: "center",
    marginBottom: 16,
  },
  mildSpace: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginBottom: 16,
  },

  subBanner1: {
    display: "flex",
    width: 473,
    // width: 573,
    padding: 16,
    flexDirection: "column",
    justifyContent: "flex-end",
    // alignItems: "center",
    gap: 24,
    borderRadius: 16,
    backgroundColor: colors.palette.primary600,
  },

  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },

  subscribeCover: {
    padding: 24,
    backgroundColor: "#F9FAFB",
    flexDirection: "row",
    gap: 24,
  },
  subwrapper: {
    padding: 16,
    borderRadius: 6,
    backgroundColor: "#fff",
    flex: 1,
  },
})
