import * as React from "react"
import { StyleSheet, FlatList, View, ViewStyle, ImageRequireSource } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, Button, Icon, AutoImage } from "app/components"
import { useNavigation } from "@react-navigation/native"
import cutOne from "../../assets/images/first-cut.png"
import cutTwo from "assets/images/second-cut.png"
import cutThree from "assets/images/third-cut.png"
import cutFour from "assets/images/fourth-cut.png"

interface WorkImg {
  source: ImageRequireSource
  key: string
}

const previousWork: WorkImg[] = [
  { key: "1", source: cutOne },
  { key: "2", source: cutTwo },
  { key: "3", source: cutThree },
  { key: "4", source: cutFour },
]

export interface ProviderWebServicesProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const ProviderWebServices = observer(function ProviderWebServices(
  props: ProviderWebServicesProps,
) {
  const navigation = useNavigation()
  return (
    <View style={styles.serviceWrapper}>
      <View style={styles.flexBetween}>
        <Text preset="bold" size="lg" text="Hairs> Hair Cut" />
        <View style={styles.flexRow}>
          <Button
            onPress={() => navigation.navigate("EditService")}
            LeftAccessory={(props) => <Icon icon="Edit" size={20} />}
          />

          <Button
            // onPress={() => Alert.alert("pressed")}
            LeftAccessory={(props) => <Icon icon="deletIcon" size={20} />}
          />
        </View>
      </View>

      <Text
        size="xs"
        text="Get a nice haircut with a professional barber"
        style={styles.serviceTextStyle}
      />

      <View style={{ flexDirection: "row", gap: 24 }}>
        <View style={styles.textWrapper}>
          <Text size="xs" text="$500" style={{ fontWeight: "500" }} />
        </View>
        <View style={[styles.flexRow, styles.textWrapper]}>
          <Icon icon="HourGlass" size={16} color={colors.palette.neutral800} />
          <Text size="xs" text="30 minutes" style={{ fontWeight: "500" }} />
        </View>
      </View>

      <View style={styles.flexBetween}>
        <Text size="xs" text="Service For" style={styles.bolderTextStyle} />
        <Text size="xs" text="Male" style={styles.close2miText} />
      </View>
      <View style={styles.flexBetween}>
        <Text size="xs" text="Service Location" style={styles.bolderTextStyle} />
        <Text size="xs" text="Salon" style={styles.close2miText} />
      </View>

      <View style={styles.imageWrapper}>
        {previousWork.map((item) => (
          <View>
            <AutoImage source={item.source} style={styles.imageStyle} />
          </View>
        ))}
      </View>

      <View style={styles.flexBetween}>
        <Text size="xs" text="Available" />
        <Icon icon="toggleButton" />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  serviceWrapper: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },

  flexBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 8,
  },

  serviceTextStyle: {
    fontWeight: "400",
    color: colors.palette.neutral500,
  },

  bolderTextStyle: {
    fontWeight: "500",
    color: colors.palette.neutral500,
  },

  textWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 16,
    backgroundColor: "#F9FAFB",
  },

  close2miText: {
    fontWeight: "500",
    color: colors.palette.primary500,
  },

  imageStyle: {
    height: 120,
    width: 102,
    borderRadius: 8,
  },
  imageWrapper: {
    flexDirection: "row",
    alignItems: "center",
    height: 120,
    width: 102,
    gap: 16,
  },
})
