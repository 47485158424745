/* eslint-disable no-lone-blocks */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
  StyleSheet,
  Image,
  View,
  useWindowDimensions,
} from "react-native"
import { AppStackScreenProps } from "app/navigators"
import { BaseModal, Button, Footer, Header, Icon, Screen, Text, TextField } from "app/components"
import Checkbox from "expo-checkbox"
import {
  TextFieldData,
  addressType,
} from "app/components/serviceUser/DummyData/AddAddressConstants"
import { useNavigation } from "@react-navigation/native"
import { colors } from "app/theme"
import { useStores } from "app/models"
import { MapContainer, TileLayer } from "react-leaflet"
import "leaflet/dist/leaflet.css"

const dummyMap = require("../../../assets/images/dummyMap.png")

interface AddAddressScreenProps extends AppStackScreenProps<"AddAddress"> {}

export const AddAddressScreen: FC<AddAddressScreenProps> = observer(function AddAddressScreen(
  props,
) {
  const [loadModal, setLoadModal] = React.useState(true)
  const window = useWindowDimensions()
  const [windowsWidth, setWindowsWidth] = useState(0)
  useEffect(() => {
    setWindowsWidth(window.width)
  }, [])
  const [selectType, setSelectType] = useState(null)
  const navigation = useNavigation()
  const { authenticationStore } = useStores()
  const { params } = props.route.params || {}

  const { userType, isAuthenticated, setAuthToken, logout } = authenticationStore
  const {
    onboardingStore: {hasCompletedOnboarding},
  } = useStores()
  const decodeNextRoute = () => {

    {userType === "sp" ? navigation.navigate("Home") : navigation.navigate("ServiceUserHome")  }
  }

  return (
    <Screen preset="scroll">
      <View style={styles.pageContainer}>
        <View style={styles.PageLeftContainer}>
          <Icon icon="logo" size={72} style={{}} />
          <View style={[styles.InsideLeftContainer, { width: windowsWidth <= 960 ? 320 : 450 }]}>
            <Header title="Add Address" titleStyle={styles.headerTitle} />

            <Text
              text="Use My Current Address OK"
              preset="subheading"
              weight="semiBold"
              size="md"
              style={styles.mapTextStyle}
            />
            <Image source={dummyMap} style={styles.image} />
            <Text
              text="Address Type"
              preset="label"
              weight="normal"
              size="md"
              style={styles.textStyle}
            />
            <View style={styles.selectWrapper}>
              {addressType.map((type) => (
                <View key={type.id} style={styles.selectWrapper}>
                  <Text text={type.type} />
                  <Checkbox
                    value={selectType === type.type}
                    onValueChange={() => {
                      setSelectType(type.type)
                    }}
                    color={selectType === type.type ? colors.tint : undefined}
                  />
                </View>
              ))}
            </View>
            {TextFieldData.map((data) => (
              <TextField
                key={data.id}
                placeholder={data.placeholder}
                style={styles.textField}
                inputWrapperStyle={styles.textField}
                containerStyle={styles.containerStyle}
                keyboardType={data.type}
              />
            ))}
            <Button
              text="Confirm"
              style={styles.button}
              textStyle={styles.buttonText}
              pressedStyle={styles.pressedButton}
              pressedTextStyle={styles.pressedButtonText}
              onPress={() => {
                decodeNextRoute()
              }}
            />
          </View>
        </View>
        <View style={[styles.PageRightContainer, { display: windowsWidth <= 960 ? "none" : null }]}>
          {windowsWidth > 960 && (
            <View style={styles.PageRightContainer}>
              <MapContainer
                center={[51.505, -0.09]}
                zoom={13}
                scrollWheelZoom={false}
                style={{ width: "100%", height: "100%" }}
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
              </MapContainer>
            </View>
          )}
        </View>
        <BaseModal
          modalVisible={loadModal}
          modalBody={
            <>
              <View style={styles.container}>
                <Text style={styles.title}>Enable Location</Text>
                <Text style={styles.body}>
                  Close2mi would like to get your location to ensure you see businesses that are
                  close to your location
                </Text>

                <View style={styles.buttonContainer}>
                  <Button style={styles.cancelButton} onPress={() => setLoadModal(false)}>
                    <Text style={styles.cancelButtonText}>Cancel</Text>
                  </Button>
                  <Button
                    text="Enable"
                    style={styles.acceptButton}
                    textStyle={styles.buttonText}
                    onPress={() => {
                      setLoadModal(false)
                    }}
                    pressedStyle={styles.pressedButton}
                    pressedTextStyle={styles.pressedButtonText}
                  />
                </View>
              </View>
            </>
          }
          setModalVisible={() => setLoadModal(false)}
        />
      </View>
      <Footer />
    </Screen>
  )
})

const styles = StyleSheet.create({
  InsideLeftContainer: {
    width: 450,
  },
  PageLeftContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  PageRightContainer: {
    flex: 1,
  },
  acceptButton: {
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.primary600,
    borderRadius: 24,
    width: "48%",
  },
  acceptButtonText: { color: colors.palette.primary50, fontSize: 14, fontWeight: "500" },
  body: {
    color: "#6B7280",
    fontSize: 14,
    fontWeight: "500",
    marginBottom: 24,
    textAlign: "center",
  },
  button: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.primary600,
    borderRadius: 27.2,
    height: 48,
    justifyContent: "center",
    marginBottom: 20,
    width: "100%",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "space-between",
  },
  buttonText: {
    color: "#F5F5FF",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  cancelButton: {
    backgroundColor: colors.palette.primary50,
    borderColor: "#D0D5DD",
    borderRadius: 24,
    width: "48%",
  },
  cancelButtonText: { color: "#374151", fontSize: 14, fontWeight: "500" },
  container: {
    backgroundColor: colors.palette.primary50,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    padding: 20,
  },
  containerStyle: {
    marginBottom: 10,
  },
  headerTitle: {
    color: "#101828",
    fontSize: 20,
    fontWeight: "900",
    lineHeight: 32,
    marginBottom: 8,
  },
  image: {
    marginBottom: 16,
  },
  mapTextStyle: {
    color: colors.palette.primary600,
    marginBottom: 16,
  },
  pageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },
  pressedButtonText: {
    color: colors.palette.primary600,
  },
  selectWrapper: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "space-between",
    marginBottom: 10,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
    marginBottom: 20,
  },
  textStyle: {
    color: "#344054",
    marginBottom: 8,
  },
  title: {
    color: "#1F2937",
    fontSize: 20,
    fontWeight: "600",
    marginBottom: 16,
    textAlign: "center",
  },
})
