import React, { useState } from "react"
import { View, StyleSheet } from "react-native"
import { bookingsData, WebbookingsData } from "app/DummyData/BookingsData"
import { screenContentContainer } from "app/styles/mainStyles"
import {
  Screen,
  Text,
  Icon,
  TextField,
  SectionText,
  WebBookings,
  Button,
  BookingDataProps,
  Booking,
  PendingBookingsWeb,
  HeaderBar,
  Sidebar,
} from "app/components"
import { colors } from "app/theme"

export const ServiceProviderBookingsScreen = () => {
  const [activeTab, setActiveTab] = useState<string>("Upcoming")
  const [searchTerm, setSearchTerm] = useState(" ")
  const [dataToShow, setDataToShow] = useState<BookingDataProps[]>(bookingsData)

  const homeTab: string[] = ["Upcoming", "Completed", "Cancelled"]

  const tabChanged = (index: number) => {
    setActiveTab(homeTab[index])
    if (homeTab[index] === "Upcoming") {
      setDataToShow(bookingsData)
    } else {
      setDataToShow(bookingsData.filter((data) => data.status === homeTab[index]))
    }
  }
  return (
    <Screen preset="auto" contentContainerStyle={screenContentContainer} safeAreaEdges={["bottom"]}>
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={{ flex: 1 }}>
          <HeaderBar title="All Bookings" />
          <View style={styles.bookingCover}>
            <View style={{ flex: 1 }}>
              <View style={styles.bookingContainer}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 16,
                    borderRadius: 6,
                    backgroundColor: colors.palette.primary50,
                    gap: 8,
                    padding: 4,
                  }}
                >
                  {homeTab.map((item, i) => (
                    <Button
                      key={i}
                      onPress={() => tabChanged(i)}
                      text={item}
                      style={{
                        borderRadius: 6,
                        height: 44,
                        backgroundColor: item === activeTab ? "#fff" : colors.palette.primary50,
                      }}
                    />
                  ))}
                </View>

                <View style={[styles.flexRow, styles.filterContainer]}>
                  <TextField
                    value={searchTerm}
                    onChangeText={(text) => setSearchTerm(text)}
                    RightAccessory={() => (
                      <Icon icon="search" size={18} style={{ marginRight: 8 }} />
                    )}
                    inputWrapperStyle={{
                      borderRadius: 24,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  />

                  <View style={styles.filterWrapper}>
                    <Text size="xs" text="Sort by" style={{ fontWeight: "400" }} />
                    <Icon icon="caretDown" size={14} />
                  </View>
                </View>

                <WebBookings
                  tabStatus={activeTab}
                  data={WebbookingsData.filter((data) => data.status === activeTab)}
                />
              </View>
            </View>

            <View style={styles.bookingContainer}>
              <SectionText subheading="Pending Bookings" text="View All" />

              <Booking data={bookingsData.filter((data) => data.status === "Upcoming")} />
              {/* <PendingBookingsWeb /> */}
            </View>
            {/* the left side above and below the right side */}
          </View>
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  filterWrapper: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderStyle: "solid",
    paddingVertical: 8,
    paddingHorizontal: 16,
    borderRadius: 24,
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    backgroundColor: "#fff",
  },

  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  filterContainer: {
    marginBottom: 16,
    gap: 16,
  },

  bookingContainer: {
    flex: 1,
    backgroundColor: "#fff",
    borderRadius: 16,
    padding: 24,
  },

  bookingCover: {
    flexDirection: "row",
    gap: 24,
    padding: 24,
    backgroundColor: "#F9FAFB",
  },
})
