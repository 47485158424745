import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet, TouchableOpacity, useWindowDimensions } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import { Screen, Text, Button, Icon, Footer, ImagePatternWeb } from "app/components"
import { useStores } from "app/models"
import { screenContentContainer } from "app/styles/mainStyles"
import { colors, spacing } from "app/theme"
import Checkbox from "expo-checkbox"
import {
  Toast,
  useToast,
  ToastDescription,
  ToastTitle,
  CheckCircleIcon,
  HStack,
  Icon as GsICon,
  Center,
} from "@gluestack-ui/themed"

interface SelectUserTypeScreenProps extends AppStackScreenProps<"select-user-type"> {}

export const SelectUserTypeScreen: FC<SelectUserTypeScreenProps> = observer(
  
  
  function SelectUserTypeScreen(_props) {
    const window =  useWindowDimensions()
    const [windowsWidth, setWindowsWidth] = useState(0)
    useEffect(() => {
      setWindowsWidth(window.width)
    
    }, [])
    const { navigation } = _props

    // Pull in one of our MST stores
    const { authenticationStore, walkthroughStore } = useStores()
    const { userType } = authenticationStore
    const [isChecked, setIsChecked] = useState(false)
    const [noUserSelected, setNouserSelected] = useState(false)
    const toast = useToast()

    // Pull in navigation via hook
    const handleUserSelection = (userType: "su" | "sp") => {
      authenticationStore.setUserType(userType)
      toggleCheckbox(userType)
      setNouserSelected(isChecked)
    }

    const proceed = () => {
      if (!isChecked) {
        setNouserSelected(true)
        ToastT()
        return
      }
      walkthroughStore.setSeenWalkthrough(true)
      navigation.navigate("LoginSignin")
    }

    const toggleCheckbox = (user: string) => {
      if (userType === user) setIsChecked(!isChecked)
      else setIsChecked(true)
    }

    const ToastT = () => {
      toast.show({
        placement: "top",
        render: ({ id }: any) => {
          return (
            <RenderToast
              description="Please select an account type "
              nativeId={id}
              action="error"
            />
          )
        },
      })
    }
    const RenderToast = ({ description, title, id, action }: any) => {
      return (
        <Toast action={action} id={id} top={150}>
          <HStack alignItems="center" space="xs">
            <GsICon as={CheckCircleIcon} />
            <ToastTitle>{title}</ToastTitle>
            <ToastDescription>{description}</ToastDescription>
          </HStack>
        </Toast>
      )
    }
    return (
      <Screen style={{ flex: 1 }} preset="auto" safeAreaEdges={["top", "bottom"]}>
        <View style={styles.pageContainer}>
          <View style={styles.PageLeftContainer}>
            <Icon icon="logo" size={72} style={{}} />
            <View style={[styles.InsideLeftContainer,{ width: windowsWidth <= 960 ? 320 : 450} ]}>
              <View style={styles.topView}>
                <Text preset="heading" size={18} style={styles.title}>
                  Select Account Type
                </Text>
                <TouchableOpacity onPress={() => handleUserSelection("sp")}>
                  <View
                    style={[
                      styles.selectView,
                      {
                        borderColor:
                          isChecked && userType === "sp" ? colors.tint : colors.palette.neutral300,
                      },
                    ]}
                  >
                    <View style={styles.selectViewIcon}>
                      <Icon
                        icon="serviceProvider"
                        color={isChecked && userType === "sp" ? colors.tint : undefined}
                        size={40}
                      />

                      <Text
                        preset="label"
                        style={{
                          paddingLeft: spacing.sm,
                          color: isChecked && userType === "sp" ? colors.tint : undefined,
                        }}
                      >
                        Service Provider
                      </Text>
                    </View>
                    <View>
                      <Checkbox
                        style={styles.checkBox}
                        value={isChecked && userType === "sp"}
                        onValueChange={setIsChecked}
                        color={isChecked && userType === "sp" ? colors.tint : undefined}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => handleUserSelection("su")}>
                  <View
                    style={[
                      styles.selectView,
                      {
                        borderColor:
                          isChecked && userType === "su" ? colors.tint : colors.palette.neutral300,
                      },
                    ]}
                  >
                    <View style={styles.selectViewIcon}>
                      <Icon
                        icon="userAdd"
                        color={isChecked && userType === "su" ? colors.tint : undefined}
                        size={40}
                      />

                      <Text
                        preset="label"
                        style={{
                          paddingLeft: spacing.sm,
                          color: isChecked && userType === "su" ? colors.tint : undefined,
                        }}
                      >
                        Customer
                      </Text>
                    </View>
                    <View>
                      <Checkbox
                        style={styles.checkBox}
                        value={isChecked && userType === "su"}
                        onValueChange={setIsChecked}
                        color={isChecked && userType === "su" ? colors.tint : undefined}
                      />
                    </View>
                  </View>
                </TouchableOpacity>
              </View>
              <View style={styles.bottomView}>
                <Button onPress={proceed} text="Proceed" preset="primary" />
              </View>
            </View>
          </View>
          <View style={[styles.PageRightContainer, { display: windowsWidth <= 960 ? "none" : null }] }>
            <ImagePatternWeb />
          </View>
        </View>
        <Footer style={{ display: windowsWidth <= 960 ? "none" : null }} />
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  bottomView: {
    marginBottom: 50,
  },
  button: {
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 20,
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  checkBox: {
    alignItems: "center",
    borderColor: colors.palette.neutral400,
    borderRadius: 5,
    borderWidth: 2,
    height: 20,
    justifyContent: "center",
    marginRight: 10,
    width: 20,
  },
  InsideLeftContainer:{
    height: "70%",
    marginVertical: "10%",
    width: 450, 
  },
  pageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  PageLeftContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  PageRightContainer: {
    flex: 1,
  },
  selectView: {
    alignItems: "center",
    backgroundColor: colors.background,
    borderColor: colors.palette.neutral400,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  selectViewIcon: {
    alignItems: "center",
    flexDirection: "row",
  },
  title: {
    fontSize: 20,
    marginBottom: 44,
    textAlign: "center",
  },
  topView: {
    flex: 1,
  },
})
