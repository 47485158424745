import React, { useState } from "react"
import { StyleProp, StyleSheet, View, ViewStyle, TouchableOpacity, Pressable } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, Icon } from "app/components"
import Checkbox from "expo-checkbox"
import { profileData } from "app/DummyData/BookingsData"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "app/models"

export interface WebProfileProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const WebProfile = observer(function WebProfile(props: WebProfileProps) {
  const [enableNotification, setNotification] = useState(false)
  const [index, setIndex] = useState(0)
  const navigation = useNavigation()

  const routeTo = (url, i) => {
    navigation.navigate(url)
    setIndex(i)
  }

  const {
    authenticationStore: { logout },
  } = useStores()
  const {
    onboardingStore: { setHasCompletedOnboarding },
    walkthroughStore: { setSeenWalkthrough },
  } = useStores()

  const doLogout = () => {
    //TODO: uncomment this if you want to test onboarding screens
    setHasCompletedOnboarding(false)
    setSeenWalkthrough(false)
    logout()
  }
  return (
    <View style={styles.profileWrapper}>
      <Text preset="bold" size="lg" text="Profile" />
      <View style={{ marginBottom: 8 }}>
        {profileData.map((profile, i) => (
          <TouchableOpacity onPress={() => routeTo(profile.url, i)} key={i}>
            <View style={styles.flexBetween}>
              <Text
                size="xs"
                text={profile.name}
                style={{
                  color: index == i ? colors.palette.primary500 : colors.palette.neutral500,
                  fontWeight: "500",
                }}
              />
              <Icon icon="caretRight" size={16} />
            </View>
          </TouchableOpacity>
        ))}
      </View>
      <View>
        <Text size="xs" text="Reviews" style={styles.labelText} />
        <TouchableOpacity onPress={() => routeTo("Reviews", 6)}>
          <View style={styles.flexBetween}>
            <Text
              size="xs"
              text="User’s reviews"
              style={{
                color: index == 6 ? colors.palette.primary500 : colors.palette.neutral500,
                fontWeight: "500",
              }}
            />
            <Icon icon="caretRight" size={16} />
          </View>
        </TouchableOpacity>
      </View>

      <View>
        <Text size="xs" text="Support" style={styles.labelText} />
        <TouchableOpacity onPress={() => routeTo("ProviderSupport", 7)}>
          <View style={styles.flexBetween}>
            <Text
              size="xs"
              text="Close2mi Support"
              style={{
                color: index == 7 ? colors.palette.primary500 : colors.palette.neutral500,
                fontWeight: "500",
              }}
            />
            <Icon icon="caretRight" size={16} />
          </View>
        </TouchableOpacity>

        <View style={styles.flexBetween}>
          <TouchableOpacity onPress={() => routeTo("Notification", 8)}>
            <Text
              size="xs"
              text="Enable Notifications"
              style={{
                color: index == 8 ? colors.palette.primary500 : colors.palette.neutral500,
                fontWeight: "500",
              }}
            />
          </TouchableOpacity>
          <Checkbox
            value={enableNotification}
            onValueChange={setNotification}
            color={enableNotification ? colors.palette.primary600 : undefined}
          />
        </View>
      </View>

      <View>
        <Text size="xs" text="Policy" style={styles.labelText} />
        <TouchableOpacity onPress={() => routeTo("TermsAndCondition", 9)}>
          <View style={styles.flexBetween}>
            <Text
              size="xs"
              text="Terms and Conditions"
              style={{
                color: index == 9 ? colors.palette.primary500 : colors.palette.neutral500,
                fontWeight: "500",
              }}
            />
            <Icon icon="caretRight" size={16} />
          </View>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => routeTo("ProviderPolicy", 10)}>
          <View style={styles.flexBetween}>
            <Text
              size="xs"
              text="Privacy Policy"
              style={{
                color: index == 10 ? colors.palette.primary500 : colors.palette.neutral500,
                fontWeight: "500",
              }}
            />
            <Icon icon="caretRight" size={16} />
          </View>
        </TouchableOpacity>
      </View>

      <View>
        <Text size="xs" text="Account and Security" style={styles.labelText} />
        <TouchableOpacity onPress={() => routeTo("ChangedPassword", 11)}>
          <View style={styles.flexBetween}>
            <Text
              size="xs"
              text="Change Password"
              style={{
                color: index == 11 ? colors.palette.primary500 : colors.palette.neutral500,
                fontWeight: "500",
              }}
            />
          </View>
        </TouchableOpacity>

        <Pressable onPress={doLogout}>
          <View style={styles.flexBetween}>
            <Text size="xs" text="Sign Out" style={styles.profileText} />
          </View>
        </Pressable>

        <View style={styles.flexBetween}>
          <Text size="xs" text="Delete Account" style={styles.profileText} />
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  profileWrapper: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 32,
  },
  profileText: {
    fontWeight: "500",
    color: colors.palette.neutral500,
  },
  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
    marginBottom: 24,
    borderBottomWidth: 0.5,
    borderBottomStyle: "solid",
    borderBottomColor: colors.palette.neutral200,
  },

  labelText: {
    fontWeight: "600",
    color: "#1F2937",
  },

  // divider: {},
})
