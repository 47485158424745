import * as React from "react"
import { FlatList, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors } from "app/theme"
import { Text } from "app/components/Text"
import { TextField } from "../TextField"
import { Button } from "../Button"
import { Icon } from "../Icon"

export interface FooterProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const Footer = observer(function Footer(props: FooterProps) {
  const { style } = props
  const hrefAttrs = { download: true, rel: "nofollow", target: "blank" }

  const company = [
    {
      name: "About us",
      url: "#",
    },
    {
      name: "Careers",
      url: "#",
    },
    {
      name: "Press",
      url: "#",
    },
    {
      name: "News",
      url: "#",
    },
    {
      name: "Media kit",
      url: "#",
    },
    {
      name: "Contact",
      url: "#",
    },
  ]

  const Services = [
    { name: "Hairs", url: "#" },
    { name: "Nails", url: "#" },
    { name: "Facial", url: "#" },
    { name: "Spa", url: "#" },
  ]

  const Social = [
    { name: "Twitter", url: "https://twitter.com/close2mi_uk" },
    { name: "LinkedIn", url: "https://www.linkedin.com/company/close2mi/about/" },
    { name: "Facebook", url: "https://www.facebook.com/profile.php?id=100064335571650" },
    { name: "Instagram", url: "https://www.instagram.com/close_2_mi/" },
  ]

  const ContactUs = [
    { name: "Close2mi@gmail.com", url: "#" },
    { name: "1234567890", url: "#" },
  ]

  return (
    <View style={styles.Container}>
      <View style={styles.contentContainer}>
        <View style={styles.HeaderContainer}>
          <Text text="Join our newsletter" style={styles.text} />
          <View style={styles.HeaderRightContainer}>
            <TextField
              placeholder="Enter your mail"
              style={styles.Input}
              inputWrapperStyle={styles.Input}
              placeholderTextColor={colors.palette.neutral100}
            />
            <Button text="Subscribe" style={styles.Button} />
          </View>
        </View>
        <View>
          <Text
            text="We'll send you a nice letter once per once per week. No spam."
            style={styles.text}
          />
        </View>
        <View style={styles.linksContainer}>
          <View>
            <Text
              text="Company"
              size="md"
              weight="medium"
              style={{ color: colors.palette.neutral100 }}
            />
            <FlatList
              style={styles.linksListWrapper}
              data={company}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <Text
                  key={index}
                  href={item.url}
                  hrefAttrs={hrefAttrs}
                  text={item.name}
                  style={styles.text}
                />
              )}
            />
          </View>
          <View>
            <Text
              text="Services"
              size="md"
              weight="medium"
              style={{ color: colors.palette.neutral100 }}
            />
            <FlatList
              data={Services}
              style={styles.linksListWrapper}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <Text
                  key={index}
                  href={item.url}
                  hrefAttrs={hrefAttrs}
                  text={item.name}
                  style={styles.text}
                />
              )}
            />
          </View>
          <View>
            <Text
              text="Socials"
              size="md"
              weight="medium"
              style={{ color: colors.palette.neutral100 }}
            />
            <FlatList
              data={Social}
              style={styles.linksListWrapper}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <Text
                  key={index}
                  href={item.url}
                  hrefAttrs={hrefAttrs}
                  text={item.name}
                  style={styles.text}
                />
              )}
            />
          </View>
          <View>
            <Text
              text="Contact Us"
              size="md"
              weight="medium"
              style={{ color: colors.palette.neutral100 }}
            />
            <FlatList
              data={ContactUs}
              style={styles.linksListWrapper}
              keyExtractor={(item, index) => index.toString()}
              renderItem={({ item, index }) => (
                <Text
                  key={index}
                  href={item.url}
                  text={item.name}
                  hrefAttrs={hrefAttrs}
                  style={styles.text}
                />
              )}
            />
          </View>
        </View>
        <View style={styles.HeaderContainer}>
          <View>
            {/* <Image source={} /> */}
            <Icon icon="logoWhite" size={52} />
          </View>
          <View>
            <Text text="© 2077 Close2mi. All rights reserved." style={styles.text} />
          </View>
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  Button: {
    backgroundColor: colors.palette.neutral100,
    borderRadius: 8,
    color: colors.palette.primary600,
    padding: 0.5,
  },
  Container: {
    backgroundColor: colors.palette.primary600,
    color: colors.palette.neutral100,
    display: "flex",
    justifyContent: "space-between",
    paddingVertical: 48,
  },
  HeaderContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  HeaderRightContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 20,
  },
  Input: {
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.neutral100,
    borderRadius: 6,
    color: colors.palette.neutral100,
    padding: 1,
  },
  contentContainer: {
    display: "flex",
    gap: 32,
    marginHorizontal: "auto",
    minWidth: 1200,
  },
  linksContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 32,
    justifyContent: "space-between",
  },
  linksListWrapper: {
    gap: 15,
  },
  text: {
    color: colors.palette.neutral100,
  },
})
