import React, { useState } from "react"
import {
  ViewStyle,
  StyleSheet,
  Image,
  View,
  ScrollView,
  KeyboardAvoidingView,
  Platform,
} from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Button, Header, Screen, Text, TextField, Icon } from "app/components"
import Checkbox from "expo-checkbox"
import {
  WebTextFieldData,
  addressType,
} from "app/components/serviceUser/DummyData/AddAddressConstants"
// const dummyMap = require("../../../assets/images/dummyMap.png")

export interface WebAddressProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const WebAddress = observer(function WebAddress(props: WebAddressProps) {
  const [selectType, setSelectType] = useState(null)
  const [newAddress, setNewAddress] = useState(false)
  return (
    <View>
      <Header title="Add Address" titleStyle={styles.headerTitle} />
      <Text
        text="Use My Current Address OK"
        preset="subheading"
        weight="semiBold"
        size="md"
        style={styles.mapTextStyle}
      />
      <TextField
        placeholder="Search"
        LeftAccessory={() => <Icon icon="search" size={16} style={{ marginLeft: 8 }} />}
        style={{ borderWidth: 0 }}
        containerStyle={{
          marginVertical: 20,
        }}
        inputWrapperStyle={{
          height: 44,
          flexDirection: "row",
          alignItems: "center",
        }}
      />
      {/* <Image source={dummyMap} style={styles.image} /> */}
      <Text text="Address Type" preset="label" weight="normal" size="md" style={styles.textStyle} />
      <View>
        <View style={styles.addressWrapper}>
          <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
            <Icon icon="pin" size={16} />
            <Text text="24 Montgomery Road, New York" weight="normal" size="xxs" />
          </View>

          <View style={styles.salonWrapper}>
            <Text text="Salon" weight="medium" style={{ color: colors.palette.primary500 }} />
          </View>
        </View>

        {!newAddress && (
          <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
            <Text text="Add  New  Address" />
            <Icon icon="plus" size={16} onPress={() => setNewAddress(true)} />
          </View>
        )}
      </View>

      {newAddress && (
        <>
          <View style={styles.selectWrapper}>
            {addressType.map((type) => (
              <View key={type.id} style={styles.selectWrapper}>
                <Text text={type.type} />
                <Checkbox
                  value={selectType === type.type}
                  onValueChange={() => {
                    setSelectType(type.type)
                  }}
                  color={selectType === type.type ? colors.tint : undefined}
                />
              </View>
            ))}
          </View>

          {WebTextFieldData.map((data) => (
            <TextField
              key={data.id}
              placeholder={data.placeholder}
              style={styles.textField}
              inputWrapperStyle={styles.textField}
              containerStyle={styles.containerStyle}
              keyboardType={data.type}
            />
          ))}

          <View style={styles.flexRow}>
            <TextField
              placeholder="City"
              style={styles.textField}
              inputWrapperStyle={styles.textField}
              keyboardType="default"
            />

            <TextField
              placeholder="Country"
              style={styles.textField}
              inputWrapperStyle={styles.textField}
              keyboardType="default"
            />
          </View>

          <TextField
            placeholder="Post Code"
            style={styles.textField}
            inputWrapperStyle={styles.textField}
            containerStyle={styles.containerStyle}
            keyboardType="default"
          />

          <Button
            text="Confirm"
            style={styles.button}
            textStyle={styles.buttonText}
            pressedStyle={styles.pressedButton}
            pressedTextStyle={styles.pressedButtonText}
          />
        </>
      )}
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  headerTitle: {
    lineHeight: 32,
    fontSize: 20,
    color: "#101828",
    fontWeight: "900",
    marginBottom: 8,
  },
  mapTextStyle: {
    color: colors.palette.primary600,
    marginBottom: 16,
  },
  image: {
    marginBottom: 16,
  },
  textStyle: {
    color: "#344054",
    marginBottom: 8,
  },
  selectWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
  },
  containerStyle: {
    marginBottom: 10,
  },

  button: {
    borderRadius: 27.2,
    backgroundColor: colors.palette.primary600,
    width: "100%",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    height: 48,
    borderColor: colors.palette.primary600,
    marginBottom: 20,
  },
  buttonText: {
    fontSize: 16,
    fontWeight: "600",
    color: "#F5F5FF",
    textAlign: "center",
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },
  pressedButtonText: {
    color: colors.palette.primary600,
  },

  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    flex: 1,
    marginBottom: 10,
  },

  salonWrapper: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    backgroundColor: "#F5F5FF",
    borderRadius: 16,
  },

  addressWrapper: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 16,
    paddingVertical: 4,
    marginBottom: 42,
  },
})
