import * as React from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, Icon } from "app/components"

export interface WebBoxesProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const WebBoxes = observer(function WebBoxes(props: WebBoxesProps) {
  return (
    <View>
      <View style={{ width: 536, flexDirection: "row", gap: 16 }}>
        <View style={styles.box}>
          <Icon icon="carbontotal" style={{ marginBottom: 12 }} size={20} />
          <Text size="xs" text="Total Earnings" style={styles.boxText} />
          <Text size="xl" text="$ 1,000" style={styles.boxHeaderText} />
        </View>
        <View style={styles.box}>
          <Icon icon="carbonchart" style={{ marginBottom: 12 }} size={20} />
          <Text size="xs" text="Average Daily " style={styles.boxText} />
          <Text size="xl" text="$ 1,200" style={styles.boxHeaderText} />
        </View>
        <View style={styles.box}>
          <Icon icon="symbols" style={{ marginBottom: 12 }} size={20} />
          <Text size="xs" text="Upcoming Earnings" style={styles.boxText} />
          <Text size="xl" text="$ 8,000" style={styles.boxHeaderText} />
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  boxText: {
    fontWeight: "600",
    color: "#F9FAFB",
    marginBottom: 8,
  },

  boxHeaderText: {
    fontWeight: "600",
    color: "#fff",
  },

  box: {
    width: 168,
    height: 148,
    paddingVertical: 16,
    paddingHorizontal: 12,
    backgroundColor: colors.palette.primary500,
    borderRadius: 16,
  },
})
