import { WebProfile, Text, Button, Icon, AutoImage, HeaderBar, Sidebar } from "app/components"
import React, { useState } from "react"
import Checkbox from "expo-checkbox"
import { colors } from "app/theme"
import { View, StyleSheet, FlatList } from "react-native"
import profilePics from "assets/images/profileImg.png"
import { screenContentContainer } from "app/styles/mainStyles"

export const ServiceProviderProfileScreen = () => {
  const [serviceFor, setServiceFor] = useState(false)
  const [serviceLocation, setServiceLocation] = useState(false)

  const premiumData: string[] = [
    "Chat Therapy Sessions ",
    "Smart client matching",
    "Session Scheduling",
  ]
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Your Profile" />
        <View style={styles.profileWrapper}>
          <View style={styles.webProfile}>
            <WebProfile />
          </View>

          <View style={styles.profileCover}>
            <View style={styles.profileInfo}>
              <View style={styles.flexBetween}>
                <Text preset="bold" size="lg" text="Detail" />
                <Button
                  onPress={() => {}}
                  LeftAccessory={(props) => <Icon icon="Edit" size={18} />}
                />
              </View>

              <View>
                <AutoImage source={profilePics} style={{ width: 32, height: 32 }} />
                <Text preset="bold" size="sm" text="Clean Kutz" />
                <Text size="sm" text="anitajoseph@gmail.com" style={styles.profileTxt} />
                <Text size="sm" text="09096631152" style={styles.profileTxt} />
                <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
                  <Icon icon="pin" size={14} />
                  <Text
                    size="sm"
                    text="24 Montgomery Road, New York"
                    style={{ fontWeight: "400", color: colors.palette.neutral800 }}
                  />
                </View>
              </View>

              <View>
                <View style={styles.flexBetween}>
                  <Text size="xs" text="Offer Service for" />
                  <View style={styles.flexRow}>
                    <Text
                      weight="medium"
                      size="xs"
                      text="Male and Female"
                      style={{
                        color: serviceFor ? colors.palette.primary600 : colors.palette.neutral500,
                      }}
                    />

                    <Checkbox
                      value={serviceFor}
                      onValueChange={setServiceFor}
                      color={serviceFor ? colors.palette.primary600 : undefined}
                    />
                  </View>
                </View>
              </View>
              {/* the div goes here */}
              <View style={styles.flexBetween}>
                <Text size="xs" text="Service Location" />
                <View style={styles.flexRow}>
                  <Text
                    weight="medium"
                    size="xs"
                    text="Male and Female"
                    style={{
                      color: serviceLocation
                        ? colors.palette.primary600
                        : colors.palette.neutral500,
                    }}
                  />

                  <Checkbox
                    value={serviceLocation}
                    onValueChange={setServiceLocation}
                    color={serviceLocation ? colors.palette.primary600 : undefined}
                  />
                </View>
              </View>
            </View>

            <View>
              <View style={styles.bannerWrapper}>
                <View style={styles.subBanner1}>
                  <View style={styles.flexBetween}>
                    <View style={styles.mildSpace}>
                      <Icon icon="lovely" size={18} />
                      <Text
                        preset="formHelper"
                        size="sm"
                        text="Premium"
                        style={{ fontWeight: "600", fontSize: 16, color: colors.palette.primary50 }}
                      />
                    </View>

                    <View style={styles.mildSpace}>
                      <Text preset="subheading" size="lg" text="$9.99" style={{ color: "#fff" }} />
                      <Text
                        preset="formHelper"
                        size="sm"
                        text="monthly"
                        style={{ fontWeight: "400", color: colors.palette.primary100 }}
                      />
                    </View>
                  </View>

                  {
                    <FlatList
                      data={premiumData}
                      renderItem={({ item }) => (
                        <View style={styles.mildSpace}>
                          <Icon icon="tickCircle" size={16} />
                          <Text
                            preset="default"
                            size="xs"
                            text={item}
                            style={{ fontWeight: "400", color: colors.palette.primary50 }}
                          />
                        </View>
                      )}
                      keyExtractor={(item) => item}
                    />
                  }

                  <Button preset="default" text="Try Now" onPress={() => {}} />
                </View>
              </View>

              <View style={styles.socialMedia}>
                <Text preset="bold" size="lg" text="Social Media" style={{ marginBottom: 24 }} />
                <View style={styles.flexRow}>
                  <View style={styles.socoialMediaWrapper}>
                    <Icon icon="facebook" size={18} />
                  </View>
                  <View style={styles.socoialMediaWrapper}>
                    <Icon icon="instagram" size={18} />
                  </View>
                  <View style={styles.socoialMediaWrapper}>
                    <Icon icon="twitter" size={18} />
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  profileCover: {
    padding: 16,
    borderRadius: 16,
    flex: 1,
  },
  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  profileTxt: {
    fontWeight: "400",
    color: colors.palette.neutral800,
    marginBottom: 8,
  },

  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  bannerWrapper: {
    display: "flex",
    justifContent: "center",
    alignItems: "center",
    marginBottom: 16,
  },
  mildSpace: {
    flexDirection: "row",
    gap: 8,
    alignItems: "center",
    marginBottom: 16,
  },
  subBanner1: {
    display: "flex",
    width: 473,
    // width: 573,
    padding: 16,
    flexDirection: "column",
    justifyContent: "flex-end",
    // alignItems: "center",
    gap: 24,
    borderRadius: 16,
    backgroundColor: colors.palette.primary600,
  },

  socialMedia: {
    padding: 24,
    borderRadius: 16,
    backgroundColor: "#fff",
  },

  socoialMediaWrapper: {
    height: 64,
    weight: 64,
    borderRadius: 80,
    padding: 20,
    flexDirection: "row",
    justifContent: "center",
    alignItems: "center",
    flex: 1,
    backgroundColor: colors.palette.primary500,
  },

  profileWrapper: {
    flexDirection: "row",
    gap: 24,
    backgroundColor: "#F9FAFB",
    padding: 24,
  },

  profileInfo: {
    backgroundColor: "#fff",
    marginVertical: 24,
    padding: 16,
    borderRadius: 16,
  },

  webProfile: {
    backgroundColor: "#fff",
    marginVertical: 24,
    padding: 16,
    borderRadius: 16,
    flex: 1,
  },
})
