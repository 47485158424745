/* eslint-disable react-native/no-color-literals */
import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors } from "app/theme"
import { Text } from "app/components/Text"
import { TextField } from "app/components/TextField"
import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { Button } from "app/components/Button"
import { ImageUploader } from "app/components/ImageUploader"
import { Header } from "app/components/Header"

export interface SupportWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const SupportWeb = observer(function SupportWeb(props: SupportWebProps) {
  const { style } = props
  const $styles = [$container, style]

  return (
    <View style={$styles}>
      <Header title="Support" />
      <TextField
        label="Subject"
        keyboardType="default"
        style={styles.textField}
        inputWrapperStyle={styles.textFieldContainer}
      />
      <TextField
        label="Description"
        keyboardType="default"
        style={styles.textField}
        inputWrapperStyle={styles.textFieldContainer}
        multiline
        placeholder="Type here..."
      />
      <Text text="40 characters left" style={styles.textFieldMessage} weight="medium" />
      <SectionHeader leftText="Images" />
      <Text text="Add Service Images" style={styles.textFieldMessage} weight="medium" />
      <ImageUploader dontShowText />
      <Button
        text="Submit"
        style={styles.button}
        textStyle={styles.buttonText}
        pressedStyle={styles.pressedButton}
        pressedTextStyle={styles.pressedButtonText}
      />{" "}
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}
const styles = StyleSheet.create({
  button: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.primary600,
    borderRadius: 27.2,
    height: 48,
    justifyContent: "center",
    marginVertical: 20,
    width: "100%",
  },
  buttonText: {
    color: "#F5F5FF",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },
  pressedButtonText: {
    color: colors.palette.primary600,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
  },
  textFieldContainer: {
    backgroundColor: colors.palette.neutral100,
    borderColor: "#D1D5DB",
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: 10,
  },
  textFieldMessage: {
    color: "#667085",
  },
})
