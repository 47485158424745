import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  Header,
  Screen,
  Text,
  WebProfile,
  Icon,
  Button,
  AutoImage,
  HeaderBar,
  Sidebar,
} from "app/components"
import { screenContentContainer } from "app/styles/mainStyles"
import { useNavigation } from "@react-navigation/native"
import { colors } from "app/theme"
import profilePics from "assets/images/profileImg.png"
// import { useStores } from "app/models"

interface OwnersScreenProps extends AppStackScreenProps<"Owners"> {}

export const OwnersScreen: FC<OwnersScreenProps> = observer(function OwnersScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  const navigation = useNavigation()

  return (
    <Screen
      preset="scroll"
      contentContainerStyle={screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
    >
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={styles.flexStyle}>
          <HeaderBar title="Your Profile" />
          <View style={styles.ownersCover}>
            <View style={[styles.providerContainer, styles.flexStyle]}>
              <WebProfile />
            </View>

            <View style={styles.ownerInfo}>
              <View style={styles.providerContainer}>
                <View style={styles.flexBetween}>
                  <Text preset="bold" size="md" text="Personal Details" />
                  <Button
                    onPress={() => {}}
                    LeftAccessory={(props) => <Icon icon="Edit" size={18} />}
                  />
                </View>
                <View>
                  <AutoImage source={profilePics} style={{ width: 32, height: 32 }} />
                  <Text preset="bold" size="sm" text="Clean Kutz" />
                  <Text size="sm" text="anitajoseph@gmail.com" style={styles.profileTxt} />
                  <Text size="sm" text="09096631152" style={styles.profileTxt} />
                  <View style={{ flexDirection: "row", alignItems: "center", gap: 4 }}>
                    <Icon icon="pin" size={14} />
                    <Text size="sm" text="24 Montgomery Road, New York" style={styles.profileTxt} />
                  </View>
                </View>
                <View>
                  <Text preset="bold" size="xs" text="Stripe Details" />
                  <Text preset="default" size="xs" text="23974093740jv" weight="medium" />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
    </Screen>
  )
})

const styles = StyleSheet.create({
  providerContainer: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
  },
  flexBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },

  profileTxt: {
    fontWeight: "400",
    color: colors.palette.neutral800,
    marginBottom: 8,
  },

  flexStyle: {
    flex: 1,
  },
  ownersCover: {
    backgroundColor: "#F9FAFB",
    flexDirection: "row",
    gap: 40,
    paddingRight: 40,
    paddingLeft: 24,
    paddingTop: 16,
  },
  ownerInfo: { backgroundColor: "#F9FAFB", flex: 1 },
})
