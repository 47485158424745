export const address = [
{
    address: "24 Montgomery Road, New York",
    Tag: "Home"
},
{
    address: "24 Montgomery Road, New York",
    Tag: "Work"
},
{
    address: "24 Montgomery Road, New York",
    Tag: "School"
},
]