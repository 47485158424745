/* eslint-disable react-native/no-inline-styles */
import React, { FC, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ImageBackground, View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  AutoImage,
  Screen,
  Button,
  Text,
  Modals,
  ImagePatternWeb,
  Icon,
  Footer,
} from "app/components"
import { screenContentContainer } from "app/styles/mainStyles"
import { colors, spacing } from "app/theme"
import SplashBackground from "assets/images/line-pattern.png"
import Logo from "assets/images/app-icon-all.png"
import { useWindowDimensions } from "react-native"

// import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface LoginSigninScreenProps extends AppStackScreenProps<"LoginSignin"> {}

export const LoginSigninScreen: FC<LoginSigninScreenProps> = observer(function LoginSigninScreen(
  _props,
) {
  const window = useWindowDimensions()
  const [windowsWidth, setWindowsWidth] = useState(0)
  useEffect(() => {
    setWindowsWidth(window.width)
  }, [])
  const { navigation } = _props
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  const [showModal, setShowModal] = React.useState(false)

  // Pull in navigation via hook

  // const navigation = useNavigation()
  const register = () => {
    navigation.navigate("Signup")
  }
  const login = () => {
    navigation.navigate("Signin")
  }
  return (
    <Screen preset="auto" contentContainerStyle={styles.splashContainer} style={{ flex: 1 }}>
      <View style={styles.pageContainer}>
        <View style={{ flexDirection: "row", justifyContent: "flex-start" }}>
          <Icon icon="logo" size={72} style={{}} />
        </View>
        <View style={styles.PageLeftContainer}>
          <ImageBackground source={SplashBackground} style={styles.background}>
            <View style={[styles.container, styles.InsideLeftContainer]}>
              <View style={styles.topView}>
                <AutoImage source={Logo} style={styles.logo} />
              </View>
              <View style={styles.bottomView}>
                <View style={styles.button}>
                  <Button onPress={register} text="Create Account" preset="primary" />
                </View>
                <View style={styles.button}>
                  <Button onPress={login} text="Sign In" preset="primary" />
                </View>
              </View>
            </View>
            <Modals
              showModal={showModal}
              setShowModal={setShowModal}
              modalTitle={"Allow Location"}
              modalBody={"We need your permission to access your location"}
              navigate={() => navigation.navigate("Notifications")}
              modalCancelButtonText={"Don't Allow"}
              modalAcceptButtonText={"Allow"}
            />
          </ImageBackground>
        </View>

        <View style={[styles.PageRightContainer, { display: windowsWidth <= 960 ? "none" : null }]}>
          <ImagePatternWeb />
        </View>
      </View>
      <Footer style={{ display: windowsWidth <= 960 ? "none" : null }} />
    </Screen>
  )
})

const styles = StyleSheet.create({
  InsideLeftContainer: {
    height: "70%",
    marginVertical: "10%",
    width: 450,
  },
  PageLeftContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  PageRightContainer: {
    flex: 1,
  },
  background: {
    flex: 1,
    resizeMode: "cover",
  },
  bottomView: {
    marginBottom: 20,
  },
  button: {
    justifyContent: "center",
    paddingHorizontal: 16,
    paddingVertical: 15,
  },
  container: {
    flex: 1,
    flexDirection: "column",
  },
  logo: {
    height: 120,
    width: 120,
  },
  pageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  selectView: {
    alignItems: "center",
    backgroundColor: colors.background,
    borderColor: colors.palette.neutral400,
    borderRadius: 8,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 20,
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  selectViewIcon: {
    alignItems: "center",
    flexDirection: "row",
  },
  splashContainer: {
    flex: 1,
    // flexDirection: "row",
    paddingVertical: spacing.md,
  },
  title: {
    fontSize: 20,
    marginBottom: 44,
    textAlign: "center",
  },
  topView: {
    alignContent: "center",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
})
