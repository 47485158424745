import React from "react"
import { View, StyleSheet } from "react-native"
import {
  Screen,
  Text,
  BackArrowHeader,
  TextField,
  Icon,
  Button,
  ImageUploader,
  ProviderProfile,
  WebProfile,
  HeaderBar,
  Sidebar,
} from "app/components"

const issues = [
  {
    complaint: "Payment Issues",
    time: "Thursday 16th October 11:44am",
  },
  {
    complaint: "Address Issues",
    time: "Thursday 16th October 11:44am",
  },
  {
    complaint: "Address Issues",
    time: "Thursday 16th October 11:44am",
  },
]

export const ProviderSupportScreen = () => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Your Profile" />
        <View style={styles.supportWrapper}>
          <View style={[styles.supportCover, styles.flexStyle]}>
            <WebProfile />
          </View>
          <View style={{ flex: 1, backgroundColor: "#F9FAFB" }}>
            <View style={styles.supportCover}>
              <View style={{ marginBottom: 32 }}>
                <Text preset="default" size="lg" text="Support" />
                <View style={styles.flexRow}>
                  <Text size="xs" text="Start New Message" weight="medium" />
                  <Icon icon="add" />
                </View>
              </View>

              <View>
                {issues.map((issue, i) => (
                  <View style={styles.flexBetween} key={i}>
                    <Text size="xs" text={issue.complaint} weight="medium" />
                    <Text
                      size="xxs"
                      text={issue.time}
                      weight="normal"
                      style={{ color: "#667085" }}
                    />
                    <Icon icon="caretRight" size={16} />
                  </View>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },

  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 16,
    marginBottom: 8,
    borderBottomWidth: 0.5,
    borderBottomStyle: "solid",
    borderBottomColor: "#E5E7EB",
  },

  supportCover: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
  },
  flexStyle: {
    flex: 1,
  },
  supportWrapper: {
    padding: 24,
    backgroundColor: "#F9FAFB",
    flexDirection: "row",
    gap: 24,
  },
})
