/* eslint-disable react-native/no-color-literals */
import * as React from "react"
import { StyleProp, View, ViewStyle, StyleSheet, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { Icon } from "../Icon"
import { useNavigation, useRoute } from "@react-navigation/native"
import { Text } from "../Text"

export interface HeaderForWebViewProps {
  style?: StyleProp<ViewStyle>
}

export const HeaderForWebView = observer(function HeaderForWebView(props: HeaderForWebViewProps) {
  const { style } = props
  const navigation = useNavigation()
  const route = useRoute()
  const [activeNav, setActiveNav] = React.useState("ServiceUserHome")

  const handleNavLinkPress = (routeName: string) => {
    if (activeNav !== routeName) {
      navigation.navigate(routeName)
      setActiveNav(routeName)
    }
  }

  const renderNavLink = (routeName: string, icon: string, text: string) => {
    const isNavLinkActive = route.name === routeName

    return (
      <TouchableOpacity onPress={() => handleNavLinkPress(routeName)} style={styles.navLink}>
        <Icon icon={icon} size={24} color={isNavLinkActive ? "#2895FE" : undefined} />
        <Text style={isNavLinkActive ? styles.activeNavLinkText : {}} size="sm" weight="medium">
          {text}
        </Text>
      </TouchableOpacity>
    )
  }

  return (
    <View style={[styles.contentContainer, style]}>
      <View style={styles.headerContainer}>
        <TouchableOpacity onPress={() => handleNavLinkPress("ServiceUserHome")}>
          <Icon icon="logo" size={72} />
        </TouchableOpacity>
        <View style={styles.navLinkContainer}>
          {renderNavLink("ServiceUserHome", "Home", "Home")}
          {renderNavLink("ServiceUserCart", "market", "Cart")}
          {renderNavLink("ServiceUserBookings", "Calendar", "Bookings")}
          {renderNavLink("ServiceUserNotification", "notification", "Notification")}
          {renderNavLink("ServiceUserProfile", "userImage", "John Smith")}
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  activeNavLinkText: {
    color: "#2895FE",
  },
  contentContainer: {
    alignContent: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  headerContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginHorizontal: "auto",
    maxWidth: 1200,
    width: "100%",
  },
  navLink: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 8,
    padding: 8,
  },
  navLinkContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 32,
  },
})
