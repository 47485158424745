import * as React from "react"
import { StyleProp, TextStyle, View, StyleSheet, Image } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text } from "app/components/Text"
import SplashImg1 from "assets/images/splash-1.png"
import SplashImg2 from "assets/images/splash-2.png"
import linepattern from "assets/images/Line-pattern-web.png"

export interface Close2MiPatternProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const Close2MiPattern = observer(function Close2MiPattern(props: Close2MiPatternProps) {
  return (
    <View style={styles.containerStyle}>
      <View style={{ marginTop: "74px", marginLeft: "24px" }}>
        <Image source={SplashImg1} alt="person" style={styles.imgStyle} />
      </View>
      <View>
        <Image source={linepattern} alt="pattern" style={styles.patternDimension} />
      </View>

      <View>
        <Image source={linepattern} alt="pattern" style={styles.patternDimension} />
      </View>

      <View style={{ marginBottom: "30", marginRight: "24" }}>
        <Image source={SplashImg2} alt="massage" style={styles.imgStyle} />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  containerStyle: {
    width: 720,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    backgroundColor: "#F3F4F6",
    gap: 55,
    margin: 0,
    padding: 0,
  },

  patternDimension: {
    width: 298,
    height: 408,
    objectFit: "contain",
  },

  imgStyle: {
    width: 343,
    height: 380,
    borderRadius: 16,
  },
})
