import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, spacing,  } from "app/theme"
import { Header } from "app/components/Header"
import { CreditCard } from "app/components/Common/CreditCard"
import { Button } from "app/components/Button"
import { TextField } from "app/components/TextField"
import { HorizontalTabs } from "app/components/serviceUser/HorizontalTabs"
import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { addCardTab } from "app/components/serviceUser/DummyData/BookingScreenData"

export interface SavedCardsWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const SavedCardsWeb = observer(function SavedCardsWeb(props: SavedCardsWebProps) {
  const { style } = props
  const $styles = [$container, style]
  const [activeCard, setActiveCard] = React.useState(false)
  const [activeCardId, setActiveCardId] = React.useState(0)
  const [activeTab, setActiveTab] = React.useState(0)


  const setCardActive = () => {
    setActiveCardId(0)
    setActiveCard(true)
  }
  return (
    <View style={$styles}>
      <Header title="Saved Cards"/>
      <View style={styles.pageWrapper}>
        <View style={styles.cardsWrapper}>
          <CreditCard onCardClicked={setCardActive} isActive={activeCard} dontShowText dontShowAddNew/>
        </View>
      </View>
      <SectionHeader leftText="Add Card" />
      <HorizontalTabs tabs={addCardTab} setActiveTab={setActiveTab} activeTab={activeTab}/>
      <TextField
        label="Card Number"
        style={styles.textField}
        inputWrapperStyle={styles.textField}
        containerStyle={styles.containerStyle}
        keyboardType={"number-pad"}
      />
      <View style={styles.textFieldContainer}>
      <TextField
        label="Expiry Date"
        style={styles.textField}
        inputWrapperStyle={styles.textField}
        containerStyle={styles.textFieldWrapper}
        keyboardType={"number-pad"}
      />
        <TextField
          label="CVV"
          style={styles.textField}
          inputWrapperStyle={styles.textField}
          containerStyle={styles.textFieldWrapper}
          keyboardType={"number-pad"}
        />
      </View>
        <TextField
          label="Card Holder"
          style={styles.textField}
          inputWrapperStyle={styles.textField}
          containerStyle={styles.containerStyle}
          keyboardType={"default"}
        />

      <View style={styles.buttonWrapper}>
        <Button
          preset="primary"
          text="Save"
          style={styles.saveButton}
          onPressIn={() => navigation.goBack()}
        />
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  buttonWrapper: {
    marginBottom: 20,
  },
  cardsWrapper: {
    flex: 1,
  },
  container: {
    flex: 1,
    margin: spacing.md,
  },
  containerStyle: {
    marginBottom: 10,
  },
  pageWrapper: {
    flex: 1,
    height: "100%",
  },
  saveButton: {
    marginBottom: 16,
    marginTop: 24,
  },
  
  screen: {
    backgroundColor: colors.transparent,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
  },
  textFieldContainer:{
    display: "flex",
    flexDirection: "row",
    gap: 20,
    justifyContent: "space-evenly",
  },
  textFieldWrapper:{
    flex: 1
  }
})

