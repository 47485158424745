/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, TouchableOpacity, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  BaseModal,
  BookingCard,
  Footer,
  Header,
  HeaderForWebView,
  HorizontalTabs,
  Icon,
  Screen,
  Search,
  SectionHeader,
  ServiceCardWeb,
  ServiceProviderCard,
  ServicesCard,
  Text,
} from "app/components"
import {
  UpcomingBookingData,
  bookingServicesWeb,
  bookingTabs,
} from "app/components/serviceUser/DummyData/BookingScreenData"
import { useNavigation } from "@react-navigation/native"
import { colors } from "app/theme"
import { services } from "app/components/serviceUser/DummyData/HomeScreenData"
// import { useStores } from "app/models"
const Next = require("../../../assets/images/theNext.png")

interface BookingsScreenProps extends AppStackScreenProps<"ServiceUserBookings"> {}

export const ServiceUserBookingsScreen: FC<BookingsScreenProps> = observer(
  function BookingsScreen() {
    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()

    const [loadModal, setLoadModal] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState(0)
    const [openIndices, setOpenIndices] = React.useState([])

    // Pull in navigation via hook
    const navigation = useNavigation()
    const onNotificationPress = () => {
      navigation.navigate("AllNotifications")
    }
    console.log(loadModal)

    const toggleAccordion = (index) => {
      if (openIndices.includes(index)) {
        setOpenIndices(openIndices.filter((item) => item !== index))
      } else {
        setOpenIndices([...openIndices, index])
      }
    }

    return (
      <Screen preset="scroll">
        <HeaderForWebView />
        <View style={styles.screenBackground}>
          <View style={styles.contentContainer}>
            <View style={styles.section}>
              <Header
                titleMode="flex"
                titleContainerStyle={{ alignItems: "flex-start" }}
                title="Bookings"
                RightActionComponent={<Search />}
              />
              <HorizontalTabs
                tabs={bookingTabs}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
              />
              <View>
                <SectionHeader leftText="Upcoming Bookings" />
              </View>
              {UpcomingBookingData.filter((data) => data.status === bookingTabs[activeTab]).map(
                (filteredData, index) => (
                  <BookingCard
                    key={index}
                    image={filteredData.image}
                    textTopLeft={filteredData.textTopLeft}
                    textTopRight={filteredData.textTopRight}
                    midTextLeft={filteredData.midTextLeft}
                    midIconRight={filteredData.midIconRight}
                    midTextRight={filteredData.midTextRight}
                    bottomIconLeft={filteredData.bottomIconLeft}
                    bottomTextLeft={filteredData.bottomTextLeft}
                    bottomIconRight={filteredData.bottomIconRight}
                    bottomTextRight={filteredData.bottomTextRight}
                    navigate={() => {
                      setLoadModal(true)
                    }}
                  />
                ),
              )}
            </View>
            <BaseModal
              modalVisible={loadModal}
              modalBody={
                <>
                  <TouchableOpacity>
                    <ServiceProviderCard
                      image={Next}
                      location="25 Montgomery Road, Yaba"
                      views="5123"
                      rating="4.5"
                      name="The Next Barbing Salon"
                      style={styles.serviceProvider}
                    />
                  </TouchableOpacity>
                  <View>
                    <View style={styles.rowContainer}>
                      <View style={[styles.itemContainer, styles.normal]}>
                        <Text
                          text="Completed"
                          size="sm"
                          weight="medium"
                          style={{ color: "#22C55E" }}
                        />
                      </View>

                      <View style={[styles.itemContainer, styles.normal]}>
                        <Text text="+44 8998746" size="sm" weight="medium" />
                      </View>
                    </View>
                    <View style={styles.rowContainer}>
                      <View style={[styles.itemContainer, styles.normal]}>
                        <Icon icon="clock" size={12} />
                        <Text text="27.01.23" size="sm" weight="medium" style={styles.normalText} />
                      </View>

                      <View style={[styles.itemContainer, styles.normal]}>
                        <Icon icon="clock" size={12} />
                        <Text text="4:00PM" size="sm" weight="medium" style={styles.normalText} />
                      </View>
                    </View>
                    <View>
                      <SectionHeader
                        leftText="Services Offered"
                        leftTextStyle={{ fontSize: 20 }}
                        onLeftTextPress={() => navigation.navigate("OfferedServicesCategory")}
                      />
                      <View style={styles.serviceContainer}>
                        {bookingServicesWeb.map((service, index) => {
                          const isShowOpen = openIndices.includes(index)
                          return (
                            <>
                              <TouchableOpacity
                                key={index}
                                style={styles.accordion}
                                onPress={() => toggleAccordion(index)}
                              >
                                <Text>{service.serviceName}</Text>
                                {isShowOpen ? (
                                  <Icon icon="chevronUp" size={24} />
                                ) : (
                                  <Icon icon="caretDown" size={24} />
                                )}
                              </TouchableOpacity>
                              <View
                                style={{
                                  borderBottomColor: "#D1D5DB",
                                  borderBottomWidth: StyleSheet.hairlineWidth,
                                }}
                              />

                              {openIndices.includes(index) && (
                                <ServiceCardWeb serviceDetails={service} />
                              )}
                            </>
                          )
                        })}
                      </View>
                    </View>
                  </View>
                </>
              }
              setModalVisible={() => setLoadModal(false)}
            />
          </View>
        </View>

        <Footer />
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  accordion: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  button: {
    alignItems: "center",
    alignSelf: "center",
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.primary600,
    borderRadius: 27.2,
    height: 48,
    justifyContent: "center",
    marginBottom: 20,
    width: "100%",
  },
  buttonText: {
    color: "#F5F5FF",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
  cancelled: {
    backgroundColor: "#FEF2F2",
  },
  cancelledText: {
    color: "#EF4444",
  },
  confirmed: {
    backgroundColor: "#F0FDF4",
  },
  confirmedText: {
    color: "#22C55E",
  },
  contentContainer: {
    backgroundColor: colors.background,
    borderRadius: 16,
    display: "flex",
    flex: 1,
    marginHorizontal: "auto",
    marginVertical: 53,
    minWidth: 1000,
    padding: 16,
  },
  itemContainer: {
    alignItems: "center",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    gap: 5,
    paddingHorizontal: 8,
    paddingVertical: 2,
  },
  normal: {
    backgroundColor: colors.palette.primary50,
  },
  normalText: {
    color: colors.palette.primary600,
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },
  pressedButtonText: {
    color: colors.palette.primary600,
  },
  refunded: {
    backgroundColor: "#F3F4F6",
  },
  rowContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  screenBackground: {
    backgroundColor: "#f7f7f7",
  },
  search: {
    width: 380,
  },
  section: {
    backgroundColor: colors.background,
    borderRadius: 16,
    padding: 16,
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  serviceContainer: {
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 20,
  },
  serviceProvider: {
    height: 325,
    marginBottom: 40,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
  },
  textFieldContainer: {
    backgroundColor: colors.palette.neutral100,
    borderColor: "#D1D5DB",
    borderRadius: 8,
    borderWidth: 1,
    marginBottom: 10,
  },
  textFieldMessage: {
    color: "#667085",
  },
})
