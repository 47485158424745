/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import React, { FC, useState, useRef, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet, TextInput, useWindowDimensions } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import { Screen, Text, TextField, Button, Footer, ImagePatternWeb } from "app/components"
import { colors } from "app/theme"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "app/models"
// import { useStores } from "app/models"

interface OtpScreenProps extends AppStackScreenProps<"Otp"> {}

export const OtpScreen: FC<OtpScreenProps> = observer(function OtpScreen() {
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const inputRef = useRef<TextInput[]>([])
  const window = useWindowDimensions()
  const [windowsWidth, setWindowsWidth] = useState(0)
  useEffect(() => {
    setWindowsWidth(window.width)
  }, [])
  const {
    authenticationStore: {
      authPhone,
      setAuthPhone,
      setAuthToken,
      validationError,
      userType,
      logout,
      isAuthenticated,
    },
  } = useStores()
  const {
    onboardingStore: { hasCompletedOnboarding, setHasCompletedOnboarding },
  } = useStores()
  // useEffect(()=>{
  //   hasCompletedOnboarding ? navigation.navigate("ServiceProvider") : navigation.navigate("ServiceUser")
  // }, [])

  console.log("UISAI", isAuthenticated)
  const handleChange = (value, index) => {
    console.log(value, "new index " + index)
    console.log(otp)
    let newOtp = [...otp]
    newOtp[index] = value
    setOtp(newOtp)

    if (value.length === 1 && index < inputRef.current.length - 1) {
      inputRef.current[index + 1].focus()
    }
  }

  const handleBackspace = (index) => {
    // console.log("otp index: ", otp[index])
    if (index > 0 && otp[index] === "") {
      console.log("is working")
      inputRef.current[index - 1].focus()
    }
  }
  const goTo = () => {
    console.log("hasCompletedOnboarding", hasCompletedOnboarding)
    console.log("isAuthenticated", isAuthenticated)
    console.log("userType", userType)
    if (hasCompletedOnboarding) {
      userType === "sp"
        ? navigation.navigate("ServiceProvider")
        : navigation.navigate("ServiceUser")
    } else {
      userType === "sp" ? navigation.navigate("MainProfile") : navigation.navigate("AddAddress")
    }
    setHasCompletedOnboarding(true)
  }

  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  const navigation = useNavigation()
  return (
    <Screen preset="scroll">
      <View>
        <View style={styles.pageContainer}>
          <View style={styles.otpContainer}>
            <View style={[styles.InsideLeftContainer, { width: windowsWidth <= 960 ? 320 : 450 }]}>
              <Text
                preset="heading"
                size="lg"
                text="Authentication"
                style={{ textAlign: "center" }}
              />
              <Text
                size="xs"
                text="Enter the 6-digit authentication code sent to "
                style={styles.labelText}
              />
              <Text size="xs" text="+44 909 633 1152" style={styles.phoneNo} />

              <View style={styles.inputWrapper}>
                {otp.map((text, index) => (
                  <TextField
                    key={index + "01"}
                    value={text}
                    onChangeText={(value) => handleChange(value, index)}
                    style={styles.input}
                    maxLength={1}
                    keyboardType="numeric"
                    onKeyPress={({ nativeEvent }) => {
                      if (nativeEvent.key === "Backspace") {
                        handleBackspace(index)
                      }
                    }}
                    ref={(TextField) => {
                      if (TextField) {
                        inputRef.current[index] = TextField
                      }
                    }}
                    containerStyle={styles.inputContainer}
                    inputWrapperStyle={styles.textWrap}
                  />
                ))}
              </View>

              <Button preset="primary" text="Submit" onPress={() => goTo()} />

              <View style={{ flexDirection: "row", justifyContent: "center", gap: 4 }}>
                <Text size="xs" text="Did not recieve an OTP? " style={styles.labelText} />
                <Text size="xs" text="Resend" style={[styles.labelText, styles.resendText]} />
              </View>
            </View>
          </View>
          <View
            style={[styles.PageRightContainer, { display: windowsWidth <= 960 ? "none" : null }]}
          >
            <ImagePatternWeb />
          </View>
        </View>
      </View>
      <Footer style={{ display: windowsWidth <= 960 ? "none" : null }} />
    </Screen>
  )
})

const styles = StyleSheet.create({
  InsideLeftContainer: {
    width: 450,
  },
  PageRightContainer: {
    flex: 1,
  },
  input: {
    flex: 1,
    fontSize: 18,
    textAlign: "center",
  },

  inputContainer: {
    height: 40,
    width: 40,
  },

  inputWrapper: {
    flexDirection: "row",

    gap: 8,
    justifyContent: "center",
    marginBottom: 350,
  },

  labelText: {
    color: "#7A7A7A",
    fontWeight: "400",
    marginBottom: 6,
    textAlign: "center",
  },

  otpContainer: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    justifyContent: "center",
    marginTop: 55,
  },
  pageContainer: {
    display: "flex",
    flexDirection: "row",
  },
  phoneNo: {
    marginBottom: 16,
    textAlign: "center",
  },
  resendText: {
    color: colors.palette.primary600,
  },
  textWrap: {
    height: 40,
    width: 40,
  },
})
