import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import { AutoImage, Close2MiPattern, WebFooter, ProviderSubscription, Screen } from "app/components"
import { screenContentContainer } from "app/styles/mainStyles"
import { useNavigation } from "@react-navigation/native"
import footerLogo from "assets/images/app-icon-all.png"

// import { useStores } from "app/models"

interface AuthSubscriptionScreenProps extends AppStackScreenProps<"AuthSubscription"> {}

export const AuthSubscriptionScreen: FC<AuthSubscriptionScreenProps> = observer(
  function AuthSubscriptionScreen() {
    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()

    // Pull in navigation via hook
    const navigation = useNavigation()

    return (
      <Screen
        preset="scroll"
        contentContainerStyle={screenContentContainer}
        safeAreaEdges={["top", "bottom"]}
      >
        {/* use what Header if you like or the Text if it suits better don't forget to delete the unused import at the end */}
        {/* <Text preset="heading" text="notifications" /> */}
        <View>
          <View style={{ flexDirection: "row", backgroundColor: "#fff" }}>
            <View>
              <AutoImage source={footerLogo} style={styles.logo} />
              <View style={styles.subscriptionWrapper}>
                <ProviderSubscription />
              </View>
            </View>
            <View style={{ flex: 1 }}>
              <Close2MiPattern />
            </View>
          </View>
          <WebFooter />
        </View>
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  logo: {
    width: 68,
    height: 72,
    marginBottom: 32,
    marginLeft: 32,
    marginTop: 16,
  },
  subscriptionWrapper: {
    paddingHorizontal: 130,
  },
})
