/* eslint-disable react-native/sort-styles */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text } from "app/components/Text"
import { Icon } from "app/components/Icon"
import { ProfileContent } from "app/components/serviceUser/ProfileContent"
import { Header } from "app/components/Header"
import { BaseModal } from "app/components/Common/BaseModal"
import {
  popularSearch,
  recentSearchCache,
} from "app/components/serviceUser/DummyData/HomeScreenData"
import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { PopularSearches } from "app/components/serviceUser/PopularSearches"
import { TextField } from "app/components/TextField"
import { Button } from "app/components/Button"
import { colors } from "app/theme"

export interface PersonalDetailsWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const PersonalDetailsWeb = observer(function PersonalDetailsWeb(
  props: PersonalDetailsWebProps,
) {
  const { style } = props
  const $styles = [$container, style]
  const [loadModal, setLoadModal] = React.useState(false)
  const [loadModal2, setLoadModal2] = React.useState(false)

  return (
    <View style={$styles}>
      <Header
        leftText="Personal Details"
        rightIcon="Edit"
        onRightPress={() => {
          setLoadModal(true)
        }}
      />
      <View style={styles.topContainer}>
        <Icon icon="userImage" size={160} />
      </View>
      <View>
        <Text text="Clean Kutz" weight="semiBold" size="lg" />
        <Text text="anitajoseph@gmail.com" weight="light" size="sm" />
      </View>
      <View style={styles.topContainer}>
        <Icon icon="location" size={16} />
        <Text text="24 Montgomery Road, New Yotk" />
      </View>
      <View>
        <ProfileContent leftText="Mobile Number" rightText="09096631152" />
        <ProfileContent leftText="Gender" rightText="Female" />
      </View>{" "}
      <BaseModal
        modalVisible={loadModal}
        modalBody={
          <>
            <Header leftIcon="arrowBack" title="Edit Profile Details" />
            <TextField label="Full Name" />
            <TextField label="Email Address" />
            <TextField label="Phone Number" />
            <TextField label="Gender" />
            <Button style={styles.saveButton} text="Save" textStyle={styles.saveButtonText} />
            <View
              style={{
                borderBottomColor: "#D1D5DB",
                borderBottomWidth: StyleSheet.hairlineWidth,
              }}
            />

            <Text
              text="Delete Account"
              style={{ color: "#EF4444" }}
              onPress={() => {
                setLoadModal(false)
                setLoadModal2(true)
              }}
            />
          </>
        }
        setModalVisible={() => setLoadModal(false)}
      />
      <BaseModal
        modalVisible={loadModal2}
        modalBody={
          <>
            <View style={styles.container}>
              <Text style={styles.title}>Delete Account</Text>
              <Text style={styles.body}>
                This cannot be reversed. Are you sure you want to delete your account?
              </Text>

              <View style={styles.buttonContainer}>
                <Button style={styles.cancelButton} onPress={() => setLoadModal2(false)}>
                  <Text style={styles.cancelButtonText}>Cancel</Text>
                </Button>
                <Button
                  text="Delete"
                  style={styles.acceptButton}
                  textStyle={styles.buttonText}
                  onPress={() => {
                    setLoadModal2(false)
                  }}
                  pressedStyle={styles.pressedButton}
                  pressedTextStyle={styles.pressedButtonText}
                />
              </View>
            </View>
          </>
        }
        setModalVisible={() => setLoadModal2(false)}
      />
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  topContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginBottom: 10,
  },
  container: {
    backgroundColor: colors.palette.primary50,
    padding: 20,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  title: {
    textAlign: "center",
    fontSize: 20,
    fontWeight: "600",
    color: "#1F2937",
    marginBottom: 16,
  },
  body: {
    textAlign: "center",
    fontSize: 14,
    fontWeight: "500",
    color: "#6B7280",
    marginBottom: 24,
  },
  cancelButton: {
    backgroundColor: colors.palette.primary50,
    borderRadius: 24,
    borderColor: "#D0D5DD",
    width: "48%",
  },
  cancelButtonText: { fontSize: 14, fontWeight: "500", color: "#374151" },
  acceptButton: {
    borderRadius: 24,
    backgroundColor: "#EF4444",
    borderColor: "#EF4444",
    width: "48%",
  },
  acceptButtonText: { fontSize: 14, fontWeight: "500", color: colors.palette.primary50 },
  saveButton: {
    borderRadius: 24,
    backgroundColor: "#2895FE",
    borderColor: "#2895FE",
  },
  saveButtonText: { fontSize: 14, fontWeight: "500", color: colors.palette.primary50 },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "space-between",
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },
  pressedButtonText: {
    color: "#EF4444",
  },
  buttonText: {
    color: "#F5F5FF",
    fontSize: 16,
    fontWeight: "600",
    textAlign: "center",
  },
})
