import React, { FC, useState } from "react"
import Checkbox from "expo-checkbox"
import { Header, Screen, Text, Icon, Button } from "app/components"
import { useNavigation } from "@react-navigation/native"
import { useStores } from "app/models"
import { StyleSheet, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"

export interface OfferServicesProps {
  isWeb: boolean
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const OfferServices = observer(function OfferServices(props: OfferServicesProps) {
  const [maleChecked, setMaleChecked] = useState(false)
  const [femaleChecked, setFemaleChecked] = useState(false)
  const [serviceChecked, setServices] = useState(false)
  const [locationChecked, setServiceLocation] = useState(false)
  const [homeSalon, setHomeSalon] = useState(false)
  const [genderChecked, setCheckedGender] = useState(false)

  const {
    onboardingStore: { hasCompletedOnboarding, setHasCompletedOnboarding },
  } = useStores()

  const navigation = useNavigation()

  const nextPage = () => {
    if (props.isWeb) {
      navigation.navigate("AddWebService")
    } else {
      console.log(hasCompletedOnboarding)
      // setHasCompletedOnboarding(true)
      navigation.navigate("ServiceProvider")
    }
  }

  return (
    <View style={{ marginTop: props.isWeb ? 0 : 130 }}>
      <Text
        preset="bold"
        size="sm"
        text="Offer Services to"
        style={{ marginTop: 25, marginBottom: 16 }}
      />
      <View>
        <View style={styles.wideSpacing}>
          <View style={styles.mildSpacing}>
            <Icon
              icon="maleIcon"
              size={25}
              color={maleChecked ? colors.palette.primary600 : colors.palette.neutral500}
            />
            <Text
              weight="medium"
              size="xs"
              text="Male"
              style={{
                color: maleChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
          </View>

          <Checkbox
            value={maleChecked}
            onValueChange={setMaleChecked}
            color={maleChecked ? colors.palette.primary600 : undefined}
          />
        </View>
        <View style={styles.wideSpacing}>
          <View style={styles.mildSpacing}>
            <Icon
              icon="female"
              size={25}
              color={femaleChecked ? colors.palette.primary600 : colors.palette.neutral500}
            />
            <Text
              weight="medium"
              text="Female"
              size="xs"
              style={{
                color: femaleChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
          </View>

          <Checkbox
            value={femaleChecked}
            onValueChange={setFemaleChecked}
            color={femaleChecked ? colors.palette.primary600 : undefined}
          />
        </View>
        <View style={styles.wideSpacing}>
          <View style={styles.mildSpacing}>
            <Icon
              icon="bothSex"
              size={25}
              color={genderChecked ? colors.palette.primary600 : colors.palette.neutral500}
            />
            <Text
              weight="medium"
              size="xs"
              text="Male and Female"
              style={{
                color: genderChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
          </View>

          <Checkbox
            value={genderChecked}
            onValueChange={setCheckedGender}
            color={genderChecked ? colors.palette.primary600 : undefined}
          />
        </View>
        {/* service location sections */}
        <Text preset="bold" size="sm" text="Service Location" style={{ marginBottom: 16 }} />
        <View style={styles.wideSpacing}>
          <View style={styles.mildSpacing}>
            <Icon
              icon="office"
              size={25}
              color={serviceChecked ? colors.palette.primary600 : colors.palette.neutral500}
            />
            <Text
              weight="medium"
              size="xs"
              text="Salon Service"
              style={{
                color: serviceChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
          </View>

          <Checkbox
            value={serviceChecked}
            onValueChange={setServices}
            color={serviceChecked ? colors.palette.primary600 : undefined}
          />
        </View>

        <View style={styles.wideSpacing}>
          <View style={styles.mildSpacing}>
            <Icon
              icon="solarHome"
              size={25}
              color={locationChecked ? colors.palette.primary600 : colors.palette.neutral500}
            />
            <Text
              weight="medium"
              size="xs"
              text="Home Service"
              style={{
                color: locationChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
          </View>

          <Checkbox
            value={locationChecked}
            onValueChange={setServiceLocation}
            color={locationChecked ? colors.palette.primary600 : undefined}
          />
        </View>
        <View style={styles.wideSpacing}>
          <View style={styles.mildSpacing}>
            <Icon
              icon="serviceProvider"
              size={25}
              color={homeSalon ? colors.palette.primary600 : colors.palette.neutral500}
            />
            <Text
              weight="medium"
              text="Home and Salon Service"
              size="xs"
              style={{
                color: homeSalon ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
          </View>

          <Checkbox
            value={homeSalon}
            onValueChange={setHomeSalon}
            color={homeSalon ? colors.palette.primary600 : undefined}
          />
        </View>
      </View>

      <Button
        preset="primary"
        text="Continue"
        style={{ marginTop: props.isWeb ? 64 : 200 }}
        onPress={nextPage}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  mildSpacing: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },

  wideSpacing: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 18,
  },
})
