import React, { useState } from "react"
import { StyleSheet, View } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, Button, TextField, AutoImage } from "app/components"
import footerLogo from "assets/images/footer-logo.png"

export interface WebFooterProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const WebFooter = observer(function WebFooter(props: WebFooterProps) {
  const [email, setEmail] = useState<string>("")
  return (
    <View style={styles.footer}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 32,
        }}
      >
        <View>
          <Text size="lg" text="Join our newsletter" style={styles.newsLetter} />
          <Text
            size="sm"
            text="We’ll send you a nice letter once per week. No spam."
            style={styles.copyRight}
          />
        </View>

        <View style={styles.inputContainer}>
          <TextField
            value={email}
            onChangeText={(text) => setEmail(text)}
            placeholder="Enter your email"
            inputWrapperStyle={{ borderColor: colors.palette.neutral100 }}
          />
          <Button
            text="Subscribe"
            style={{ backgroundColor: colors.palette.neutral100 }}
            textStyle={{ color: colors.palette.primary500 }}
          />
        </View>
      </View>

      <View style={styles.linkWrapperStyle}>
        <View style={styles.linkItemContainer}>
          <Text size="xs" text="Company" style={styles.footerItemText} />
          <Text size="xs" text="About us" style={styles.footerItemText} />
          <Text size="xs" text="Carrers" style={styles.footerItemText} />
          <Text size="xs" text="Press" style={styles.footerItemText} />
          <Text size="xs" text="News" style={styles.footerItemText} />
          <Text size="xs" text="Media Kit" style={styles.footerItemText} />
          <Text size="xs" text="Contact" style={styles.footerItemText} />
        </View>

        <View style={styles.linkItemContainer}>
          <Text size="xs" text="Services" style={styles.footerItemText} />
          <Text size="xs" text="Hairs" style={styles.footerItemText} />
          <Text size="xs" text="Nails" style={styles.footerItemText} />
          <Text size="xs" text="Facial" style={styles.footerItemText} />
          <Text size="xs" text="Spa" style={styles.footerItemText} />
        </View>

        <View style={styles.linkItemContainer}>
          <Text size="xs" text="Social" style={styles.footerItemText} />
          <Text size="xs" text="Twitter" style={styles.footerItemText} />
          <Text size="xs" text="LinkedIn" style={styles.footerItemText} />
          <Text size="xs" text="Facebook" style={styles.footerItemText} />
          <Text size="xs" text="Github" style={styles.footerItemText} />
          <Text size="xs" text="AngelList" style={styles.footerItemText} />
          <Text size="xs" text="Dribbie" style={styles.footerItemText} />
        </View>

        <View style={styles.linkItemContainer}>
          <Text size="xs" text="Contact us" style={styles.footerItemText} />
          <Text size="xs" text="clos2mi@gmail.com" style={styles.footerItemText} />
          <Text size="xs" text="1234567890" style={styles.footerItemText} />
        </View>
      </View>

      <View style={styles.copyRightWrapper}>
        <AutoImage source={footerLogo} alt="footer-logo" style={{ width: 62, height: 72 }} />
        <Text size="sm" text="© 2077 Close2mi. All rights reserved." style={styles.copyRight} />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  footer: {
    paddingTop: 68,
    paddingBottom: 48,
    paddingHorizontal: 82,
    backgroundColor: colors.palette.primary500,
  },
  newsLetter: {
    color: colors.palette.neutral100,
    fontWeight: "500",
  },

  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
  },

  footerItemText: {
    fontWeight: "500",
    color: colors.palette.primary50,
  },

  linkItemContainer: {
    flexDirection: "column",
    justifyContent: "center",
    gap: 16,
  },

  linkWrapperStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 32,
  },

  copyRight: {
    fontWeight: "400",
    color: "#F9FAFB",
  },

  copyRightWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
})
