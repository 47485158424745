import React, { FC, useState } from "react"
import {
  Header,
  Screen,
  TextField,
  Text,
  Button,
  PasswordSetting,
  WebProfile,
  HeaderBar,
  Sidebar,
} from "app/components"
import { View, StyleSheet } from "react-native"

export const ChangedPasswordScreen = () => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Your Profile" />
        <View style={styles.passwordWrapper}>
          <View style={[styles.passwordCover, styles.flexStyle]}>
            <WebProfile />
          </View>
          <View style={{ flex: 1, backgroundColor: "#F9FAFB" }}>
            <View style={styles.passwordCover}>
              <PasswordSetting />
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  passwordCover: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
  },

  flexStyle: {
    flex: 1,
  },

  passwordWrapper: {
    padding: 24,
    flexDirection: "row",
    gap: 24,
    backgroundColor: "#F9FAFB",
  },
})
