import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import { Header, Screen, Text, HeaderBar, WebAddress, WebProfile, Sidebar } from "app/components"
import { screenContentContainer } from "app/styles/mainStyles"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface RegisteredAddressScreenProps extends AppStackScreenProps<"RegisteredAddress"> {}

export const RegisteredAddressScreen: FC<RegisteredAddressScreenProps> = observer(
  function RegisteredAddressScreen() {
    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()

    // Pull in navigation via hook
    const navigation = useNavigation()

    return (
      <Screen
        preset="scroll"
        contentContainerStyle={screenContentContainer}
        safeAreaEdges={["top", "bottom"]}
      >
        {/* use what Header if you like or the Text if it suits better don't forget to delete the unused import at the end */}
        {/* <Text preset="heading" text="notifications" /> */}
        <View style={{ flexDirection: "row" }}>
          <Sidebar />
          <View style={{ flex: 1 }}>
            <HeaderBar title="Your Profile" />
            <View style={styles.addressContainer}>
              <View style={[styles.addressCover, styles.flexStyle]}>
                <WebProfile />
              </View>
              <View style={{ flex: 1, backgroundColor: "#F9FAFB" }}>
                <View style={styles.addressCover}>
                  <WebAddress />
                </View>
              </View>
            </View>
          </View>
        </View>
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  addressCover: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
  },
  flexStyle: {
    flex: 1,
  },

  addressContainer: {
    padding: 24,
    flexDirection: "row",
    backgroundColor: "#F9FAFB",
    gap: 24,
  },
})
