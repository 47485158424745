import React, { useState } from "react"
import { View, StyleSheet } from "react-native"
import { colors } from "app/theme"
import { useNavigation } from "@react-navigation/native"
import { screenContentContainer } from "app/styles/mainStyles"

import {
  Sidebar,
  HeaderBar,
  BarCharts,
  Icon,
  Text,
  SectionText,
  Screen,
  Ratings,
  Booking,
  BookingDataProps,
  Button,
  PreviousWork,
  WebBoxes,
  HomeServices,
} from "app/components"
import { bookingsData, operationTime, homeServices } from "app/DummyData/BookingsData"

export const ServiceProviderHomeScreen = () => {
  const navigation = useNavigation()
  const [activeTab, setActiveTab] = useState<string>("Upcoming")
  const [dataToShow, setDataToShow] = useState<BookingDataProps[]>(bookingsData)

  const homeTab: string[] = ["Upcoming", "Completed", "Cancelled"]

  const tabChanged = (index: number) => {
    setActiveTab(homeTab[index])
    if (homeTab[index] === "All") {
      setDataToShow(bookingsData)
    } else {
      setDataToShow(bookingsData.filter((data) => data.status === homeTab[index]))
    }
  }

  const goTo = (url) => {
    if (url) navigation.navigate(url)
  }
  return (
    <Screen preset="auto" contentContainerStyle={screenContentContainer} safeAreaEdges={["bottom"]}>
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={{ flex: 1 }}>
          <HeaderBar title="Dashboard" />
          <View style={styles.homeCover}>
            <View style={{ flex: 1 }}>
              <View style={styles.chartContainer}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 40,
                    marginBottom: 68,
                  }}
                >
                  <Text preset="bold" size="xs" text="Income" />
                  <View style={styles.bookingIconText}>
                    <Text
                      preset="default"
                      size="xxs"
                      text="Last 7 days"
                      style={{ fontWeight: "400", color: colors.palette.neutral600 }}
                    />

                    <Icon icon="carretdown" />
                  </View>
                </View>
                <BarCharts />
              </View>

              <WebBoxes />

              <View style={styles.availability}>
                <View>
                  <SectionText subheading="Your Availability" text="View All" />

                  {operationTime.map((availableStatus, i) => (
                    <View style={styles.timeContainer} key={i}>
                      <Text size="xs" text={availableStatus.date} style={styles.labelText} />
                      <View style={styles.timeflex}>
                        <View style={styles.timeWrapper}>
                          <Text size="xs" text={availableStatus.fistTime} />
                          <Icon icon="x" size={19} />
                        </View>
                        <View style={styles.timeWrapper}>
                          <Text size="xs" text={availableStatus.secondTime} />
                          <Icon icon="x" size={19} />
                        </View>
                        <View style={styles.timeWrapper}>
                          <Text size="xs" text={availableStatus.thirdTime} />
                          <Icon icon="x" size={19} />
                        </View>
                        <View style={styles.timeWrapper}>
                          <Text size="xs" text={availableStatus.fourthTime} />
                          <Icon icon="x" size={19} />
                        </View>
                      </View>
                    </View>
                  ))}
                </View>
              </View>

              <view style={styles.reviewContainer}>
                <SectionText subheading="Ratings and Reviews" text="View All" />
                <Ratings />
              </view>
            </View>
            {/* right side goes below here */}
            <View>
              <View style={styles.availability}>
                <SectionText
                  subheading="Pending Bookings"
                  text="View All"
                  onPress={() => goTo("PendingBookings")}
                />

                <Booking data={bookingsData.filter((data) => data.status === "Upcoming")} />
              </View>

              <View style={styles.reviewContainer}>
                <SectionText
                  subheading="All Bookings"
                  text="View All"
                  onPress={() => goTo("ServiceProviderBookings")}
                />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 20,
                    borderRadius: 6,
                    backgroundColor: colors.palette.primary50,
                    gap: 8,
                    padding: 4,
                  }}
                >
                  {homeTab.map((item, i) => (
                    <Button
                      key={i}
                      onPress={() => tabChanged(i)}
                      text={item}
                      style={{
                        borderRadius: 6,
                        height: 44,
                        backgroundColor: item === activeTab ? "#fff" : colors.palette.primary50,
                      }}
                    />
                  ))}
                </View>
                <HomeServices data={homeServices} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  chartContainer: {
    height: 327,
    borderRadius: 16,
    padding: 16,
    marginBottom: 16,
    backgroundColor: "#fff",
  },

  labelText: {
    fontWeight: "500",
    color: colors.palette.neutral800,
    marginBottom: 8,
  },
  timeWrapper: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 12,
    paddingVertical: 4,
    borderRadius: 16,
    borderWidth: 0.5,
    borderStyle: "solid",
    borderColor: colors.palette.primary200,
  },

  timeContainer: {
    gap: 10,
    marginBottom: 21,
  },
  timeflex: {
    flexDirection: "row",
    alignItems: "center",
    gap: 32,
  },
  availability: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
    marginVertical: 16,
  },

  reviewContainer: {
    padding: 24,
    borderRadius: 16,
    backgroundColor: "#fff",
  },

  homeCover: {
    flexDirection: "row",
    gap: 24,
    padding: 24,
    backgroundColor: "#F9FAFB",
  },

  bookingIconText: {
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
})
