/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text } from "app/components/Text"
import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { CreditCard } from "app/components/Common/CreditCard"
import { Button } from "app/components/Button"
import { BaseModal } from "app/components/Common/BaseModal"
import { Icon, CheckCircleIcon } from "@gluestack-ui/themed"
import { useNavigation } from "@react-navigation/native"

export interface SavedCartCardProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  setCartCard: (cartCard: string) => void
}

/**
 * Describe your component here
 */
export const SavedCartCard = observer(function SavedCartCard(props: SavedCartCardProps) {
  const { style, setCartCard } = props
  const $styles = [$container, style]

  const [activeCard, setActiveCard] = React.useState(false)
  const [activeCardId, setActiveCardId] = React.useState(0)
  const [showPaymentOK, setShowPaymentOk] = React.useState(false)
  const navigation = useNavigation()

  const setCardActive = () => {
    setActiveCardId(0)
    setActiveCard(true)
  }
  const submit = () => {
    setShowPaymentOk(true)
  }
  const paymentOk = () => {
    setShowPaymentOk(false)
    navigation.navigate("ServiceUser", { screen: "Home" })
  }

  const PaymentModal = () => {
    return (
      <View style={{ alignItems: "center" }}>
        <Icon as={CheckCircleIcon} size="xl" color={colors.tint} style={{ padding: 20 }} />
        <Text
          preset="subheading"
          text="Payment successful"
          size="lg"
          style={{ padding: 20, marginVertical: 10 }}
        />
        <Text text="Your payment has been processed" />
        <Button
          preset="primary"
          text="Okay"
          style={{ paddingHorizontal: 20, marginVertical: 30, width: 200 }}
          onPress={() => paymentOk()}
        />
      </View>
    )
  }

  return (
    <View style={$styles}>
      <SectionHeader leftText="Confirm Payment" />
      <View>
        <View>
          <CreditCard onCardClicked={setCardActive} isActive={activeCard} setCartCard={setCartCard}/>
        </View>
        <View>
          <Button preset="primary" text="Pay Now" onPressIn={() => submit()} />
        </View>
      </View>
      <BaseModal
        modalVisible={showPaymentOK}
        modalBody={<PaymentModal />}
        setModalVisible={() => paymentOk()}
      />
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}
