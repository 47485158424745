import React from "react"
import { View, StyleSheet } from "react-native"
import { Header, Screen, Text, WebProfile, Icon, HeaderBar, Sidebar } from "app/components"
import { colors } from "app/theme"

export const AccountDetailsScreen = () => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Your Profile" />
        <View style={styles.accountCover}>
          <View style={[styles.accountWrapper, styles.flexStyle]}>
            <WebProfile />
          </View>

          <View style={{ flex: 1, backgroundColor: "#F9FAFB" }}>
            <View style={styles.accountWrapper}>
              <View style={styles.bottomGap}>
                <Text
                  preset="bold"
                  size="lg"
                  text="Account details"
                  style={{ textAlign: "center" }}
                />
                <Text size="xs" weight="medium" text="Sort Code" style={styles.firstText} />
                <Text size="sm" weight="normal" text="9079797073" style={styles.secondText} />
              </View>

              <View style={styles.bottomGap}>
                <Text size="xs" weight="medium" text="Account Number" style={styles.firstText} />
                <Text size="sm" weight="normal" text="0237154436" style={styles.secondText} />
              </View>

              <View style={styles.bottomGap}>
                <Text
                  size="xs"
                  weight="medium"
                  text="Account Holder Name"
                  style={styles.firstText}
                />
                <Text size="sm" weight="normal" text="Ayominde Gbenga" style={styles.secondText} />
              </View>

              <View style={styles.bottomGap}>
                <Text
                  size="xs"
                  weight="medium"
                  text="Account Holder Type"
                  style={styles.firstText}
                />
                <Text size="sm" weight="normal" text="Business" style={styles.secondText} />
              </View>
              <View style={styles.addWrapper}>
                <Text size="xs" text="Add  New  Address" style={styles.addAddress} />
                <Icon icon="plus" size={14} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  firstText: {
    color: "#344054",
  },

  secondText: {
    color: "#667085",
  },

  bottomGap: {
    marginBottom: 32,
  },

  addAddress: {
    fontWeight: "500",
    color: colors.palette.primary500,
  },

  addWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
    marginTop: 40,
  },

  accountWrapper: {
    padding: 20,
    backgroundColor: "#fff",
    borderRadius: 16,
  },
  flexStyle: {
    flex: 1,
  },

  accountCover: {
    flexDirection: "row",
    padding: 24,
    gap: 24,
    backgroundColor: "#F9FAFB",
    borderRadius: 16,
  },
})
