/* eslint-disable spaced-comment */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet, TouchableOpacity } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  AddNewAddressWeb,
  Footer,
  HeaderForWebView,
  Icon,
  PersonalDetailsWeb,
  PrivacyPolicyWeb,
  ProfileContent,
  SavedAddressWeb,
  SavedCardsWeb,
  Screen,
  SectionHeader,
  SupportWeb,
  TermsAndConditiondsWeb,
  Text,
} from "app/components"
// import { useNavigation } from "@react-navigation/native"
import { useStores } from "app/models"
import { colors } from "app/theme"

interface ProfileScreenProps extends AppStackScreenProps<"ServiceUserProfile"> {}

export const ServiceUserProfileScreen: FC<ProfileScreenProps> = observer(function ProfileScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  // const navigation = useNavigation()

  const [activeProfile, setActiveProfile] = useState<string>("personalDetails")

  const handleProfileClick = (profileName: string) => {
    setActiveProfile(profileName)
  }

  const {
    onboardingStore: { setHasCompletedOnboarding },
  } = useStores()

  const {
    authenticationStore: { logout },
  } = useStores()
  const doLogout = () => {
    //TODO: uncomment this if you want to test onboarding screens
    // setHasCompletedOnboarding(false)
    logout()
  }
  const renderProfileComponent = () => {
    switch (activeProfile) {
      case "personalDetails":
        return <PersonalDetailsWeb />
      case "savedAddress":
        return <SavedAddressWeb setActiveProfile={setActiveProfile} />
      case "support":
        return <SupportWeb />
      case "addAddress":
        return <AddNewAddressWeb />
      case "privacyPolicy":
        return <PrivacyPolicyWeb />
      case "savedCards":
        return <SavedCardsWeb />
      case "termAndConditions":
        return <TermsAndConditiondsWeb />
      default:
        return null
    }
  }
  return (
    <Screen preset="scroll">
      <HeaderForWebView />
      <View style={styles.screenBackground}>
        <View style={styles.contentContainer}>
          <View style={styles.section}>
            <TouchableOpacity
              style={styles.topContainer}
              onPress={() => handleProfileClick("personalDetails")}
            >
              <Icon icon="userImage" size={48} />
              <View>
                <Text text="Clean Kutz" weight="semiBold" size="lg" />
                <Text text="anitajoseph@gmail.com" weight="light" size="sm" />
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={styles.topContainer}
              onPress={() => handleProfileClick("personalDetails")}
            >
              <Icon icon="location" size={16} />
              <Text text="24 Montgomery Road, New Yotk" />
            </TouchableOpacity>
            <View>
              <SectionHeader leftText="Personal Details" />
              <ProfileContent
                leftText="Mobile Number"
                rightText="09096631152"
                onPress={() => handleProfileClick("personalDetails")}
              />
              <ProfileContent
                leftText="Gender"
                rightText="Female"
                onPress={() => handleProfileClick("personalDetails")}
              />
              <ProfileContent
                leftText="Addresses"
                rightIcon="chevronRight"
                onPress={() => handleProfileClick("savedAddress")}
                activeProfile={activeProfile}
              />
            </View>
            <View>
              <SectionHeader leftText="Payment" />
              <ProfileContent
                leftText="Saved Cards"
                rightIcon="chevronRight"
                onPress={() => handleProfileClick("savedCards")}
              />
            </View>
            <View>
              <SectionHeader leftText="Support" />
              <ProfileContent
                leftText="Close2mi Support"
                rightIcon="chevronRight"
                onPress={() => handleProfileClick("support")}
              />
              <ProfileContent leftText="Enable Notifications" />
            </View>
            <View>
              <SectionHeader leftText="Policy" />
              <ProfileContent
                leftText="Terms and Conditions"
                rightIcon="chevronRight"
                onPress={() => handleProfileClick("termAndConditions")}
              />
              <ProfileContent
                leftText="Privacy Policy"
                rightIcon="chevronRight"
                onPress={() => handleProfileClick("privacyPolicy")}
              />
            </View>
            <View>
              <SectionHeader leftText="Security" />
              <ProfileContent leftText="Change Password" rightIcon="chevronRight" />
              <ProfileContent
                leftText="Sign Out"
                rightIcon="chevronRight"
                onPress={() => {
                  doLogout()
                }}
              />
            </View>
          </View>
          <View style={styles.sectionTwo}>
            <View style={styles.section2}>{renderProfileComponent()} </View>
          </View>
        </View>
      </View>
      <Footer />
    </Screen>
  )
})

const styles = StyleSheet.create({
  contentContainer: {
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    gap: 30,
    marginHorizontal: "auto",
    marginVertical: 30,
    minWidth: 1200,
    padding: 16,
  },
  screenBackground: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexDirection: "row",
    gap: 30,
  },
  section: {
    backgroundColor: colors.background,
    borderRadius: 16,
    flex: 1,
    height: "auto",
    padding: 16,
  },
  section2: {
    backgroundColor: colors.background,
    borderRadius: 16,
    height: "auto",
    padding: 16,
  },
  sectionTwo: {
    flex: 1,
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginBottom: 10,
  },
})
