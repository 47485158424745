import React, { useState } from "react"
import { StyleSheet, View, TouchableOpacity } from "react-native"
import { observer } from "mobx-react-lite"
import { colors } from "app/theme"
import { Text, Button, AutoImage, Icon } from "app/components"
import profilePics from "assets/images/profileImg.png"
import { useNavigation } from "@react-navigation/native"

export interface HeaderBarProps {
  title: string
}

/**
 * Describe your component here
 */
export const HeaderBar = observer(function HeaderBar(props: HeaderBarProps) {
  const navigation = useNavigation()
  const [toggle, setToggle] = useState(false)

  const handlePress = () => {
    navigation.navigate("ServiceProviderProfile")
    setToggle(true)
  }
  return (
    <View style={styles.barContainer}>
      <Text size="xl" text={props.title} />
      <View style={styles.profileContainer}>
        <View style={styles.spacing}>
          <Button
            onPress={() => navigation.navigate("AllNotifications")}
            LeftAccessory={(props) => <Icon icon="bell" size={22} />}
          />
          <Text size="xl" text="Notification" style={styles.profileText} />
        </View>
        <View>
          <TouchableOpacity onPress={handlePress}>
            <View style={styles.spacing}>
              <View
                style={{
                  height: 32,
                  width: 32,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: toggle ? "#2895FE" : "#F7F7F7",
                  borderRadius: 24,
                }}
              >
                <AutoImage source={profilePics} style={styles.profileImg} />
              </View>
              <Text
                size="xl"
                text="John Smith"
                style={{
                  fontWeight: "500",
                  color: toggle ? colors.palette.primary500 : colors.palette.neutral600,
                }}
              />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  )
})
const styles = StyleSheet.create({
  barContainer: {
    paddingVertical: 36,
    paddingRight: 48,
    paddingLeft: 24,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    height: 96,
  },

  spacing: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },

  profileContainer: {
    flexDirection: "row",
    alignItems: "center",
    gap: 29,
  },

  profileText: {
    fontWeight: "500",
    color: colors.palette.neutral600,
  },

  profileImg: {
    width: 32,
    height: 32,
    borderRadius: 24,
  },
})
