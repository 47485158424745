import React, { useState } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import Checkbox from "expo-checkbox"
import { colors, typography } from "app/theme"
import { Text, Icon, Button, TextField } from "app/components"
import { useNavigation } from "@react-navigation/native"

export interface EditServicesProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const EditServices = observer(function EditServices(props: EditServicesProps) {
  const [serviceName, setService] = useState(" ")
  const [serviceDuration, setDuration] = useState(" ")
  const [servicePrice, setPrice] = useState("")
  const [maleChecked, setMaleChecked] = useState(false)
  const [femaleChecked, setFemaleChecked] = useState(false)
  const [bothSex, setBothSex] = useState(false)
  const [home, setHome] = useState(false)
  const [salon, setSalon] = useState(false)
  const [bothLocation, setBothLocation] = useState(false)
  const navigation = useNavigation()
  return (
    <View>
      <View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Button
            onPress={() => navigation.navigate("ServiceProviderServices")}
            LeftAccessory={(props) => <Icon icon="back" size={20} />}
          />
          <View style={{ flex: 1 }}>
            <Text
              preset="subheading"
              size="lg"
              text="Edit Service"
              style={{ textAlign: "center", marginRight: 40 }}
            />
          </View>
        </View>
      </View>

      <Text preset="bold" size="lg" text="Hairs>" style={{ marginBottom: 8 }} />
      <Text
        preset="bold"
        size="xs"
        text="Get a nice haircut with a professional barber"
        style={{ fontWeight: "400", color: colors.palette.neutral500 }}
      />

      <View style={styles.flexBetween}>
        <Text
          size="xs"
          text="Available"
          style={{ fontWeight: "500", color: colors.palette.neutral700 }}
        />

        <Icon icon="toggleButton" />
      </View>

      <View style={styles.inputCover}>
        <Text preset="label" size="xs" text="Service Name" style={styles.textLabel} />
        <TextField
          value={serviceName}
          onChangeText={(text) => setService(text)}
          placeholder="HairCut"
        />
      </View>

      <View style={styles.inputCover}>
        <Text preset="label" size="xs" text="Set Duration" style={styles.textLabel} />
        <TextField
          value={serviceDuration}
          onChangeText={(text) => setDuration(text)}
          placeholder="30 minutes"
        />
      </View>

      <View style={styles.inputCover}>
        <Text preset="label" size="xs" text="Price" style={styles.textLabel} />
        <TextField
          value={servicePrice}
          onChangeText={(text) => setPrice(text)}
          placeholder="$500"
        />
      </View>

      <View>
        <Text preset="label" size="xs" text="Offer Service for" style={styles.textLabel} />
        <View style={styles.flexBetween}>
          <View style={styles.flexRow}>
            <Text
              weight="medium"
              size="xs"
              text="Male"
              style={{
                color: maleChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />
            <Checkbox
              value={maleChecked}
              onValueChange={setMaleChecked}
              color={maleChecked ? colors.palette.primary600 : undefined}
            />
          </View>

          <View style={styles.flexRow}>
            <Text
              weight="medium"
              text="Female"
              size="xs"
              style={{
                color: femaleChecked ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />

            <Checkbox
              value={femaleChecked}
              onValueChange={setFemaleChecked}
              color={femaleChecked ? colors.palette.primary600 : undefined}
            />
          </View>

          <View style={styles.flexRow}>
            <Text
              weight="medium"
              text="Both"
              size="xs"
              style={{
                color: bothSex ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />

            <Checkbox
              value={bothSex}
              onValueChange={setBothSex}
              color={bothSex ? colors.palette.primary600 : undefined}
            />
          </View>
        </View>
      </View>

      <View>
        <Text preset="label" size="xs" text="Service Location" style={styles.textLabel} />

        <View style={styles.flexBetween}>
          <View style={styles.flexRow}>
            <Text
              weight="medium"
              text="Home"
              size="xs"
              style={{
                color: home ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />

            <Checkbox
              value={home}
              onValueChange={setHome}
              color={home ? colors.palette.primary600 : undefined}
            />
          </View>

          <View style={styles.flexRow}>
            <Text
              weight="medium"
              text="Salon"
              size="xs"
              style={{
                color: salon ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />

            <Checkbox
              value={salon}
              onValueChange={setSalon}
              color={salon ? colors.palette.primary600 : undefined}
            />
          </View>

          <View style={styles.flexRow}>
            <Text
              weight="medium"
              text="Both"
              size="xs"
              style={{
                color: bothLocation ? colors.palette.primary600 : colors.palette.neutral500,
              }}
            />

            <Checkbox
              value={bothLocation}
              onValueChange={setBothLocation}
              color={bothLocation ? colors.palette.primary600 : undefined}
            />
          </View>
        </View>
      </View>

      <View style={styles.buttonContainer}>
        <Button preset="default" text="Cancel" style={{ flex: 1 }} />
        <Button preset="primary" text="Save" style={{ flex: 1 }} />
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },

  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 24,
  },

  textLabel: {
    fontWeight: "500",
    color: "#344054",
    marginBottom: 6,
  },

  inputCover: {
    marginBottom: 24,
  },

  buttonContainer: {
    flexDirection: "row",
    gap: 40,
    marginTop: 40,
    paddingBottom: 40,
  },
})
