/* eslint-disable react-native/sort-styles */
/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  AutoImage,
  BaseModal,
  Button,
  CustomCheckBox,
  Footer,
  HeaderForWebView,
  HeaderWithBackButton,
  Icon,
  PopularSearches,
  Screen,
  SectionHeader,
  ServicesCard,
  Text,
  TextField,
} from "app/components"
import { colors, typography } from "app/theme"
import { addressType } from "app/components/serviceUser/DummyData/AddAddressConstants"
import { useNavigation } from "@react-navigation/native"
import { works } from "app/components/serviceUser/DummyData/BookingScreenData"
// import { useStores } from "app/models"

interface ServicesScreenProps extends AppStackScreenProps<"Services"> {}

export const ServicesScreen: FC<ServicesScreenProps> = observer(function ServicesScreen(props) {
  const { params } = props.route.params || {} // Perform null-checking

  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  // Pull in navigation via hook

  const [loadModal, setLoadModal] = React.useState(false)

  const navigation = useNavigation()
  return (
    <Screen preset="scroll">
      <HeaderForWebView />
      <View style={styles.screenBackground}>
        <View style={styles.contentContainer}>
          <View style={styles.section}>
            <HeaderWithBackButton title="Services" />
            <View style={styles.serviceStyle}>
              <Text
                style={styles.textStyle}
                text={params?.serviceName}
                size="lg"
                weight="semiBold"
              />
              <Icon icon={params?.icon} size={56} />
            </View>
            <View>
              <ServicesCard
                setLoadModal={setLoadModal}
                serviceDetails={params}
                style={styles.servicesCard}
              />
            </View>
          </View>
        </View>
        <BaseModal
          modalVisible={loadModal}
          modalBody={
            <>
              {/* <HeaderWithBackButton title="Book Service" /> */}
              <SectionHeader
                leftText="Previous Work"
                rightText="View All"
                onLeftTextPress={() => navigation.goBack()}
              />
              <View style={styles.worksContainer}>
                {works.map((work, index) => (
                  <AutoImage key={index} source={work} />
                ))}
              </View>

              <View style={styles.containerStyle}>
                <Text text={"Hair Cut"} size="md" weight="medium" />
                <View style={styles.container}>
                  <Icon icon="hourglass" size={12} />
                  <Text text="30 minutes" size="sm" weight="medium" style={styles.textColor} />
                </View>
              </View>

              <TextField
                label="Number of Persons"
                style={styles.textField}
                inputWrapperStyle={styles.textField}
                containerStyle={styles.textContainer}
                keyboardType="number-pad"
              />
              <TextField
                label="Select Appointment Date"
                style={styles.textField}
                inputWrapperStyle={styles.textField}
                containerStyle={styles.textContainer}
                keyboardType="numbers-and-punctuation"
                status="disabled"
                RightAccessory={() => (
                  <Icon
                    icon="date"
                    size={24}
                    style={{
                      alignContent: "center",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: 5,
                    }}
                  />
                )}
              />

              <SectionHeader leftText="Available Slots" />
              <View style={styles.selectWrapper}>
                {addressType.map((type, index) => (
                  <View key={type.id} style={styles.selectWrapper}>
                    <PopularSearches
                      text={index + 1 + ":00PM"}
                      key={"male"}
                      textFontWeight="semiBold"
                    />
                  </View>
                ))}
              </View>

              <SectionHeader leftText="Service Location" />
              <View style={styles.selectWrapper}>
                {addressType.map((type) => (
                  <View key={type.id} style={styles.selectWrapper}>
                    <Text text={type.type} />
                    <CustomCheckBox />
                  </View>
                ))}
              </View>

              <View style={styles.priceContainer}>
                <Text text="Total Price" />
                <Text text="£500" />
              </View>

              <View style={styles.buttonContainer}>
                <Button
                  style={styles.cancelButton}
                  onPress={() => navigation.navigate("ServiceUserHome")}
                >
                  <Text style={styles.cancelButtonText} text="Add to Cart" />
                </Button>
                <Button
                  text={"Book Appointment"}
                  style={styles.acceptButton}
                  textStyle={styles.buttonText}
                  pressedStyle={styles.pressedButton}
                  pressedTextStyle={styles.pressedButtonText}
                  onPress={() => navigation.navigate("ServiceUserCart")}
                />
              </View>
            </>
          }
          setModalVisible={() => setLoadModal(false)}
        />
      </View>
      <Footer />
    </Screen>
  )
})

const styles = StyleSheet.create({
  acceptButton: {
    borderRadius: 24,
    backgroundColor: colors.palette.primary600,
    borderColor: colors.palette.primary600,
    width: "48%",
  },
  buttonContainer: {
    flexDirection: "row",
    gap: 8,
    justifyContent: "space-between",
    paddingVertical: 15,
  },
  buttonText: {
    fontSize: 14,
    fontWeight: "600",
    color: "#F5F5FF",
    textAlign: "center",
  },
  cancelButton: {
    backgroundColor: colors.palette.primary50,
    borderColor: "#D0D5DD",
    borderRadius: 24,
    paddingHorizontal: 26,
    width: "48%",
  },
  cancelButtonText: { fontSize: 14, fontWeight: "500", color: "#374151" },
  container: {
    alignItems: "center",
    backgroundColor: colors.palette.primary50,
    borderRadius: 30,
    display: "flex",
    flexDirection: "row",
    gap: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  containerStyle: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 10,
  },
  contentContainer: {
    backgroundColor: colors.background,
    borderRadius: 16,
    display: "flex",
    flex: 1,
    marginHorizontal: "auto",
    marginVertical: 76,
    minWidth: 790,
    padding: 16,
  },
  pressedButton: {
    backgroundColor: "#F5F5FF",
  },
  pressedButtonText: {
    color: colors.palette.primary600,
  },
  priceContainer: {
    backgroundColor: "#F9F9F9",
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    gap: 8,
    padding: 8,
  },
  screenBackground: {
    backgroundColor: "#f7f7f7",
  },
  section: {
    backgroundColor: colors.background,
    borderRadius: 16,
    display: "flex",
    gap: 32,
    padding: 16,
  },
  selectWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 8,
    justifyContent: "space-between",
    marginBottom: 5,
  },
  serviceStyle: {
    alignItems: "center",
    backgroundColor: colors.palette.primary600,
    borderRadius: 14,
    display: "flex",
    flexDirection: "row",
    gap: 10,
    height: 80,
    justifyContent: "space-between",
    marginBottom: 15,
    paddingHorizontal: 12,
    paddingVertical: 12,
  },
  servicesCard: {
    marginTop: 32,
  },
  textColor: {
    color: colors.palette.primary600,
  },
  textContainer: {
    marginBottom: 5,
  },
  textField: {
    alignContent: "center",
    alignItems: "center",
    backgroundColor: colors.palette.neutral100,
  },
  textStyle: {
    color: colors.palette.primary50,
    fontFamily: typography.primary.normal,
  },
  worksContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 5,
  },
})
