import React from "react"
import { View, StyleSheet } from "react-native"
import footerLogo from "assets/images/app-icon-all.png"
import { AutoImage, Close2MiPattern, WebFooter, AccountDetail } from "app/components"

export const BankAccountDetailsScreen = () => {
  return (
    <View>
      <View style={{ flexDirection: "row", backgroundColor: "#fff" }}>
        <View style={{ flex: 1 }}>
          <View style={{ marginTop: 16, marginLeft: 32 }}>
            <AutoImage source={footerLogo} style={styles.logo} />
          </View>
          <View style={styles.accountWrapper}>
            <AccountDetail />
          </View>
        </View>
        <Close2MiPattern />
      </View>
      <WebFooter />
    </View>
  )
}

const styles = StyleSheet.create({
  logo: {
    width: 68,
    height: 72,
    marginBottom: 32,
  },

  accountWrapper: {
    paddingHorizontal: 130,
  },
})
