import React from "react"
import { View, StyleSheet } from "react-native"
import footerLogo from "assets/images/app-icon-all.png"
import { AutoImage, Close2MiPattern, WebFooter, OfferServices } from "app/components"

export const OfferServicesScreen = () => {
  return (
    <View>
      <View style={{ flexDirection: "row", backgroundColor: "#fff" }}>
        <View style={{ flex: 1 }}>
          <AutoImage source={footerLogo} style={styles.logo} />
          <View style={{ paddingHorizontal: 130 }}>
            <OfferServices isWeb />
          </View>
        </View>
        <Close2MiPattern />
      </View>
      <WebFooter />
    </View>
  )
}

const styles = StyleSheet.create({
  logo: {
    width: 68,
    height: 72,
    marginBottom: 32,
    marinTop: 16,
    marginLeft: 32,
  },
})
