import * as React from "react"
import { StyleSheet, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, AutoImage } from "app/components"
const Anita = "https://dev-c2m.close2mi.com/images/home/female.png"

export interface EarningBookingDataProps {
  userImg?: string
  name?: string
  price?: string
  bookingId?: string
  date?: string
  time?: string
  phone?: string
}

export interface TransactionWebProps {
  /**
   * An optional style override useful for padding & margin.
   *
   */

  data: EarningBookingDataProps[]
}

/**
 * Describe your component here
 */
export const TransactionWeb = observer(function TransactionWeb(props: TransactionWebProps) {
  const { data } = props
  return (
    <>
      {data.map((earning, i) => (
        <View style={styles.transactionContainer} key={i}>
          <View style={[styles.flexBetween, styles.verticalGap]}>
            <View style={styles.flexRow}>
              <Text
                size="xs"
                text="Booking ID :"
                style={{ fontWeight: "500", color: colors.palette.neutral500 }}
              />
              <Text size="xs" text={earning.bookingId} style={[styles.idText, {marginRight: 25}]} />
            </View>
            <View style={styles.flexRow}>
              <Text size="xs" text={earning.date} style={styles.idText} />
              <Text size="xs" text={earning.time} style={styles.idText} />
            </View>
          </View>

          <View style={styles.flexBetween}>
            <View style={styles.flexRow}>
              <AutoImage source={{ uri: Anita }} style={{width: 32, height: 32, borderRadius: 24}} />
              <Text size="xs" text={earning.name} style={styles.idText} />
            </View>

            <Text size="xs" text="$5" style={styles.idText} />
          </View>
        </View>
      ))}
    </>
  )
})

const styles = StyleSheet.create({
  transactionContainer: {
    paddingHorizontal: 8,
    paddingVertical: 16,
    borderRadius: 16,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: colors.palette.primary100,
    marginBottom: 16,
  },
  idText: {
    color: colors.text,
    fontWeight: "500",
  },

  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },

  flexBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  verticalGap: {
    marginBottom: 16,
  },
})
