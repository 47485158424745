/* eslint-disable react/jsx-no-undef */
/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { Image, Pressable, StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { spacing } from "app/theme"
import { HeaderWithBackButton } from "app/components/Common/HeaderWithBackButton"
import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { works } from "app/components/serviceUser/DummyData/BookingScreenData"
import { BaseModal } from "app/components/Common/BaseModal"

export interface PortfolioProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  setServiceComponent?: (serviceComponent: string) => void
}

/**
 * Describe your component here
 */
export const Portfolio = observer(function Portfolio(props: PortfolioProps) {
  const { style, setServiceComponent } = props
  const $styles = [$container, style]
  const [loadModal, setLoadModal] = React.useState(false)
  const [activeImg, setActiveImg] = React.useState()
  // Pull in navigation via hook
  const loadImg = (img) => {
    setActiveImg(img)
    setLoadModal(true)
  }
  return (
    <View style={$styles}>
      <HeaderWithBackButton title="Previous Work" />
      <View>
        <SectionHeader leftText="Beards" />
        <View style={styles.worksContainer}>
          {works.map((work, index) => (
            // eslint-disable-next-line react/jsx-key
            <Pressable onPress={() => loadImg(work)}>
              <Image key={index} source={work} style={{ width: 170, height: 170 }} />
            </Pressable>
          ))}
        </View>
      </View>
      <View style={{ marginVertical: spacing.md }}>
        <SectionHeader leftText="Haircut" />
        <View style={styles.worksContainer}>
          {works.map((work, index) => (
            // eslint-disable-next-line react/jsx-key
            <Pressable onPress={() => loadImg(work)}>
              <Image key={index} source={work} style={{ width: 170, height: 170 }} />
            </Pressable>
          ))}
        </View>
      </View>
      <View>
        <SectionHeader leftText="Makeup" />
        <View style={styles.worksContainer}>
          {works.map((work, index) => (
            // eslint-disable-next-line react/jsx-key
            <Pressable onPress={() => loadImg(work)}>
              <Image key={index} source={work} style={{ width: 170, height: 170 }}/>
            </Pressable>
          ))}
        </View>
      </View>
      <BaseModal
        modalVisible={loadModal}
        modalBody={<Image source={activeImg} style={styles.img} />}
        setModalVisible={() => setLoadModal(false)}
      />
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  img: {
    borderRadius: 10,
    height: 300,
    padding: 10,
    width: "100%",
  },
  worksContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
  },
})