import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { ViewStyle, View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  Header,
  Screen,
  Text,
  AutoImage,
  TextField,
  ImageUploader,
  Button,
  Close2MiPattern,
  WebFooter,
} from "app/components"
import { screenContentContainer } from "app/styles/mainStyles"
import { useNavigation } from "@react-navigation/native"
import footerLogo from "assets/images/app-icon-all.png"
import { useStores } from "app/models"

interface AddWebServiceScreenProps extends AppStackScreenProps<"AddWebService"> {}

export const AddWebServiceScreen: FC<AddWebServiceScreenProps> = observer(
  function AddWebServiceScreen() {
    const [service, setService] = useState(" ")
    const [duration, setDuration] = useState(" ")
    const [price, setPrice] = useState(" ")
    const [description, setDescription] = useState(" ")
    // Pull in one of our MST stores
    // const { someStore, anotherStore } = useStores()
    const {
      onboardingStore: { hasCompletedOnboarding, setHasCompletedOnboarding },
    } = useStores()

    // Pull in navigation via hook
    const navigation = useNavigation()
    const goNext = (): void => {
      setHasCompletedOnboarding(true)
    }

    return (
      <Screen
        preset="scroll"
        contentContainerStyle={screenContentContainer}
        safeAreaEdges={["top", "bottom"]}
      >
        {/* use what Header if you like or the Text if it suits better don't forget to delete the unused import at the end */}
        {/* <Text preset="heading" text="notifications" /> */}
        <View style={{ flexDirection: "row", backgroundColor: "#fff" }}>
          <View style={{ flex: 1 }}>
            <AutoImage source={footerLogo} style={styles.logo} />
            <View style={{ paddingHorizontal: 96 }}>
              <Header title="Add Service" leftIcon="back" onLeftPress={() => navigation.goBack()} />
              <View style={styles.horizontalSpacing}>
                <Text preset="label" size="xs" text="Service Name" style={styles.textStyle} />
                <Text
                  preset="default"
                  size="xs"
                  text="You can add multiple services later"
                  style={styles.sublabel}
                />
                <TextField
                  value={service}
                  onChangeText={(text) => setService(text)}
                  placeholder="Haircut"
                />
              </View>

              <View style={styles.horizontalSpacing}>
                <Text preset="label" size="xs" text="Service Category" style={styles.textStyle} />
                <TextField value={""} onChangeText={() => {}} />
              </View>

              <View style={styles.horizontalSpacing}>
                <Text preset="label" size="xs" text="Set Duration" style={styles.textStyle} />
                <TextField value={duration} onChangeText={(text) => setDuration(text)} />
              </View>

              <View style={styles.horizontalSpacing}>
                <ImageUploader />
              </View>

              <View style={styles.horizontalSpacing}>
                <Text preset="label" size="xs" text="Price" style={styles.textStyle} />
                <TextField value={price} onChangeText={(text) => setPrice(text)} />
              </View>
              <View style={styles.horizontalSpacing}>
                <Text preset="label" size="xs" text="Description" style={styles.textStyle} />
                <TextField
                  value={description}
                  onChangeText={(text) => setDescription(text)}
                  containerStyle={{ width: 178 }}
                  placeholder="Type here...."
                />
                <Text size="xs" text="40 characters left" style={styles.sublabel} />
              </View>
              <View style={styles.buttonWrapper}>
                <Button preset="default" text="Cancel" style={{ flex: 1 }} />
                <Button preset="primary" text="Save" onPress={goNext} style={{ flex: 1 }} />
              </View>
            </View>
          </View>
          <View style={{ flex: 1 }}>
            <Close2MiPattern />
          </View>
        </View>

        <WebFooter />
      </Screen>
    )
  },
)

const styles = StyleSheet.create({
  textStyle: {
    fontWeight: "500",
    color: "#344054",
    marginBottom: 6,
  },

  sublabel: {
    fontWeight: "400",
    color: "#667085",
    marginBottom: 6,
  },

  horizontalSpacing: {
    marginBottom: 32,
  },

  buttonWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
    paddingBottom: 82,
  },

  logo: {
    width: 68,
    height: 72,
    marginBottom: 32,
    marginTop: 16,
    marginLeft: 32,
  },
})
