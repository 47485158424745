import React from "react"
import { View, StyleSheet } from "react-native"
import footerLogo from "assets/images/app-icon-all.png"
import { Signup, Close2MiPattern, WebFooter, Text, AutoImage } from "app/components"

export const SignupScreen = () => {
  return (
    <View>
      <View style={styles.signupContainer}>
        <View style={styles.signupWrapper}>
          <AutoImage source={footerLogo} style={styles.logo} />
          <View style={{ paddingHorizontal: 130 }}>
            <Signup />
          </View>
        </View>

        <Close2MiPattern />
      </View>
      <WebFooter />
    </View>
  )
}

const styles = StyleSheet.create({
  signupContainer: {
    flexDirection: "row",
    backgroundColor: "#fff",
  },

  logo: {
    width: 68,
    height: 72,
    marginBottom: 12,
    objectFixed: "contain",
  },

  signupWrapper: {
    flex: 1,
    marginLeft: 32,
    marginTop: 16,
  },
})
