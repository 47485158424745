import React from "react"
import { View, StyleSheet } from "react-native"
import {
  Screen,
  Text,
  BackArrowHeader,
  Ratings,
  Icon,
  SectionText,
  WebProfile,
  HeaderBar,
  Sidebar,
} from "app/components"
import { colors } from "app/theme"

export const ReviewsScreen = () => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Sidebar />
      <View style={{ flex: 1 }}>
        <HeaderBar title="Your Profile" />
        <View style={styles.ratingContainer}>
          <View style={styles.ratingCover}>
            <WebProfile />
          </View>
          <View style={styles.ratingCover}>
            <SectionText subheading="Ratings and Reviews" text="View All" />
            <View style={styles.ratingBanner}>
              <View style={styles.starWrapper}>
                {Array.from({ length: 5 }).map((_, index) => (
                  <Icon icon={index == 4 ? "starOutline" : "starFill"} size={16} />
                ))}
              </View>

              <Text
                preset="bold"
                text="Based on 1560 reviews"
                style={{ color: colors.palette.primary50 }}
              />
            </View>
            <Ratings />
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  ratingBanner: {
    height: 130,
    width: 437,
    borderRadius: 8,
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colors.palette.primary500,
    marginVertical: 21,
  },

  starWrapper: {
    flexDirection: "row",
    alignItems: "center",
    gap: 16,
    marginBottom: 16,
  },
  ratingCover: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
    flex: 1,
  },
  ratingContainer: {
    padding: 24,
    backgroundColor: "#F7F7F7",
    flexDirection: "row",
    gap: 24,
  },
})
