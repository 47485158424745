import {
  BookingDataProps,
  PendingBookingDataProps,
  EarningBookingDataProps,
  HomeServiceProps,
} from "app/components"
import { Image, ImageRequireSource } from "react-native"
const DummyImg = require("../../assets/images/accept-mark.png")
const beard = require("../../assets/images/trim-beard0.png")

// const Anita = Image.resolveAssetSource(DummyImg).uri;
const Anita = "https://dev-c2m.close2mi.com/images/home/female.png"

export const bookingsData: BookingDataProps[] = [
  {
    userImg: Anita,
    name: "Anita Juba",
    price: "$30",
    service: "Nails",
    date: "01.27.23",
    time: "4.00 Pm",
    timeLine: "BiClock",
    status: "Completed",
  },
  {
    userImg: Anita,
    name: "Benson Kubna",
    price: "$17",
    service: "Hair Cut",
    date: "01.27.23",
    time: "12.00 Pm",
    timeLine: "BiClock",
    status: "Upcoming",
  },
  {
    userImg: Anita,
    name: "Bisham Mnajiku",
    price: "$50",
    service: "Pedicure",
    date: "01.27.23",
    time: "4.00 Pm",
    timeLine: "BiClock",
    status: "Cancelled",
  },
  {
    userImg: Anita,
    name: "Shalam Bercetov",
    price: "$500",
    service: "Spa Treatment",
    date: "11.04.23",
    time: "11.00 Am",
    timeLine: "BiClock",
    status: "Upcoming",
  },
]

export const profileData = [
  {
    name: " Profile Detail",
    icon: "caretRight",
    url: "ServiceProviderProfile",
  },

  {
    name: "Owner",
    icon: "caretRight",
    url: "Owners",
  },

  {
    name: "Registered Address",
    icon: "caretRight",
    url: "RegisteredAddress",
  },
  {
    name: "Account Details",
    icon: "caretRight",
    url: "AccountDetails",
  },
  {
    name: "Subscription",
    icon: "caretRight",
    url: "ProviderSubscription",
  },

  {
    name: "Previous Work",
    icon: "caretRight",
    url: "PreviousWorks",
  },
]

export const operationTime = [
  {
    date: "Thur 17th July",
    fistTime: "10:00AM",
    secondTime: "12:00AM",
    thirdTime: "10:00AM",
    fourthTime: "2:00PM",
  },

  {
    date: "Fri 18th July",
    fistTime: "10:00AM",
    secondTime: "12:00AM",
    thirdTime: "10:00AM",
    fourthTime: "2:00PM",
  },

  {
    date: "Sat 19th July",
    fistTime: "10:00AM",
    secondTime: "12:00AM",
    thirdTime: "10:00AM",
    fourthTime: "2:00PM",
  },
]

export const WebbookingsData: BookingDataProps[] = [
  {
    userImg: Anita,
    name: "Anita Juba",
    price: "$30",
    service: "Nails",
    date: "01.27.23",
    time: "4.00 Pm",
    timeLine: "BiClock",
    status: "Completed",
    phone: "+44 8998746",
  },
  {
    userImg: Anita,
    name: "Benson Kubna",
    price: "$17",
    service: "Hair Cut",
    date: "01.27.23",
    time: "12.00 Pm",
    timeLine: "BiClock",
    status: "Upcoming",
    phone: "+44 8998746",
  },
  {
    userImg: Anita,
    name: "Bisham Mnajiku",
    price: "$50",
    service: "Pedicure",
    date: "01.27.23",
    time: "4.00 Pm",
    timeLine: "BiClock",
    status: "Cancelled",
    phone: "+44 8998746",
  },
  {
    userImg: Anita,
    name: "Shalam Bercetov",
    price: "$500",
    service: "Spa Treatment",
    date: "11.04.23",
    time: "11.00 Am",
    timeLine: "BiClock",
    status: "Upcoming",
    phone: "+44 8998746",
  },

  {
    userImg: Anita,
    name: "Shalam Bercetov",
    price: "$500",
    service: "Spa Treatment",
    date: "11.04.23",
    time: "11.00 Am",
    timeLine: "BiClock",
    status: "Cancelled",
    phone: "+44 8998746",
  },
]

export const pendingBookingData: PendingBookingDataProps[] = [
  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "500",
    service: "Hair Cut",
    date: "01.27.23",
    time: "12.00 Pm",

    location: "25 Montgomery Road, Yaba",
  },

  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    service: "Hair Cut",
    date: "01.27.23",
    time: "12.00 Pm",
    location: "25 Montgomery Road, Yaba",
  },

  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    service: "Hair Cut",
    date: "01.27.23",
    time: "12.00 Pm",
    location: "25 Montgomery Road, Yaba",
  },

  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    service: "Hair Cut",
    date: "01.27.23",
    time: "12.00 Pm",
    location: "25 Montgomery Road, Yaba",
  },
]

export const earningData = [
  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    bookingId: "3907932123478",
    date: "01.27.23",
    time: "12.00 Pm",
  },

  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    bookingId: "3907932123478",
    date: "01.27.23",
    time: "12.00 Pm",
  },

  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    bookingId: "3907932123478",
    date: "01.27.23",
    time: "12.00 Pm",
  },
  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    bookingId: "3907932123478",
    date: "01.27.23",
    time: "12.00 Pm",
  },
  {
    userImg: Anita,
    name: "Anita Joseph",
    price: "$500",
    bookingId: "3907932123478",
    date: "01.27.23",
    time: "12.00 Pm",
  },
]

export const homeServices: HomeServiceProps[] = [
  {
    services: "Haircut",
    shop: "Dami’s Beauty Shop",
    minute: "30 minutes",
    hour: "4:00 PM",
    date: "27.01.23",
    status: "Completed",
    serviceImg: beard,
  },

  {
    services: "Haircut",
    shop: "Dami’s Beauty Shop",
    minute: "30 minutes",
    hour: "4:00 PM",
    date: "27.01.23",
    status: "Completed",
    serviceImg: beard,
  },

  {
    services: "Haircut",
    shop: "Dami’s Beauty Shop",
    minute: "30 minutes",
    hour: "4:00 PM",
    date: "27.01.23",
    status: "Completed",
    serviceImg: beard,
  },

  {
    services: "Haircut",
    shop: "Dami’s Beauty Shop",
    minute: "30 minutes",
    hour: "4:00 PM",
    date: "27.01.23",
    status: "Completed",
    serviceImg: beard,
  },

  {
    services: "Haircut",
    shop: "Dami’s Beauty Shop",
    minute: "30 minutes",
    hour: "4:00 PM",
    date: "27.01.23",
    status: "Completed",
    serviceImg: beard,
  },
]
