import React, { useState } from "react"
import { View, StyleSheet } from "react-native"
import { screenContentContainer } from "app/styles/mainStyles"
import { pendingBookingData } from "app/DummyData/BookingsData"
import { PendingBookingsWeb, Sorting, HeaderBar, Sidebar, Screen } from "app/components"

export const PendingBookingsScreen = () => {
  return (
    <Screen preset="auto" contentContainerStyle={screenContentContainer} safeAreaEdges={["bottom"]}>
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={{ flex: 1 }}>
          <HeaderBar title="Pending Bookings" />
          <View style={styles.pendingBookCover}>
            <View style={styles.contentWrapper}>
              <Sorting />

              <PendingBookingsWeb data={pendingBookingData} />

              {/* <View style={{ marginBottom: 16 }}>
                <PendingBookingsWeb />
              </View>
              <View style={{ marginBottom: 16 }}>
                <PendingBookingsWeb />
              </View>
              <View style={{ marginBottom: 16 }}>
                <PendingBookingsWeb />
              </View> */}
            </View>
          </View>
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  contentWrapper: {
    padding: 24,
    backgroundColor: "#fff",
    borderRadius: 16,
    // marginTop: 8,
    // marginLeft: 8,
    // marginRight: 32,
  },

  pendingBookCover: {
    padding: 24,
    backgroundColor: "#F7F7F7",
  },
})
