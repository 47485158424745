/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-unused-styles */
/* eslint-disable react-native/no-color-literals */
import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { StyleSheet, View } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  AddCartCard,
  CartCard,
  Footer,
  HeaderForWebView,
  SavedCartCard,
  Screen,
  SectionHeader,
  Text,
} from "app/components"
import { colors, spacing } from "app/theme"

// import { useStores } from "app/models"

interface CartScreenProps extends AppStackScreenProps<"ServiceUserCart"> {}

export const ServiceUserCartScreen: FC<CartScreenProps> = observer(function CartScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()
  // const { params } = props.route.params || {}; // Perform null-checking

  // Pull in navigation via hook
  // console.log("CART", params)

  const [cartCard, setCartCard] = useState("savedCards")

  const renderCartCard = () => {
    switch (cartCard) {
      case "savedCards":
        return <SavedCartCard setCartCard={setCartCard} />
      case "addNewCard":
        return <AddCartCard setCartCard={setCartCard} />
      default:
        return null
    }
  }
  return (
    <Screen preset="auto">
      <HeaderForWebView />
      <View style={styles.screenBackground}>
        <View style={styles.contentContainer}>
          <View style={styles.section}>
            <SectionHeader leftText="Cart" />
            <View style={styles.containerStyle}>
              <CartCard />
            </View>
            <View style={styles.totalContainer}>
              <View>
                <Text text="Total Price" weight="semiBold" />
                <Text text="(5 Persons)" size="xs" />
              </View>
              <Text text="£500" weight="bold" />
            </View>
          </View>
          <View style={styles.section}>{renderCartCard()}</View>
        </View>
      </View>
      <Footer />
    </Screen>
  )
})

const styles = StyleSheet.create({
  buttonContainer: {
    margin: spacing.md,
    paddingBottom: 40,
  },
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  containerStyle: {
    borderColor: colors.palette.neutral200,
    borderWidth: 2,
    marginVertical: 20,
    padding: 5,
  },
  contentContainer: {
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    gap: 30,
    marginHorizontal: "auto",
    marginVertical: 53,
    minWidth: 1100,
    padding: 16,
  },
  pageContainer: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexDirection: "row",
    gap: 30,
  },
  pricingContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: 15,
  },
  screenBackground: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexDirection: "row",
    gap: 30,
  },
  section: {
    backgroundColor: colors.background,
    borderRadius: 16,
    flex: 1,
    height: "auto",
    padding: 16,
  },
  textColor: {
    color: colors.palette.primary600,
  },

  totalContainer: {
    alignItems: "center",
    backgroundColor: "#F9F9F9",
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    gap: 8,
    justifyContent: "space-between",
    marginVertical: 15,
    padding: 20,
  },
})
