/* eslint-disable react-native/no-color-literals */
import React from "react";
import { TouchableOpacity, Text, StyleSheet } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";

export interface NavLinkProps {
  to: string;
  children: React.ReactNode;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children }) => {
  const navigation = useNavigation();
  const route = useRoute();
  const isNavLinkActive = route.name === to;

  const handlePress = () => {
    if (!isNavLinkActive) {
      navigation.navigate(to);
    }
  };

  return (
    <TouchableOpacity onPress={handlePress} style={styles.navLink}>
      <Text style={[styles.navLink ,isNavLinkActive ? styles.activeNavLink : {}]}>{children}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  activeNavLink: {
    color: "#2895FE",
    fontWeight: "bold",
  },
  navLink: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
});

export default NavLink;
