const nailPaint = require("../../../../assets/images/nailsPaint.png")
const Haircut = require("../../../../assets/images/theNext.png")
const next = require("../../../../assets/images/theNext.png")
const damiApparel = require("../../../../assets/images/damiApparel.png")
const beautyMakeover = require("../../../../assets/images/beautyMakeover.png")

export const bookingTabs = ["Upcoming", "Previous", "Cancelled"]
export const addCardTab = ["Credit Card", "Debit Card"]
 
export const UpcomingBookingData = [
  {
    image:  Haircut,
    textTopLeft: "Haircut",
    textTopRight: "On Hold",
    midTextLeft: "The Next Salon",
    midIconRight: "hourglassAlt",
    midTextRight: "30 minutes",
    bottomIconLeft: "clock",
    bottomTextLeft: "4:00 PM",
    bottomIconRight: "calendarBlue",
    bottomTextRight: "27.01.23",
    status: "Upcoming"
  },
  {
    image: nailPaint,
    textTopLeft: "Nails> Nail Paint",
    textTopRight: "Completed",
    midTextLeft: "Dami’s Beauty Shop",
    midIconRight: "hourglassAlt",
    midTextRight: "30 minutes",
    bottomIconLeft: "clock",
    bottomTextLeft: "4:00 PM",
    bottomIconRight: "calendarBlue",
    bottomTextRight: "27.01.23",
    status: "Previous"
  },
  {
    image: nailPaint,
    textTopLeft: "Pedicure",
    textTopRight: "Cancelled",
    midTextLeft: "Dami’s Beauty Shop",
    midIconRight: "hourglassAlt",
    midTextRight: "30 minutes",
    bottomIconLeft: "clock",
    bottomTextLeft: "4:00 PM",
    bottomIconRight: "calendarBlue",
    bottomTextRight: "27.01.23",
    status: "Cancelled"
  },
  ]

  export const works = [
    next, damiApparel, beautyMakeover,
  ]

  export const bookingServicesWeb = [
    { serviceName: "Hair", icon: "hair", serviceOfferings: [{ categoryName: "Hair", icon: "hair", offerName: "Hair", price: 17.2, duration: 30, noOfPeople: 2 }, { categoryName: "Hair", icon: "hair", offerName: "Beard Trim", price: 5, duration: 15 , noOfPeople: 2},] },
    { serviceName: "Nails", icon: "highFive", serviceOfferings: [{ categoryName: "Nails", icon: "highFive", offerName: "Polish", price: 17.2, duration: 30, noOfPeople: 2}, { categoryName: "Nails", icon: "highFive", offerName: "Pedicure", price: 5, duration: 15 , noOfPeople: 2}, ] },
    { serviceName: "Beard", icon: "beard", serviceOfferings: [{ categoryName: "Beard", icon: "beard", offerName: "beard", price: 17.2, duration: 30, noOfPeople: 2 }, { categoryName: "Beard", icon: "beard", offerName: "Beard Trim", price: 5, duration: 15 , noOfPeople: 2}, ] },
   ]
  