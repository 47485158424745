import React, { useState, useMemo } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import {
  Header,
  Screen,
  TextField,
  Text,
  Button,
  Icon,
  TextFieldAccessoryProps,
} from "app/components"
import { useNavigation } from "@react-navigation/native"

export interface PasswordSettingProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const PasswordSetting = observer(function PasswordSetting(props: PasswordSettingProps) {
  const navigation = useNavigation()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [isAuthPasswordHidden, setIsAuthPasswordHidden] = useState(true)

  const PasswordRightAccessory = useMemo(
    () =>
      function PasswordRightAccessory(props: TextFieldAccessoryProps) {
        return (
          <Icon
            icon={isAuthPasswordHidden ? "view" : "hidden"}
            color={colors.palette.neutral800}
            containerStyle={props.style}
            size={20}
            onPress={() => setIsAuthPasswordHidden(!isAuthPasswordHidden)}
          />
        )
      },
    [isAuthPasswordHidden],
  )

  return (
    <View>
      <Header title="change Password" leftIcon="back" onLeftPress={() => navigation.goBack()} />

      <Text
        preset="default"
        size="xs"
        text="Your new password must be different from the previous password"
        style={styles.passwordText}
      />

      <View style={{ marginBottom: 12 }}>
        <TextField
          value={password}
          onChangeText={(value) => setPassword(value)}
          label="Enter New Password"
          style={{ marginBottom: 12 }}
          RightAccessory={PasswordRightAccessory}
        />
      </View>

      <View style={{ marginBottom: 12 }}>
        <TextField
          value={confirmPassword}
          onChangeText={(value) => setConfirmPassword(value)}
          label="Confirm New Password"
          RightAccessory={PasswordRightAccessory}
        />
      </View>

      <Text size="xxs" text="Both passwords must match" style={styles.textTip} />

      <Button preset="primary" text="Update" />
    </View>
  )
})

const styles = StyleSheet.create({
  passwordText: {
    textAlign: "center",
    fontWeight: "400",
    color: "#596780",
    marginTop: 16,
    marginBottom: 32,
  },
  textTip: {
    color: "#596780",
    fontWeight: "400",
    marginBottom: 32,
  },
})
