import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"

import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { HorizontalTabs } from "app/components/serviceUser/HorizontalTabs"
import { TextField } from "app/components/TextField"
import { Button } from "app/components/Button"
import { useNavigation } from "@react-navigation/native"
import { colors, spacing } from "app/theme"
import { addCardTab } from "app/components/serviceUser/DummyData/BookingScreenData"

export interface AddCartCardProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  setCartCard? : (cartCard: string) => void
}

/**
 * Describe your component here
 */
export const AddCartCard = observer(function AddCartCard(props: AddCartCardProps) {
  const { style, setCartCard } = props
  const $styles = [$container, style]
  const navigation = useNavigation()
  const [activeTab, setActiveTab] = React.useState(0)


  return (
    <View style={$styles}>
      <SectionHeader leftText="Add Card" />
      <HorizontalTabs tabs={addCardTab} setActiveTab={setActiveTab} activeTab={activeTab} />
      <TextField
        label="Card Number"
        style={styles.textField}
        inputWrapperStyle={styles.textField}
        containerStyle={styles.containerStyle}
        keyboardType={"number-pad"}
      />
      <View style={styles.textFieldContainer}>
        <TextField
          label="Expiry Date"
          style={styles.textField}
          inputWrapperStyle={styles.textField}
          containerStyle={styles.textFieldWrapper}
          keyboardType={"number-pad"}
        />
        <TextField
          label="CVV"
          style={styles.textField}
          inputWrapperStyle={styles.textField}
          containerStyle={styles.textFieldWrapper}
          keyboardType={"number-pad"}
        />
      </View>
      <TextField
        label="Card Holder"
        style={styles.textField}
        inputWrapperStyle={styles.textField}
        containerStyle={styles.containerStyle}
        keyboardType={"default"}
      />
      <View style={styles.buttonWrapper}>
        <Button
          preset="primary"
          text="Save"
          style={styles.saveButton}
          onPress={()=>{setCartCard("savedCards")}}
        />
      </View>{" "}
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  buttonWrapper: {
    marginBottom: 20,
  },
  cardsWrapper: {
    flex: 1,
  },
  container: {
    flex: 1,
    margin: spacing.md,
  },
  containerStyle: {
    marginBottom: 10,
  },
  pageWrapper: {
    flex: 1,
    height: "100%",
  },
  saveButton: {
    marginBottom: 16,
    marginTop: 24,
  },
  screen: {
    backgroundColor: colors.transparent,
  },
  textField: {
    backgroundColor: colors.palette.neutral100,
  },
  textFieldContainer:{
    display: "flex",
    flexDirection: "row",
    gap: 20,
    justifyContent: "space-evenly",
  },
  textFieldWrapper:{
    flex: 1
  }
})