/* eslint-disable react-native/no-color-literals */
/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { Linking, StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors } from "app/theme"
import { Text } from "app/components/Text"
import { HeaderWithBackButton } from "app/components/Common/HeaderWithBackButton"
import { ServiceProviderCard } from "app/components/serviceUser/ServiceProviderCard"
import { Icon } from "app/components/Icon"
import { HorizontalTabs } from "app/components/serviceUser/HorizontalTabs"
import { SectionHeader } from "app/components/serviceUser/SectionHeader"
import { inspiredTabs, services } from "app/components/serviceUser/DummyData/HomeScreenData"
import { ServicesCard } from "app/components/serviceUser/ServicesCard"
import { useNavigation } from "@react-navigation/native"

const image = require("../../../../assets/images/theNext.png")

export interface ServiceProviderProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  setServiceComponent?: (serviceComponent: string) => void
}

/**
 * Describe your component here
 */
export const ServiceProvider = observer(function ServiceProvider(props: ServiceProviderProps) {
  const { style, setServiceComponent } = props
  const $styles = [$container, style]

  const [openIndices, setOpenIndices] = React.useState([])
  const [activeTab, setActiveTab] = React.useState(0)
  const makePhoneCall = (phoneNumber) => {
    Linking.openURL(`tel:${phoneNumber}`)
  }
  const navigation = useNavigation()

  const toggleAccordion = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((item) => item !== index))
    } else {
      setOpenIndices([...openIndices, index])
    }
  }
 

  return (
    <View style={$styles}>
      <HeaderWithBackButton title="Provider Details" />
      <ServiceProviderCard
        image={image}
        location="25 Montgomery Road, Yaba"
        views="5123"
        rating="4.5"
        name="The Next Barbing Salon"
        style={styles.serviceProviderCard}
      />
      <View style={styles.headContainer}>
        <Text text="The Next Barbing Salon" size="lg" weight="semiBold" />
        <Text text="View Portfolio" weight="bold" size="md" style={styles.text} onPress={()=>setServiceComponent("Portfolio")} />
        <View
          style={{
            width: 40,
            height: 40,
            backgroundColor: colors.palette.neutral200,
            borderRadius: 100,
            alignContent: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            icon="callPhone"
            size={20}
            style={{
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
            onPress={() => makePhoneCall("0712345678")}
          />
        </View>
      </View>
      <Text
        text="The best saloon to get a professional haircut with the best prices and facilities in Camden"
        style={styles.providerDescription}
      />
      <HorizontalTabs tabs={inspiredTabs} setActiveTab={setActiveTab} activeTab={activeTab} />
      <View>
        <SectionHeader
          leftText="Services Offered"
          leftTextStyle={{ fontSize: 20 }}
          onLeftTextPress={() => navigation.navigate("OfferedServicesCategory")}
        />
        <View style={styles.serviceContainer}>
          {services.map((service, index) => {
            const isShowOpen = openIndices.includes(index)
            return (
              <>
                <TouchableOpacity
                  key={index}
                  style={styles.accordion}
                  onPress={() => toggleAccordion(index)}
                >
                  <Text>{service.serviceName}</Text>
                  {isShowOpen ? (
                    <Icon icon="chevronUp" size={24} />
                  ) : (
                    <Icon icon="caretDown" size={24} />
                  )}
                </TouchableOpacity>
                <View
                  style={{
                    borderBottomColor: "#D1D5DB",
                    borderBottomWidth: StyleSheet.hairlineWidth,
                  }}
                />

                {openIndices.includes(index) && <ServicesCard serviceDetails={service} />}
              </>
            )
          })}
        </View>
      </View>
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  accordion: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
  },
  button: {
    marginTop: 20,
  },
  contentContainer: {
    borderRadius: 16,
    display: "flex",
    flexDirection: "row",
    gap: 30,
    marginHorizontal: "auto",
    marginVertical: 30,
    maxWidth: 1200,
    padding: 16,
  },
  headContainer: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 30,
  },
  image: {
    marginBottom: 16,
  },

  popularSearchContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
  },
  previousWorkContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 10,
    justifyContent: "space-between",
  },
  providerDescription: {
    marginVertical: 10,
  },
  screenBackground: {
    backgroundColor: "#f7f7f7",
    display: "flex",
    flexDirection: "row",
    gap: 30,
  },
  section: {
    backgroundColor: colors.background,
    borderRadius: 16,
    flex: 1,
    height: "auto",
    padding: 16,
  },
  serviceContainer: {
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 20,
  },
  serviceProviderCard: {
    height: 350,
  },
  servicesLabelText: { color: "#2895FE", fontSize: 16 },
  text: {
    color: "#2895FE",
  },
})
