/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
  NavigatorScreenParams, // @demo remove-current-line
} from "@react-navigation/native"
import { createNativeStackNavigator, NativeStackScreenProps } from "@react-navigation/native-stack"
import { observer } from "mobx-react-lite"
import React from "react"
import { useColorScheme, Platform } from "react-native"
import * as Screens from "app/screens"
import Config from "../config"
import { useStores } from "../models" // @demo remove-current-line
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { colors } from "app/theme"
import { ServiceProviderNavigator, ServiceProviderTabParamList } from "./ServiceProviderNavigator"
import { ServiceUserNavigator, ServiceUserTabParamList } from "./ServiceUserNavigator"

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type ServiceDetailsParamList = {
  data: {
    serviceName: string
    icon: string
    serviceOfferings: [
      {
        categoryName: string
        icon: string
        offerName: string
        price: number
        duration: number
      },
    ]
  }
}
export type ServiceDetailParamList = {
  data: {
    categoryName: string
    icon: string
    offerName: string
    price: number
    duration: number
  }
}
export type OnboardingParamList = {
  data: {
    nextPage?: string
  }
}
export type AppStackParamList = {
  ServiceProvider: NavigatorScreenParams<ServiceProviderTabParamList>
  ServiceUser: NavigatorScreenParams<ServiceUserTabParamList>
  UserSelection: undefined
  // 🔥 Your screens go here
  "splash-one": undefined
  SplashTwo: undefined
  SplashThree: undefined
  LoginSignin: undefined
  "select-user-type": undefined
  Signup: undefined
  Otp: undefined
  Signin: undefined
  ResetPassword: undefined
  AllNotifications: undefined

  // ServiceUser Screens
  PrivacyPolicy: undefined
  ServiceUserBookings: undefined
  ServiceUserCart: undefined
  ServiceUserHome: undefined
  ServiceUserProfile: undefined
  Notifications: NavigatorScreenParams<OnboardingParamList>
  Search: undefined
  SearchResult: undefined
  InspiredByClose2Mi: undefined
  PopularInYourArea: undefined
  EditDetails: undefined
  SavedAddress: undefined
  AddNewAddress: undefined
  ViewBookings: undefined
  ServiceProviderDetails: undefined
  Services: NavigatorScreenParams<ServiceDetailsParamList>
  BookService: NavigatorScreenParams<ServiceDetailsParamList>
  SavedCards: undefined
  TermAndConditions: undefined
  Report: undefined
  Support: undefined

  // ServiceProvider Screens
  ServiceProviderBookings: undefined
  ServiceProviderProfile: undefined
  ServiceProviderHome: undefined
  ServiceProviderServices: undefined

  ServiceSettings: undefined
  ProviderSubscription: undefined

  ViewServices: undefined
  BookView: undefined
  ServiceDetail: undefined
  Availability: undefined
  TermsAndConditions: undefined
  TermsAndCondition: undefined
  Reviews: undefined
  Earnings: undefined
  TransactionDetails: undefined
  Reports: undefined
  AvailabilityCalendar: undefined
  ProviderSupport: undefined
  ChangedPassword: undefined
  ChangePassword: undefined
  AddAddress: NavigatorScreenParams<OnboardingParamList>
  ProviderNotification: undefined
  PendingBookings: undefined
  AccountDetails: undefined
  BankAccountDetails: undefined
  PreviousWorks: undefined
  PreviousWork: undefined
  OfferServices: undefined
  MainDetail: undefined
  MainProfile: undefined
  OfferedServicesCategory: undefined
  ConfirmPayment: undefined
  AddNewCard: undefined
  Success: undefined
  ActionStatus: undefined
  SubmitNotification: undefined
  ViewPendingBookings: undefined
  ViewCurrentBookings: undefined
  // PendingBookings: undefined
  SwitchUserType: undefined
  ForgotPassword: undefined
  MapSearch: undefined
  OnboardingOtp: NavigatorScreenParams<OnboardingParamList>
  EnableLocation: NavigatorScreenParams<OnboardingParamList>
  EnableNotifications: NavigatorScreenParams<OnboardingParamList>
  OnboardingAddAddress: NavigatorScreenParams<OnboardingParamList>
  OnboardingSetupProfile: NavigatorScreenParams<OnboardingParamList>
  AddWebService: undefined
  EditService: undefined
  Owners: undefined
  RegisteredAddress: undefined
  Close2MiSupport: undefined
  AuthSubscription: undefined
  ProviderPolicy: undefined
  // IGNITE_GENERATOR_ANCHOR_APP_STACK_PARAM_LIST
}

// const authenticationStore = AuthenticationStoreModel.create({
//   authToken: "", // Initial values
//   authPhone: "",
//   userType: "",
// })
// const walkthroughStore = WalkthroughStoreModel.create({ seenWalkthrough: undefined })

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof AppStackParamList> = NativeStackScreenProps<
  AppStackParamList,
  T
>

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<AppStackParamList>()

const AppStack = observer(function AppStack() {
  // @demo remove-block-start
  const {
    authenticationStore: { isAuthenticated, userType },
  } = useStores()
  const {
    walkthroughStore: { hasSeenWalkthrough },
  } = useStores()

  const {
    onboardingStore: { hasCompletedOnboarding },
  } = useStores()

  
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, navigationBarColor: colors.background }}
      initialRouteName={hasSeenWalkthrough ? "LoginSignin" : "splash-one"} 
    >
      {isAuthenticated ? (
        <>
          {userType === "su" ? (
            <>
              {hasCompletedOnboarding === true ? (
                <>
                  {/* ServiceUser Screens  */}
                  {/* <Stack.Screen name="Report" component={Screens.ReportScreen} /> */}
                  <Stack.Screen name="ServiceUser" component={ServiceUserNavigator} />
                  <Stack.Screen name="ServiceUserHome" component={Screens.ServiceUserHomeScreen} />
                  <Stack.Screen
                    name="ServiceUserBookings"
                    component={Screens.ServiceUserBookingsScreen}
                  />
                  <Stack.Screen name="ServiceUserCart" component={Screens.ServiceUserCartScreen} />
                  <Stack.Screen
                    name="ServiceUserProfile"
                    component={Screens.ServiceUserProfileScreen}
                  />
                  <Stack.Screen name="Search" component={Screens.SearchScreen} />
                  <Stack.Screen name="SearchResult" component={Screens.SearchResultScreen} />
                  <Stack.Screen
                    name="InspiredByClose2Mi"
                    component={Screens.InspiredByClose2MiScreen}
                  />
                  <Stack.Screen
                    name="PopularInYourArea"
                    component={Screens.PopularInYourAreaScreen}
                  />
                  <Stack.Screen name="EditDetails" component={Screens.EditDetailsScreen} />
                  <Stack.Screen name="ViewBookings" component={Screens.ViewBookingsScreen} />
                  <Stack.Screen
                    name="ServiceProviderDetails"
                    component={Screens.ServiceProviderDetailsScreen}
                  />
                  <Stack.Screen name="Services" component={Screens.ServicesScreen} />
                  <Stack.Screen name="BookService" component={Screens.BookServiceScreen} />
                  <Stack.Screen name="SavedCards" component={Screens.SavedCardsScreen} />
                  <Stack.Screen name="Report" component={Screens.ReportScreen} />
                  <Stack.Screen name="MapSearch" component={Screens.MapSearchScreen} />
                  <Stack.Screen name="PreviousWork" component={Screens.PreviousWorkScreen} />
                </>
              ) : (
                <>
                  <Stack.Screen name="OnboardingOtp" component={Screens.OtpScreen} />
                  <Stack.Screen name="EnableLocation" component={Screens.EnableLocationScreen} />
                  <Stack.Screen name="OnboardingAddAddress" component={Screens.AddAddressScreen} />
                  <Stack.Screen
                    name="EnableNotifications"
                    component={Screens.NotificationsScreen}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {hasCompletedOnboarding ? (
                <>
                  {/* Service Provider Screens */}
                  {Platform.OS === "web" ? null : (
                    <Stack.Screen name="ServiceProvider" component={ServiceProviderNavigator} />
                  )}

                  <Stack.Screen name="ServiceProvider" component={ServiceProviderNavigator} />

                  <Stack.Screen
                    name="ServiceProviderHome"
                    component={Screens.ServiceProviderHomeScreen}
                  />

                  <Stack.Screen name="AccountDetails" component={Screens.AccountDetailsScreen} />

                  <Stack.Screen
                    name="ServiceProviderBookings"
                    component={Screens.ServiceProviderBookingsScreen}
                  />
                  <Stack.Screen
                    name="ViewPendingBookings"
                    component={Screens.PendingBookingsScreen}
                  />
                  {/* <Stack.Screen name="PendingBookings" component={Screens.PendingBookingsScreen} /> */}
                  <Stack.Screen
                    name="ServiceProviderServices"
                    component={Screens.ServiceProviderServicesScreen}
                  />
                  <Stack.Screen
                    name="ServiceProviderProfile"
                    component={Screens.ServiceProviderProfileScreen}
                  />

                  {/* <Stack.Screen name="ServiceSettings" component={Screens.ServiceSettingsScreen} /> */}
                  <Stack.Screen name="Earnings" component={Screens.EarningsScreen} />
                  <Stack.Screen
                    name="AvailabilityCalendar"
                    component={Screens.AvailabilityCalendarScreen}
                  />

                  <Stack.Screen
                    name="ProviderSubscription"
                    component={Screens.ProviderSubscriptionScreen}
                  />
                  <Stack.Screen name="EditService" component={Screens.EditServiceScreen} />
                  <Stack.Screen name="ViewServices" component={Screens.ViewServicesScreen} />
                  <Stack.Screen name="Reviews" component={Screens.ReviewsScreen} />
                  <Stack.Screen name="PreviousWorks" component={Screens.PreviousWorksScreen} />
                  <Stack.Screen name="BookView" component={Screens.BookViewScreen} />
                  <Stack.Screen name="ServiceDetail" component={Screens.ServiceDetailScreen} />
                  <Stack.Screen name="Availability" component={Screens.AvailabilityScreen} />
                  <Stack.Screen name="Reports" component={Screens.ReportsScreen} />
                  <Stack.Screen name="ProviderSupport" component={Screens.ProviderSupportScreen} />
                  <Stack.Screen name="Owners" component={Screens.OwnersScreen} />
                  <Stack.Screen name="ProviderPolicy" component={Screens.ProviderPolicyScreen} />
                  <Stack.Screen
                    name="RegisteredAddress"
                    component={Screens.RegisteredAddressScreen}
                  />
                  <Stack.Screen name="Close2MiSupport" component={Screens.Close2MiSupportScreen} />
                  <Stack.Screen name="ChangedPassword" component={Screens.ChangedPasswordScreen} />

                  <Stack.Screen name="PendingBookings" component={Screens.PendingBookingsScreen} />

                  {/* <Stack.Screen
                    name="BankAccountDetails"
                    component={Screens.BankAccountDetailsScreen}
                  /> */}

                  {/* <Stack.Screen name="MainProfile" component={Screens.MainProfileScreen} /> */}
                </>
              ) : (
                <>
                  <Stack.Screen name="OnboardingOtp" component={Screens.OtpScreen} />
                  <Stack.Screen name="MainProfile" component={Screens.MainProfileScreen} />
                  <Stack.Screen name="MainDetail" component={Screens.MainDetailScreen} />
                  <Stack.Screen
                    name="SubmitNotification"
                    component={Screens.SubmitNotificationScreen}
                  />
                  <Stack.Screen name="OnboardingAddAddress" component={Screens.AddAddressScreen} />
                  <Stack.Screen name="EnableLocation" component={Screens.EnableLocationScreen} />
                  {/* <Stack.Screen name="AccountDetails" component={Screens.AccountDetailsScreen} /> */}
                  <Stack.Screen
                    name="BankAccountDetails"
                    component={Screens.BankAccountDetailsScreen}
                  />
                  {/* <Stack.Screen
                    name="ProviderSubscription"
                    component={Screens.ProviderSubscriptionScreen}
                  /> */}

                  <Stack.Screen
                    name="AuthSubscription"
                    component={Screens.AuthSubscriptionScreen}
                  />
                  <Stack.Screen
                    name="EnableNotifications"
                    component={Screens.NotificationsScreen}
                  />
                  <Stack.Screen name="AddWebService" component={Screens.AddWebServiceScreen} />
                  <Stack.Screen name="OfferServices" component={Screens.OfferServicesScreen} />
                  {/* <Stack.Screen name="OnboardingOtp" component={Screens.OtpScreen} /> */}
                </>
              )}
            </>
          )}

          <Stack.Screen name="SavedAddress" component={Screens.SavedAddressScreen} />
          <Stack.Screen name="PrivacyPolicy" component={Screens.PrivacyPolicyScreen} />
          <Stack.Screen name="TermAndConditions" component={Screens.TermAndConditionsScreen} />
          <Stack.Screen name="TermsAndCondition" component={Screens.TermsAndConditionScreen} />
          <Stack.Screen name="Support" component={Screens.SupportScreen} />
          <Stack.Screen name="Notifications" component={Screens.NotificationsScreen} />
          <Stack.Screen name="AddNewAddress" component={Screens.AddNewAddressScreen} />
          <Stack.Screen name="AddAddress" component={Screens.AddAddressScreen} />

          <Stack.Screen name="Otp" component={Screens.OtpScreen} />
          {/* <Stack.Screen name="OnboardingOtp" component={Screens.OtpScreen} /> */}
        </>
      ) : (
        <>
          {hasSeenWalkthrough ? (
            <>
              <Stack.Screen name="LoginSignin" component={Screens.LoginSigninScreen} />
              <Stack.Screen name="Signup" component={Screens.SignupScreen} />
              <Stack.Screen name="Signin" component={Screens.SigninScreen} />
              <Stack.Screen name="ResetPassword" component={Screens.ResetPasswordScreen} />
              <Stack.Screen name="SwitchUserType" component={Screens.SwitchUserTypeScreen} />
            </>
          ) : (
            <>
              <Stack.Screen name="splash-one" component={Screens.SplashOneScreen} />
              <Stack.Screen name="SplashTwo" component={Screens.SplashTwoScreen} />
              <Stack.Screen name="SplashThree" component={Screens.SplashThreeScreen} />
              <Stack.Screen name="select-user-type" component={Screens.SelectUserTypeScreen} />
            </>
          )}
        </>
      )}

      <Stack.Screen name="AllNotifications" component={Screens.AllNotificationsScreen} />
      <Stack.Screen
        name="OfferedServicesCategory"
        component={Screens.OfferedServicesCategoryScreen}
      />
      <Stack.Screen
        name="ConfirmPayment"
        component={Screens.ConfirmPaymentScreen}
        //TODO: find a way to show modal over modal options={{ presentation: 'modal' }}
      />
      <Stack.Screen
        name="ActionStatus"
        component={Screens.ActionStatusScreen}
        options={{
          presentation: "modal",
        }}
      />
      <Stack.Screen name="AddNewCard" component={Screens.AddNewCardScreen} />
      <Stack.Screen name="ForgotPassword" component={Screens.ResetPasswordScreen} />
      {/* <Stack.Screen name="OnboardingOtp" component={Screens.OtpScreen} /> */}

      {/* IGNITE_GENERATOR_ANCHOR_APP_STACK_SCREENS */}
    </Stack.Navigator>
  )
})

export interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  const colorScheme = useColorScheme()

  useBackButtonHandler((routeName) => exitRoutes.includes(routeName))

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      <AppStack />
    </NavigationContainer>
  )
})
