import * as React from "react"
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, Icon, AutoImage } from "app/components"
const Anita = "https://dev-c2m.close2mi.com/images/home/female.png"

export interface PendingBookingsWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  data: PendingBookingDataProps[]
}

export interface PendingBookingDataProps {
  userImg?: string
  name?: string
  price?: string
  service?: string
  date?: string
  time?: string
  location?: string
  phone?: string
}

/**
 * Describe your component here
 */
export const PendingBookingsWeb = observer(function PendingBookingsWeb(
  props: PendingBookingsWebProps,
) {
  const { style, data } = props

  return (
    <>
      {data.map((pending) => (
        <View style={styles.pendingBookWrapper}>
          <View style={styles.flexSpacing}>
            <View>
              <AutoImage
                source={{ uri: pending.userImg }}
                style={{ width: 64, height: 64, borderRadius: 50 }}
              />
            </View>
            <View>
              <Text
                size="xs"
                text={pending.name}
                style={{ fontWeight: "500", color: colors.text }}
              />
              <View style={styles.flexSpacing}>
                <Icon icon="pin" size={14} />
                <Text size="xxs" text={pending.location} />
              </View>
            </View>
          </View>

          <View style={styles.timeline}>
            <Text preset="bold" size="xs" text={pending.price} />
            <View style={styles.paidBg}>
              <Text size="xxs" text="Paid" style={{ fontWeight: "500", color: "#22C55E" }} />
            </View>

            <View style={styles.textWrapper}>
              <Text size="xs" text={pending.service} style={styles.infoText} />
            </View>

            <View style={[styles.textWrapper, styles.flexRow]}>
              <Icon icon="Calendar" />
              <Text size="xs" text={pending.date} style={styles.infoText} />
            </View>

            <View style={[styles.textWrapper, styles.flexRow]}>
              <Icon icon="BiClock" />
              <Text size="xs" text={pending.date} style={styles.infoText} />
            </View>
          </View>

          <View style={styles.flexRow}>
            <View style={[styles.acceptBg, styles.declineBg]}>
              <Icon icon="x" size={16} />
              <Text
                preset="default"
                size="xxs"
                text="Decline"
                style={{ fontWeight: "500", color: "#EF4444 " }}
              />
            </View>

            <View style={styles.acceptBg}>
              <Icon icon="check" size={16} />
              <Text
                preset="default"
                size="xxs"
                text="Accept"
                style={{ fontWeight: "500", color: "#22C55E " }}
              />
            </View>
          </View>
        </View>
      ))}
    </>
  )
})
const styles = StyleSheet.create({
  flexSpacing: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  paidBg: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    backgroundColor: "#F0FDF4",
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
  },

  infoText: {
    fontWeight: "500",
    color: colors.palette.primary500,
  },

  textWrapper: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F9FAFB",
  },
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 12,
  },

  acceptBg: {
    alignItems: "center",
    backgroundColor: "#F0FDF4",
    borderRadius: 16,
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 8,
  },

  declineBg: {
    backgroundColor: "#FEF2F2",
  },

  pendingBookWrapper: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderRadius: 16,
    borderColor: "#E1E1FE",
    borderStyle: "solid",
    borderWidth: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 16,
  },

  timeline: {
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
})
