export const addressType = [
  { id: 1, type: "Home" },
  { id: 2, type: "Work" },
  { id: 3, type: "Others" },
]

export const TextFieldData = [
  { id: 1, placeholder: "Address line 1", type: "default" },
  { id: 2, placeholder: "Address line 2", type: "default" },
  { id: 3, placeholder: "Apt/Suite/Others", type: "default" },
  { id: 4, placeholder: "City", type: "default" },
  { id: 5, placeholder: "County", type: "default" },
  { id: 6, placeholder: "Post Code", type: "default" },
]

export const WebTextFieldData = [
  { id: 1, placeholder: "Address line 1", type: "default" },
  { id: 2, placeholder: "Address line 2", type: "default" },
  { id: 3, placeholder: "Apt/Suite/Others", type: "default" },
  //   { id: 4, placeholder: "City", type: "default" },
  //   { id: 5, placeholder: "County", type: "default" },
  //   { id: 6, placeholder: "Post Code", type: "default" },
]
