/* eslint-disable react-native/no-color-literals */
import * as React from "react"
import { StyleProp, StyleSheet, TouchableOpacity, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors } from "app/theme"
import { Text } from "app/components/Text"
import { Header } from "app/components/Header"
import { Search } from "app/components/serviceUser/Search"
import { address } from "app/components/serviceUser/DummyData/ProfileScreensData"
import { Icon } from "app/components/Icon"
import { CustomCheckBox } from "app/components/Common/CheckBox"
import { useNavigation } from "@react-navigation/native"

export interface SavedAddressWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  setActiveProfile: (profile: string) => void
}

/**
 * Describe your component here
 */
export const SavedAddressWeb = observer(function SavedAddressWeb(props: SavedAddressWebProps) {
  const { style, setActiveProfile } = props
  const $styles = [$container, style]

  const [isChecked, setChecked] = React.useState(false)
  const [defaultAddress, setDefaultAddress] = React.useState(0)

  const handleCheckboxChange = (newValue: boolean, id) => {
    // const handleCheckboxChange = (newValue: boolean) => {
    setDefaultAddress(id)
    setChecked(newValue)
  }
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  const navigation = useNavigation()

  return (
    <View style={$styles}>
      <Header title="Saved Addresses" />
      <Search />
      {address.map((data, index) => (
        <View style={styles.container} key={index}>
          <View style={styles.addressContainer}>
            <View style={styles.leftContainer}>
              <Icon icon="location" size={16} />
              <Text text={data.address} size="xs" weight="light" />
            </View>
            <View style={styles.textContainer}>
              <Text text={data.Tag} style={styles.rightText} size="sm" weight="light" />
            </View>
          </View>
          <View style={styles.leftContainer}>
            <CustomCheckBox
              label="Mark as default address"
              // value={isChecked}
              value={defaultAddress === index}
              onChange={() => handleCheckboxChange(isChecked, index)}
              // onChange={handleCheckboxChange}
              id={index as unknown as string}
            />
          </View>
        </View>
      ))}
      <TouchableOpacity
        style={[styles.leftContainer, styles.newAddress]}
        onPress={() => setActiveProfile("addAddress")}
      >
        <Text text="Add New Address" size="lg" style={styles.rightText} />
        <Icon icon="add" size={24} />
      </TouchableOpacity>{" "}
    </View>
  )
})

const $container: ViewStyle = {
  justifyContent: "center",
}

const styles = StyleSheet.create({
  addressContainer: {
    alignItems: "center",
    borderColor: "#D1D5DB",
    borderRadius: 16,
    borderWidth: 0.5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
    paddingHorizontal: 10,
    paddingVertical: 15,
  },
  container: {
    marginBottom: 5,
  },
  leftContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    gap: 5,
  },
  newAddress: {
    marginTop: 30,
  },
  rightText: {
    color: colors.palette.primary600,
  },
  textContainer: {
    backgroundColor: colors.palette.neutral200,
    borderRadius: 16,
    padding: 2,
    paddingHorizontal: 8,
  },
})
