import React from "react"
import { View, StyleSheet } from "react-native"
import supportImage from "assets/images/support-img.png"
import { colors } from "app/theme"
import {
  Screen,
  Text,
  BackArrowHeader,
  AutoImage,
  WebProfile,
  HeaderBar,
  Sidebar,
} from "app/components"

export const ProviderPolicyScreen = () => {
  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={{ flex: 1 }}>
          <HeaderBar title="Your Profile" />
          <View style={styles.conditionWrapper}>
            <View style={[styles.conditionCover, styles.flexStyle]}>
              <WebProfile />
            </View>
            <View style={{ flex: 1, backgroundColor: "#F9FAFB" }}>
              <View style={styles.conditionCover}>
                <Text
                  preset="bold"
                  size="lg"
                  text="Terms and Conditions"
                  style={{ textAlign: "center" }}
                />
                <AutoImage source={supportImage} style={styles.supportImage} />
                <Text
                  preset="default"
                  size="xs"
                  text="Close2mi has been giving services to its clients by If you are facing financial challenges, you may be eligible for a discounted therapy costIf you are facing financial challenges, you may be eligible for a discounted therapy cost."
                  style={styles.policyText}
                />

                <Text
                  size="xs"
                  text="What informaton does Close2mi care capture about visitors to its website?"
                  style={styles.policySubHeader}
                />

                <Text
                  preset="default"
                  size="xs"
                  text="Close2mi web server uses an extended log file format which captures date and time of visit, referring address (location from which visitor comes to Close2mi.com), type of internet browser and visitor’s IP address. The log file does not capture a visitor’s email address"
                  style={styles.policyText}
                />

                <Text
                  size="xs"
                  text="How does Close2mi care use the information?"
                  style={styles.policySubHeader}
                />

                <Text
                  preset="default"
                  size="xs"
                  text="The information collected through this site helps us identify the type of website content our customers value most. We use this information to market and improve the website and our services. we also use this information to send you email notifications about updates on the website, and to contact you by other means for marketing and other purposes."
                  style={styles.policyText}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  supportImage: {
    width: 437,
    height: 202,
    marginTop: 24,
    marginBottom: 16,
    borderRadius: 16,
  },

  policyText: {
    fontWeight: "400",
    color: colors.palette.neutral600,
    marginBottom: 16,
  },

  policySubHeader: {
    fontWeight: "700",
    color: colors.text,
  },
  conditionCover: {
    padding: 16,
    borderRadius: 16,
    backgroundColor: "#fff",
  },
  flexStyle: {
    flex: 1,
  },
  conditionWrapper: {
    padding: 24,
    flexDirection: "row",
    gap: 24,
    backgroundColor: "#F9FAFB",
  },
})
