import * as React from "react"
import { StyleProp, StyleSheet, Pressable, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, Icon, EmptyState, AutoImage, BookingDataProps } from "app/components"
import { useNavigation } from "@react-navigation/native"

export interface WebBookingsProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  data: BookingDataProps[]
  tabStatus?: string
}

/**
 * Describe your component here
 */
export const WebBookings = observer(function WebBookings(props: WebBookingsProps) {
  const { style, data, tabStatus } = props
  const navigation = useNavigation()

  return (
    <>
      {data && data.length > 0 ? (
        data.map((booking, index) => (
          <View style={[styles.bookingContainer, style]} key={"k46" + index}>
            <View style={styles.bookingStyle} key={index + booking.time}>
              <Pressable onPress={() => navigation.navigate("BookView")}>
                <View style={styles.spaceItems}>
                  <View style={{ flexDirection: "row", alignItems: "center", gap: 8 }}>
                    <AutoImage source={{ uri: booking.userImg }} style={styles.img} />
                    <Text
                      preset="default"
                      size="xs"
                      text={booking.name}
                      style={styles.priceTextStyle}
                    />
                  </View>
                  <Text
                    preset="default"
                    size="xs"
                    text={booking.phone}
                    style={styles.priceTextStyle}
                  />
                  <Text
                    preset="default"
                    size="xs"
                    text={booking.price}
                    style={styles.priceTextStyle}
                  />
                </View>
                <View style={[styles.spaceItems, styles.bottomMargin]}>
                  <View style={[styles.spaceMargin, styles.bookingIconText]}>
                    <Text
                      preset="default"
                      size="xxs"
                      text={booking.service}
                      style={styles.timeTextStyle}
                    />
                  </View>
                  <View style={styles.bookingIconText}>
                    <Icon icon="BiClock" size={14} />
                    <Text
                      preset="default"
                      size="xxs"
                      text={booking.date}
                      style={styles.timeTextStyle}
                    />
                  </View>

                  <View style={styles.bookingIconText}>
                    <Icon icon="HourGlass" size={12} />
                    <Text
                      preset="default"
                      size="xxs"
                      text={booking.service}
                      style={styles.timeTextStyle}
                    />
                  </View>
                </View>
              </Pressable>

              <View style={styles.spaceItems}>
                <View style={styles.flexRow}>
                  <Icon icon="pin" size={16} />
                  <Text
                    preset="default"
                    size="xxs"
                    text="25 Montgomery Road, Yaba"
                    style={styles.locationText}
                  />
                </View>
                <View style={{ flexDirection: "row", gap: 4 }}>
                  <Text size="xs" text="$500" style={styles.locationText} />
                  <View style={styles.paidStyle}>
                    <Text
                      preset="default"
                      size="xxs"
                      text="paid"
                      style={{ fontWeight: "500", color: "#22C55E " }}
                    />
                  </View>
                </View>
              </View>

              {tabStatus === "Upcoming" ? null : (
                <Text
                  size="sm"
                  text={booking.status}
                  style={{
                    fontWeight: "600",
                    color: booking.status === "Cancelled" ? "#EF4444" : "#22C55E",
                  }}
                />
              )}
            </View>
          </View>
        ))
      ) : (
        <View style={[styles.bookingContainer, style]}>
          <EmptyState heading="Nothing to show" content="There is nothing to see here" />
        </View>
      )}
    </>
  )
})

const styles = StyleSheet.create({
  // acceptBg: {
  //   alignItems: "center",
  //   backgroundColor: "#F0FDF4",
  //   borderRadius: 16,
  //   flexDirection: "row",
  //   gap: 4,
  //   justifyContent: "center",
  //   paddingHorizontal: 8,
  //   paddingVertical: 8,
  // },

  bookingContainer: {
    alignContent: "center",
    borderColor: "#E1E1FE",
    borderRadius: 8,
    borderStyle: "solid",
    borderWidth: 1,
    justifyContent: "center",
    marginBottom: 8,
    padding: 24,
    backgroundColor: "#fff",
  },

  bookingIconText: {
    alignItems: "center",
    backgroundColor: colors.palette.primary50,
    borderRadius: 16,
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    paddingBottom: 4,
    paddingHorizontal: 8,
    paddingTop: 4,
  },

  bookingStyle: {
    paddingBottom: 4,
    paddingHorizontal: 8,
    paddingTop: 4,
    // border: `1px solid ${colors.palette.primary100}`,
  },

  declineBg: {
    alignItems: "center",
    backgroundColor: "#FEF2F2",
    borderRadius: 16,
    flexDirection: "row",
    gap: 4,
    justifyContent: "center",
    paddingHorizontal: 8,
    paddingVertical: 8,
  },

  iconChips: {
    alignItems: "center",
    backgroundColor: colors.palette.primary50,
    borderRadius: 16,
    justifyContent: "center",
    paddingBottom: 4,
    paddingHorizontal: 8,
    paddingTop: 4,
  },
  img: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },

  priceTextStyle: {
    color: colors.text,
    fontWeight: "500",
  },

  spaceItems: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  spaceMargin: {
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  timeTextStyle: {
    color: colors.palette.primary600,
    fontWeight: "500",
  },

  bottomMargin: {
    marginBottom: 16,
    marginTop: 8,
  },

  paidStyle: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 16,
    backgroundColor: "#F0FDF4",
  },

  locationText: {
    fontWeight: "500",
    color: colors.text,
  },

  flexRow: {
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
})
