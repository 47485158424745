/* eslint-disable react-native/no-inline-styles */
import * as React from "react"
import { StyleProp, StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { Text } from "app/components/Text"
import { Header } from "app/components/Header"
import HappyWoman from "../../../../assets/images/HappyWoman.png"
import { AutoImage } from "app/components/AutoImage"

export interface PrivacyPolicyWebProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
}

/**
 * Describe your component here
 */
export const PrivacyPolicyWeb = observer(function PrivacyPolicyWeb(props: PrivacyPolicyWebProps) {
  const { style } = props
  const $styles = [$container, style]

  return (
    <View style={$styles}>
      <Header title="Privacy Policy" />
      <View style={{flex: 1}}>
      <AutoImage source={HappyWoman} maxWidth={510} />
      </View>
      <View style={styles.rightContainer}>
      <Text
        text="Close2mi understands the importance of protecting the privacy of its users. The information collected is used to market and improve the services we and our subsidiaries offer, to improve the Close2mi website and to contact you with updates to the website or for other marketing purposes."
        size="sm"
        weight="light"
      />
      <View>
        <Text
          text="What informaton does Close2mi care capture about visitors to its website?"
          size="sm"
          weight="semiBold"
          style={styles.headerText}
        />
        <Text
          text="Close2mi web server uses an extended log file format which captures date and time of visit, referring address (location from which visitor comes to Close2mi.com), type of internet browser and visitor’s IP address. The log file does not capture a visitor’s email address"
          size="sm"
          weight="light"
        />
      </View>
      <View>
        <Text
          text="How does Close2mi care use the information?"
          size="sm"
          weight="semiBold"
          style={styles.headerText}
        />
        <Text
          text="The information collected through this site helps us identify the type of website content our customers value most. We use this information to market and improve the website and our services. we also use this information to send you email notifications about updates on the website, and to contact you by other means for marketing and other purposes."
          size="sm"
          weight="light"
        />
      </View>
    </View>
    </View>
  )
})

const $container: ViewStyle = {
  flex: 1,
}

const styles = StyleSheet.create({
  headerText: {
    marginBottom: 10,
    marginTop: 20,
  },
  rightContainer: {
    minWidth: 400,
    width: 510,
  }
})
