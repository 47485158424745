/* eslint-disable react-native/no-inline-styles */
/* eslint-disable react-native/no-color-literals */
import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet, TouchableOpacity } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  BaseModal,
  BookingCard,
  Footer,
  HeaderForWebView,
  Icon,
  PopularSearches,
  Screen,
  Search,
  SectionHeader,
  ServiceProviderCard,
  Services,
  Text,
} from "app/components"
// import { screenContentContainer } from "app/styles/mainStyles"
import { colors } from "app/theme"
import {
  bookingData,
  popularSearch,
  recentSearchCache,
  serviceProvider,
  services,
} from "app/components/serviceUser/DummyData/HomeScreenData"
import { useNavigation } from "@react-navigation/native"

interface HomeScreenProps extends AppStackScreenProps<"ServiceUserHome"> {}

export const ServiceUserHomeScreen: FC<HomeScreenProps> = observer(function HomeScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  const [loadModal, setLoadModal] = React.useState(false)

  // Pull in navigation via hook
  const navigation = useNavigation()
  return (
    <Screen preset="scroll">
      <HeaderForWebView />
      <View style={styles.screenBackground}>
        <View style={styles.contentContainer}>
          <TouchableOpacity onPress={() => setLoadModal(!loadModal)}>
            <Search style={styles.search} />
          </TouchableOpacity>
          <View style={styles.section}>
            <SectionHeader leftText="Based on your recent search" rightText="See All" />
            <View style={styles.recentSearch}>
              {serviceProvider.map((providers, index) => (
                <ServiceProviderCard
                  key={index}
                  image={providers.image}
                  location={providers.location}
                  views={providers.views}
                  rating={providers.rating}
                  name={providers.name}
                  navigate={() => navigation.navigate("ServiceProviderDetails", providers)}
                  style={styles.serviceProviderCard}
                />
              ))}
            </View>
          </View>
          <View style={styles.section}>
            <SectionHeader leftText="Upcoming Booking" rightText="See All" />
            {bookingData.map((booking, index) => (
              <BookingCard
                key={index}
                image={booking.image}
                textTopLeft={booking.textTopLeft}
                midTextLeft={booking.midTextLeft}
                bottomIconLeft={booking.bottomIconLeft}
                bottomTextLeft={booking.bottomTextLeft}
                bottomIconRight={booking.bottomIconRight}
                bottomTextRight={booking.bottomTextRight}
                navigate={() => navigation.navigate("Bookings")}
              />
            ))}
          </View>
          <View style={styles.section}>
            <SectionHeader leftText="Services on Close2mi" />
            <View style={styles.serviceContainer}>
              {services.map((service, index) => (
                <Services
                  key={index}
                  style={{ width: 174, height: 179 }}
                  icon={service.icon}
                  text={service.serviceName}
                  onLeftTextPress={() => navigation.navigate("Services")}
                  onPress={() => {
                    navigation.navigate("Services", { params: service })
                  }}
                />
              ))}
            </View>
          </View>
          <View style={styles.section}>
            <SectionHeader
              leftText="Popular in your Area"
              rightText="See All"
              onLeftTextPress={() => navigation.navigate("PopularInYourArea")}
            />
            <View style={styles.recentSearch}>
              {serviceProvider.map((providers, index) => (
                <ServiceProviderCard
                  key={index}
                  image={providers.image}
                  location={providers.location}
                  views={providers.views}
                  rating={providers.rating}
                  name={providers.name}
                  navigate={() => navigation.navigate("ServiceProviderDetails", providers)}
                  style={styles.serviceProviderCard}
                />
              ))}
            </View>
          </View>
        </View>
        <BaseModal
          modalVisible={loadModal}
          modalBody={
            <>
              <View>
                {/* <Search /> */}
                <SectionHeader leftText="Recent Searches" rightText="Clear All" />
                {recentSearchCache.map((history, index) => (
                  <View style={styles.container} key={index}>
                    <Text text={history.text} size="md" weight="medium" style={styles.text} />
                    <Icon icon="close" size={14} />
                  </View>
                ))}
              </View>
              <View>
                <SectionHeader leftText="Popular Searches" />
                <View style={styles.popularSearchContainer}>
                  {popularSearch.map((popular, index) => (
                    <PopularSearches text={popular.text} key={index} textFontWeight={"light"} />
                  ))}
                </View>
              </View>
            </>
          }
          setModalVisible={() => setLoadModal(false)}
        />
      </View>
      <Footer />
    </Screen>
  )
})

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
    marginTop: 10,
  },
  popularSearchContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  // eslint-disable-next-line react-native/no-color-literals
  text: {
    color: "#6B7280",
  },
  // eslint-disable-next-line react-native/sort-styles
  contentContainer: {
    display: "flex",
    gap: 32,
    marginHorizontal: "auto",
    maxWidth: 1200,
  },
  recentSearch: {
    display: "flex",
    flexDirection: "row",
    gap: 24,
  },
  screenBackground: {
    alignContent: "center",
    backgroundColor: "#f7f7f7",
    display: "flex",
    justifyContent: "center",
    paddingVertical: 32,
  },
  search: {
    width: 380,
  },
  section: {
    backgroundColor: colors.background,
    borderRadius: 16,
    padding: 16,
  },
  serviceContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 24,
  },
  serviceProviderCard: {
    height: 350,
    width: 368,
  },
})
