import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { View, StyleSheet } from "react-native"
import { AppStackScreenProps } from "app/navigators"
import {
  Header,
  Screen,
  Text,
  EditServices,
  PreviousWork,
  HeaderBar,
  Sidebar,
} from "app/components"
import { screenContentContainer } from "app/styles/mainStyles"
import { useNavigation } from "@react-navigation/native"
// import { useStores } from "app/models"

interface EditServiceScreenProps extends AppStackScreenProps<"EditService"> {}

export const EditServiceScreen: FC<EditServiceScreenProps> = observer(function EditServiceScreen() {
  // Pull in one of our MST stores
  // const { someStore, anotherStore } = useStores()

  // Pull in navigation via hook
  const navigation = useNavigation()

  return (
    <Screen
      preset="scroll"
      contentContainerStyle={screenContentContainer}
      safeAreaEdges={["top", "bottom"]}
    >
      {/* use what Header if you like or the Text if it suits better don't forget to delete the unused import at the end */}
      {/* <Text preset="heading" text="notifications" /> */}
      <View style={{ flexDirection: "row" }}>
        <Sidebar />

        <View style={{ flex: 1 }}>
          <HeaderBar title="Services" />
          <View style={styles.editCover}>
            <View style={styles.editWrapper}>
              <EditServices />
            </View>
            <View style={styles.editWrapper}>
              <PreviousWork isWeb />
            </View>
          </View>
        </View>
      </View>
    </Screen>
  )
})

const styles = StyleSheet.create({
  editCover: {
    flexDirection: "row",
    gap: 24,
    padding: 24,
    backgroundColor: "#F9FAFB",
  },

  editWrapper: {
    padding: 16,
    backgroundColor: "#fff",
    flex: 1,
  },
})
