import * as React from "react"
import { StyleProp, TextStyle, View, ViewStyle, StyleSheet, ImageRequireSource } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, AutoImage } from "app/components"
import { Icon } from "app/components"

export interface HomeServiceProps {
  services: string
  shop: string
  minute: string
  hour: string
  date: string
  status: string
  serviceImg: ImageRequireSource
}

export interface HomeServicesProps {
  /**
   * An optional style override useful for padding & margin.
   */
  style?: StyleProp<ViewStyle>
  data: HomeServiceProps[]
}

/**
 * Describe your component here
 */
export const HomeServices = observer(function HomeServices(props: HomeServicesProps) {
  const { data } = props
  return (
    <>
      {/* <View style={styles.containerStyle}> */}
      {data.map((homeService, i) => (
        <View style={styles.containerStyle} key={i}>
          <View style={styles.imageContainer}>
            <AutoImage source={homeService.serviceImg} style={{ width: 74, height: 70 }} />
          </View>

          <View style={{ flex: 1 }}>
            <View style={styles.flexBetween}>
              <Text preset="bold" text={homeService.services} style={styles.headerText} />
              <View style={styles.availableBg}>
                <Text
                  preset="default"
                  size="xxs"
                  text={homeService.status}
                  style={{ fontWeight: "500", color: "#22C55E " }}
                />
              </View>
            </View>
            <View style={styles.flexBetween}>
              <Text
                preset="default"
                size="xxs"
                text={homeService.shop}
                style={{ fontWeight: "400", color: "#374151" }}
              />

              <View style={styles.txtCover}>
                <Icon icon="HourGlass" size={14} />
                <Text
                  preset="default"
                  size="xxs"
                  text={homeService.minute}
                  style={styles.timeTextStyle}
                />
              </View>
            </View>

            <View style={styles.flexBetween}>
              <View style={styles.serviceTextContainer}>
                <Icon icon="BiClock" size={14} />
                <Text
                  preset="default"
                  size="xxs"
                  text={homeService.date}
                  style={styles.timeTextStyle}
                />
              </View>
              <View style={styles.serviceTextContainer}>
                <Icon icon="Calendar" size={14} />
                <Text
                  preset="default"
                  size="xxs"
                  text={homeService.date}
                  style={styles.timeTextStyle}
                />
              </View>
            </View>
          </View>
        </View>
      ))}
      {/* </View> */}
    </>
  )
})

const styles = StyleSheet.create({
  containerStyle: {
    flexDirection: "row",
    paddingVertical: 8,
    paddingHorizontal: 4,
    alignItems: "center",
    // justifyContent: "space-between",
    gap: 2,
    borderRadius: 8,
    borderWidth: 1,
    borderColor: "#E1E1FE",
    borderStyle: "solid",
    marginBottom: 8,
  },
  headerText: {
    fontWeight: "500",
    fontSize: 15,
    color: colors.text,
  },

  timeTextStyle: {
    fontWeight: "500",
    color: colors.palette.primary600,
  },

  mildSpacing: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 4,
  },

  serviceTextContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    borderRadius: 16,
    backgroundColor: colors.palette.primary50,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },

  txtCover: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    // marginBottom: 4,
  },

  availableBg: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 4,
    borderRadius: 16,
    backgroundColor: "#F0FDF4",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 8,
    paddingRight: 8,
  },

  imageContainer: {
    marginRight: 8,
  },
})
