import React, { useState } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Text, TextField, Icon } from "app/components"

export interface SortingProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const Sorting = observer(function Sorting(props: SortingProps) {
  const [searchTerm, setSearchTerm] = useState(" ")

  return (
    <View>
      <View style={[styles.flexRow, styles.filterContainer]}>
        <TextField
          value={searchTerm}
          onChangeText={(text) => setSearchTerm(text)}
          RightAccessory={() => <Icon icon="search" style={{ marginRight: 8 }} size={18} />}
          inputWrapperStyle={{
            width: 480,
            borderRadius: 24,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        />

        <View style={styles.filterWrapper}>
          <Text size="xs" text="Sort by" style={{ fontWeight: "400" }} />
          <Icon icon="caretDown" size={14} />
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  flexRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  filterContainer: {
    marginBottom: 16,
    gap: 16,
  },

  filterWrapper: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderStyle: "solid",
    padding: 8,
    borderRadius: 24,
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
})
