import React from "react"
import { View, StyleSheet } from "react-native"
import { screenContentContainer } from "app/styles/mainStyles"
import {
  WebBoxes,
  TransactionWeb,
  BarCharts,
  Text,
  Icon,
  HeaderBar,
  Sidebar,
  Screen,
} from "app/components"
import { colors } from "app/theme"
import { earningData } from "app/DummyData/BookingsData"

export const EarningsScreen = () => {
  return (
    <Screen preset="auto" contentContainerStyle={screenContentContainer} safeAreaEdges={["bottom"]}>
      <View style={{ flexDirection: "row" }}>
        <Sidebar />
        <View style={{ flex: 1 }}>
          <HeaderBar title="Earnings" />
          <View style={styles.earningContentContainer}>
            <View style={styles.earningAnalytics}>
              <View style={{ marginBottom: 40 }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 40,
                    marginBottom: 68,
                  }}
                >
                  <Text preset="bold" size="xs" text="Income" />
                  <View style={styles.bookingIconText}>
                    <Text
                      preset="default"
                      size="xxs"
                      text="Last 7 days"
                      style={{ fontWeight: "400", color: colors.palette.neutral600 }}
                    />

                    <Icon icon="carretdown" />
                  </View>
                </View>
                <BarCharts />
              </View>
              <WebBoxes />
            </View>

            <View>
              <View style={styles.headStyle}>
                <View style={styles.flexBetween}>
                  <Text preset="bold" size="lg" text="Transactions" />
                  <View style={styles.filterWrapper}>
                    <Text size="xs" text="Sort by" style={{ fontWeight: "400" }} />
                    <Icon icon="caretDown" size={14} />
                  </View>
                </View>
                <View style={{ marginBottom: 8 }}>
                  <TransactionWeb data={earningData} />
                </View>

                {/* <View style={{ marginBottom: 8 }}>
                  <TransactionWeb />
                </View>
                <View style={{ marginBottom: 8 }}>
                  <TransactionWeb />
                </View> */}
              </View>
            </View>
          </View>
        </View>
      </View>
    </Screen>
  )
}

const styles = StyleSheet.create({
  earningAnalytics: {
    padding: 16,
    backgroundColor: "#fff",
    borderRadius: 16,
  },

  filterWrapper: {
    borderWidth: 1,
    borderColor: "#D1D5DB",
    borderStyle: "solid",
    padding: 8,
    borderRadius: 24,
    flexDirection: "row",
    alignItems: "center",
    gap: 8,
  },
  headStyle: {
    backgroundColor: "#fff",
    padding: 16,
    borderRadius: 16,
  },

  earningContentContainer: {
    flexDirection: "row",
    gap: 24,
    padding: 24,
    backgroundColor: "#F9FAFB",
  },
  flexBetween: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 8,
  },

  bookingIconText: {
    flexDirection: "row",
    alignItems: "center",
    gap: 1,
  },
})
