import React, { FC, useState } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { observer } from "mobx-react-lite"
import { colors, typography } from "app/theme"
import { Header, TextField, Text, Icon, Button } from "app/components"
import { useNavigation } from "@react-navigation/native"

export interface AccountDetailProps {
  /**
   * An optional style override useful for padding & margin.
   */
}

/**
 * Describe your component here
 */
export const AccountDetail = observer(function AccountDetail(props: AccountDetailProps) {
  const [bnkName, setBnkName] = useState<string>("")
  const [accNumber, setAccNumber] = useState<string>("")
  const [confirmNum, setConfirmNum] = useState<string>("")
  const [holderName, setHolderName] = useState<string>("")
  const [holderType, setHolderType] = useState<string>("")
  const navigation = useNavigation()

  return (
    <View>
      <Header
        title="Bank Account Details"
        leftIcon="back"
        onLeftPress={() => navigation.goBack()}
      />

      <View>
        <View style={styles.textFieldWrapper}>
          <Text
            preset="label"
            size="xs"
            weight="medium"
            text="Sort Code"
            style={styles.labelColor}
          />

          <TextField
            value={bnkName}
            onChangeText={(value) => setBnkName(value)}
            placeholder="GTB"
          />
        </View>

        <View style={styles.textFieldWrapper}>
          <Text
            preset="label"
            size="xs"
            weight="medium"
            text="Account Number"
            style={styles.labelColor}
          />

          <TextField
            value={accNumber}
            onChangeText={(value) => setAccNumber(value)}
            placeholder="0237154436"
          />
        </View>

        <View style={styles.textFieldWrapper}>
          <Text
            preset="label"
            size="xs"
            weight="medium"
            text="Confirm Number"
            style={styles.labelColor}
          />

          <TextField
            value={confirmNum}
            onChangeText={(value) => setConfirmNum(value)}
            placeholder="0237154436"
          />
        </View>

        <View style={styles.textFieldWrapper}>
          <Text
            preset="label"
            size="xs"
            weight="medium"
            text="Holder Name"
            style={styles.labelColor}
          />

          <TextField
            value={holderName}
            onChangeText={(value) => setHolderName(value)}
            placeholder="Ayominde Gbenga"
          />
        </View>

        <View style={styles.textFieldWrapper}>
          <Text
            preset="label"
            size="xs"
            weight="medium"
            text="Holder Type"
            style={styles.labelColor}
          />

          <TextField
            value={holderType}
            onChangeText={(value) => setHolderType(value)}
            RightAccessory={() => <Icon icon="caretDown" />}
          />
        </View>
      </View>

      <Button
        preset="primary"
        text="Submit"
        onPress={() => navigation.navigate("AuthSubscription")}
      />
    </View>
  )
})

const styles = StyleSheet.create({
  labelColor: {
    color: "#344054",
    marginBottom: 6,
  },

  textFieldWrapper: {
    marginBottom: 20,
  },
})
